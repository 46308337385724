.faq-part-section .contains {
  background: none !important;
}

.faq-part-section .contains > .title p {
  width: auto;
  max-width: 628px;
  max-width: calc(100vw - 32px);
}

.faq-part-section .contains .contents-faq {
  padding: 0 90px;
  max-width: calc(100vw - 32px);
}

.contents-faq .faq-item {
  padding: 24px;
  border-radius: 20px;
  background: var(--gray-100);
  width: 100%;
  cursor: pointer;
  margin: 0 auto;
}
.contents-faq .faq-item .faq-title h2 {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.64px;
}

.contents-faq .faq-item .faq-title .arrow {
  /* Violet */
  transition: transform 0.3s;
}

.contents-faq .faq-item .faq-title .arrow.open {
  transform: rotate(180deg);
}
.contents-faq .faq-item .faq-description :is(li, p, ul) {
  color: var(--shade-black, #1e1a21);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.contents-faq .faq-item .faq-description > p:first-child {
  margin-top: 16px;
}
.contents-faq .faq-item .faq-description ul li {
  margin-left: 20px;
  position: relative;
}
.contents-faq .faq-item .faq-description ul li span {
  color: var(--shade-black, #1e1a21);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.contents-faq .faq-item .faq-description ul li::before {
  content: "";
  position: absolute;
  left: -15px;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--shade-black);
}

@media screen and (max-width: 850px) {
  .faq-part-section .contains {
    padding-left: 32px;
    padding-right: 32px;
  }
  .faq-part-section .contains .contents-faq {
    padding: 0;
    margin: 0 auto;
    width: 100%;
  }

  .contents-faq .faq-item {
    padding: 16px;
    border-radius: 16px;
  }
  .contents-faq .faq-item .faq-title h2 {
    font-size: 22px;
    line-height: 32px;
    letter-spacing: -0.42px;
  }

  .contents-faq .faq-item .faq-description {
    margin-top: 6px;
    font-size: 14px;
    line-height: 22px;
  }
}

@media screen and (max-width: 680px) {
  .faq-part-section .contains {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 480px) {
  .contents-faq .faq-item .faq-title h2 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
}
