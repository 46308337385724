.filters {
  width: 100%;
  column-gap: 16px;
  flex-wrap: nowrap;
  padding-top: 12px;
  padding-bottom: 12px;
  background: var(--shade-white);
}
.filters .btns-filter {
  flex: 1;
  overflow-x: auto;
  white-space: nowrap;
  cursor: pointer;
  padding: 0;
}

.scrollable:hover {
  cursor: grab;
}

.scrollable:active {
  cursor: grabbing;
}

.scrollable::-webkit-scrollbar {
  opacity: 0;
  height: 0;
}

.filters .show-filter {
  margin-left: 8px;
  border: 1px solid var(--primary-300);
  background: var(--primary-100);
}
.filters .arrow-localisation {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  margin-left: 12px;
  border: 1px solid var(--gray-300);
}
.filters .arrow-localisation img {
  width: 20px;
  height: 20px;
}
.filters .btn-next-step {
  padding: 0;
}
.filters-complete {
  z-index: 4;
  margin-top: 8px;
  row-gap: 16px;
  position: fixed;
  top: 105px;
  width: 100%;
  border-bottom: 1px solid var(--gray-200);
  left: 50%;
  transform: translateX(-50%);
  background: var(--shade-white);
}
@media screen and (min-width: 951px) {
  .filters-complete {
    top: 27px;
    padding-top: 49px;
  }
}
.filters-replace {
  background: var(--shade-white);
  width: 100%;
  height: 68px;
}
.filters-complete .second-search-bar {
  display: none;
  width: 100%;
  margin: 0;
  border-radius: 1000px;
  border: 1px solid var(--gray-300, #d1d5db);
  background: var(--shade-white, #fff);

  /* CARD BOX SHADOW */
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  margin-top: 10px;
}
@media screen and (max-width: 950px) {
  .filters-complete .second-search-bar {
    display: flex;
  }
  .filters-replace {
    width: 100%;
    height: 143px;
  }
  .filters-complete {
    top: 78px;
    margin-top: 0;
  }
}
@media screen and (max-width: 1050px) {
  .filters-complete {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 950px) {
  .filters-complete {
    padding-top: 10px;
  }
}
@media screen and (max-width: 550px) {
  .filters-complete {
    padding-left: 16px;
    padding-right: 16px;
  }
  .filters {
    display: none;
  }
  .filters-complete {
    padding-bottom: 8px;
  }
  .filters-replace {
    height: 88px;
  }
}

.for-mobile-part {
  position: fixed;
  top: 90px;
  left: 0;
  margin: 0 16px !important;
  width: calc(100% - 32px) !important;
  z-index: 4;
}
