.paiement-recap .first-step {
  flex-wrap: wrap;
}
.paiement-recap .first-step .input-select-perso {
  border-top-width: 0 !important;
  border-radius: 0px !important;
}
.paiement-recap .first-step .name-billing {
  border-top-width: 1px !important;
  border-radius: 6px 6px 0px 0px !important;
}
.paiement-recap .first-step :is(.tel-billing, .email-billing) {
  width: 50%;
}
.paiement-recap .first-step :is(.tel-billing) {
  width: 50%;
}
.paiement-recap .first-step :is(.email-billing) {
  border-left: 0;
}
@media screen and (max-width: 450px) {
  .paiement-recap .first-step :is(.email-billing) {
    border-left: 1px solid var(--gray-500, #6b7280);
  }
}
.paiement-recap .province-billing {
  border-radius: 0 !important;
}
.paiement-recap .province-billing.selected {
  border: 1px solid var(--primary-500, #dac7ff) !important;
}
.paiement-recap .country-billing.selected {
  border: 1px solid var(--primary-500, #dac7ff) !important;
}
.paiement-recap .first-step :is(.ville-billing, .zip-billing) {
  width: 50%;
}
.paiement-recap form {
  row-gap: 24px;
}

.paiement-recap .select-input {
  width: 100%;
  transition: 0.2s;
  opacity: 0;
  border-radius: 16px;
  max-height: 250px;
  position: absolute;
  bottom: calc(100% + 10px);
  left: 0;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 0;
  pointer-events: none;
  background: var(--background);
  padding: 5px 0;
  box-shadow: -3px 6px 14px 0px rgba(135, 135, 135, 0.05),
    -10px 23px 25px 0px rgba(135, 135, 135, 0.04),
    -23px 51px 34px 0px rgba(135, 135, 135, 0.03),
    -41px 91px 40px 0px rgba(135, 135, 135, 0.01),
    -63px 142px 43px 0px rgba(135, 135, 135, 0);
}
.select-visible {
  opacity: 1 !important;
  pointer-events: all !important;
}
.select-input button {
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 16px;
  column-gap: 8px;
  border-bottom: 0.5px solid var(--gray-200);
  justify-content: flex-start;
}
.select-input button:last-child {
  border-bottom: 0;
}
.select-input button span {
  font-size: 16px;
  font-weight: 600;
  max-width: calc(100% - 48px - 24px - 40px);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
}
.select-input button img {
  position: relative;
  left: 0;
  transform: translate(0);
  top: 0;
  width: 24px;
  height: 24px;
}
.paiement-recap .preview {
  opacity: 1 !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  width: auto !important;
  height: 100%;
  border-radius: 16px;
}
.paiement-recap .preview img {
  width: 20px;
  height: 20px;
  position: relative !important;
  top: 0 !important;
  transform: translate(0) !important;
  left: 0 !important  ;
  overflow: hidden;
}
.paiement-recap .listing .preview img {
  width: 30px;
  height: 24px;

  object-fit: contain;
}
.paiement-recap .preview span {
  font-size: 16px;
  font-weight: 600;
}

.paiement-recap span.input-select-perso {
  padding: 25px 16px;
  border-radius: 0;
}
.paiement-recap span.input-select-perso.listing {
  border-radius: 8px;
}

.paiement-recap span.input-select-perso .preview {
  width: calc(100% - 32px) !important;
}
.paiement-recap span.input-select-perso.listing .preview {
  width: calc(100%) !important;
  transform: translate(-16px, -50%);
  padding: 0 10px;
}
.paiement-recap span.input-select-perso.listing .preview input {
  padding-left: 0;
}
.paiement-recap span.input-select-perso .preview .chevron-img {
  margin-left: auto;
  transition: 0.2s;
}
.paiement-recap .country-billing.selected .chevron-img {
  transform: rotate(180deg) !important;
  filter: invert(100%) hue-rotate(270deg);
}

.paiement-recap span.input-select-perso .select-visible {
  padding: 5px 0 10px 0;
  padding-right: 10px;
  max-height: 235px;
  /*  */
  border: 1px solid var(--primary-200);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  z-index: 5;
  transform: scale(1.02);
  box-sizing: border-box;
  background: #f6f3f3fc;
}
.paiement-recap span.input-select-perso.listing .select-visible {
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
}
.paiement-recap span.input-select-perso .select-input button {
  padding-top: 10px;
  padding-bottom: 10px;
}
.paiement-recap span.input-select-perso .select-input button:hover {
  background: var(--background);
}

.paiement-recap span.input-select-perso .preview > span,
.paiement-recap .province-billing label,
.paiement-recap span.input-select-perso .preview input {
  color: var(--shade-black) !important;
  font-family: Inter !important;
  font-size: 16px !important;
  font-style: normal;
  border-radius: 0;
  font-weight: 500 !important;
  line-height: 24px; /* 150% */
}

.paiement-recap .province-billing.selected label {
  transform: translateY(calc(-100% - 12.5px)) !important;
  background: var(--background) !important;
  z-index: 2 !important;
}

.choose-paiement-method {
  width: 100%;
  justify-content: flex-start;
  padding: 16px;
  border: 1px solid var(--gray-500);
  border-radius: 6px;
}

.choose-paiement-method span {
  color: var(--shade-black, #1e1a21);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.choose-paiement-method > img {
  width: 20px !important;
  height: 20px !important;
  transition: 0.2s !important;
}
.choose-paiement-method > img.current-paiement-method {
  width: 34px;
  max-height: 100%;
  object-fit: contain;
  border-radius: 4px;
}
.choose-paiement-method > img:last-child {
  margin-left: auto;
}
.choose-paiement-method.open > img:last-child {
  transform: rotate(180deg) !important;
}
.contains-paiement-method .modal-choose-paiement {
  width: 100%;
  border-radius: 6px;
  position: absolute;
  align-items: flex-start;
  row-gap: 0;
  bottom: calc(100% + 10px);
  padding: 5px 0 10px 0;
  max-height: 205px;
  overflow-y: scroll;
  border: 1px solid var(--primary-200);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  z-index: 5;
  box-sizing: border-box;
  background: #f6f3f3fc;
  animation: show-modal-choose-paiement 0.2s ease-in-out forwards;
}
@keyframes show-modal-choose-paiement {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.contains-paiement-method .modal-choose-paiement button {
  width: 100%;
  padding: 10px 16px;
  border-radius: 0;
  border-bottom: 1px solid var(--gray-200);
  justify-content: flex-start;
  align-items: center;
}
.contains-paiement-method .modal-choose-paiement button span {
  font-size: 14px;
  font-weight: 600;
  max-width: calc(100% - 48px - 24px - 40px);
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  overflow: hidden;
}
.contains-paiement-method .modal-choose-paiement button img {
  width: 34px;
  max-height: 100%;
  object-fit: contain;
  border-radius: 4px;
}
.contains-paiement-method .modal-choose-paiement button.selected {
  background: var(--background);
}
