.step-9 .photos-adder {
  aspect-ratio: 141/44;
  width: auto;
  height: auto;
  cursor: pointer;
  padding: 12px 24px;
  border-radius: 100px;
  border: 1px solid var(--shade-black);
  column-gap: 8px;
}
.step-9 .photos-adder img {
  width: 16px;
  height: 16px;
}
.step-9 .photos-adder span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.step-9 .right-part-add-resi {
  padding-top: 135px;
  padding-bottom: 93px;
}
.step-9 .right-part-add-resi .title-show label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.48px;
  flex: 1;
}

.step-9 .right-part-add-resi .contains-input {
  row-gap: 32px;
}
.step-9 .right-part-add-resi .contains-input .contains-photos {
  flex: 1;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  flex-wrap: wrap;
  padding-bottom: 20px;
  align-content: baseline;
  row-gap: 16px;
  column-gap: 20px;
}

.step-9 .right-part-add-resi .contains-input .btn-delete-all {
  z-index: 999;
  position: absolute;
  background: var(--destructive-50);
  border-radius: 8px;
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  flex-direction: row;
  bottom: 10px;
  right: 16px;
  transform-origin: right;
  justify-content: center;
  animation: none;
  opacity: 0.9;
}

.step-9 .right-part-add-resi .contains-input .btn-delete-all .tooltip {
  left: 5px;
  transform: translate(-105%, 50%) !important;
  width: fit-content !important;
  white-space: nowrap;
  min-width: 100px;
  padding: 8px;
  border-radius: 6px;
  animation: show-opacity 0.2s ease-out forwards;
}
@keyframes show-opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.step-9 .right-part-add-resi .contains-input .btn-delete-all:hover {
  opacity: 1;
}
.step-9 .right-part-add-resi .contains-input .btn-delete-all svg {
  width: 30px;
  height: 30px;
  fill: var(--destructive-500);
}
@media screen and (max-width: 450px) {
  .step-9 .right-part-add-resi .contains-input .btn-delete-all {
    right: 10px;
    width: 45px;
    height: 45px;
  }
  .step-9 .right-part-add-resi .contains-input .btn-delete-all svg {
    width: 22px;
    height: 22px;
  }
  /* @media screen and (max-width: 350px) { */
  .step-9 .right-part-add-resi .contains-input .btn-delete-all .tooltip {
    display: none;
  }
  /* } */
}
.step-9 .modal-delete-all-photos .modal-contains {
  min-height: auto;
  max-width: 300px;
}
.step-9 .modal-delete-all-photos .modal-contains .btns {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 16px;
}
.step-9 .modal-delete-all-photos .modal-contains .content.bottom-child {
  margin-top: 62px;
}
.step-9 .modal-delete-all-photos .modal-contains span.p-just {
  /* margin-top: 60px; */
  color: var(--shade-black);
  font-size: 18px;
}
.step-9 .modal-delete-all-photos .modal-contains .btns .btn-cancel {
  background: var(--shade-black);
  color: var(--shade-white);
  font-weight: bold;
  /* border-radius: 8px; */
}
.step-9 .modal-delete-all-photos .modal-contains .btns .btn-delete {
  background: var(--destructive-400);
  color: var(--shade-white);
  font-weight: bold;
  /* border-radius: 8px; */
}

.step-9
  .right-part-add-resi
  .contains-input
  .contains-photos::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}
.contains-input .contains-photos .one-img {
  border-radius: 10px;
  width: calc(50% - 10px);
  overflow: hidden;
  height: fit-content;
  aspect-ratio: 1/1;
  opacity: 0;
  flex-shrink: 0;
  background: var(--shade-black);
  animation: showMe-Img 0.2s ease-out forwards;
}
@keyframes showMe-Img {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.contains-input .contains-photos .one-img:first-child {
  width: 100%;
  aspect-ratio: 630/301;
}
.contains-input .contains-photos .one-img img {
  border-radius: 10px;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
.contains-photos .one-img .btn-open-modal {
  padding: 6px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: var(--shade-white);
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 2;
}
.contains-photos .one-img .btn-open-modal img {
  width: 100%;
  height: 100%;
}
.contains-photos .one-img .am-the-thumb {
  padding: 8px 16px;
  background: var(--shade-white);
  border-radius: 8px;
  position: absolute;
  top: 16px;
  left: 16px;
}
.contains-photos .one-img .am-the-thumb span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.contains-photos .one-img .select-options {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  animation: show-me-option 0.2s ease-out forwards;
  row-gap: 16px;
  border-radius: 10px;
}
@keyframes show-me-option {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.contains-photos .one-img .select-options button {
  padding: 8px 16px;
  background: var(--shade-white);
  border-radius: 8px;
  justify-content: flex-start;
}
.contains-photos .one-img .select-options button :is(img, svg) {
  width: 16px;
  height: 16px;
}
.contains-photos .one-img .select-options button > span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
.contains-photos .one-img .select-options .btn-delete-photo {
  background: var(--destructive-400);
}
.contains-photos .one-img .select-options .btn-delete-photo span {
  color: var(--shade-white);
}
