.notifcations-section .contains {
  padding: 0 140px;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 55px;
  width: 100%;
  margin-top: 40px;
}

.notifcations-section .contains > h2 {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.8px;
}
.notifcations-section .second {
  position: fixed;
  top: 72px;
  width: 100vw;
  height: auto;
  padding-top: 20px;
  padding-bottom: 12px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);
  animation: show-header-page-slow 0.2s linear forwards;
}

@keyframes show-header-page-slow {
  from {
    transform: translateX(-50%) translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}
.notifcations-section .contains-notifs {
  row-gap: 8px;
  width: 100%;
  min-height: calc(78vh - 200px);
  /* height: auto; */
  overflow-y: scroll;
}
.notifcations-section .contains-notifs::-webkit-scrollbar {
  opacity: 0;
  width: 0;
}
.notifcations-section .contains-notifs .not-notif {
  color: var(--gray-400);
  padding: 12px 0;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.notifcations-section .contains-notifs .notif {
  width: 100%;
  column-gap: 16px;
  padding: 16px;
}

.notifcations-section .contains-notifs .notif .btn {
  column-gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 0;
  padding: 0;
}

.contains-notifs .notif .see-message .icon-message {
  column-gap: 16px;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 8px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--primary-50);
}

.notif .see-message .icon-message svg {
  width: 100%;
  height: 100%;
}

.notif .see-message .message {
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  row-gap: 4px;
}
.notif .see-message .message p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  flex: 1;
  text-align: left;
}
.notif .see-message .message.seen p {
  font-weight: 400;
}
.notif .see-message .message span {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.contains-notifs .notif .close-btn {
  border-radius: 50%;
  padding: 8px;
  transform: translateY(-8px);
}

.contains-notifs .notif .close-btn img {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 1150px) {
  .notifcations-section .contains {
    padding: 0 80px;
  }
}
@media screen and (max-width: 1050px) {
  .notifcations-section .contains {
    padding: 0 32px;
  }
  .notifcations-section .contains > h2 {
    margin-top: 10px;
    font-size: 32px;
    line-height: 40px;
  }
  .notifcations-section .second h2{
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 680px) {
  .notifcations-section .contains {
    padding: 0 20px;
    row-gap: 16px;
  }
  .notif .see-message .message p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  .contains-notifs .notif .see-message .icon-message {
    width: 40px;
    height: 40px;
  }
  .notifcations-section .contains > h2 {
    margin-top: 10px;
    font-size: 24px;
    line-height: 32px;
  }
  .notifcations-section .second h2{
    font-size: 18px;
    line-height: 28px;
  }
}
@media screen and (max-width: 550px) {
  .notifcations-section .contains {
    padding: 0;
    row-gap: 8px;
  }
  .notifcations-section .contains > h2 {
    margin-top: 10px;
    font-size: 18px;
    line-height: 28px;
  }
}
