.lister-resi {
  padding-top: 102px;
  padding-bottom: 102px;
  column-gap: 20px;
}

.lister-resi .left-side-resi {
  align-items: flex-start;
  width: 510px;
  row-gap: 40px;
}

.lister-resi .left-side-resi .title {
  align-items: flex-start;
  width: 100%;
}

.lister-resi .left-side-resi .title h2 {
  color: var(--shade-black);
  font-family: "Mori Gothic";
  font-size: 36px;
  font-weight: 600;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.lister-resi .left-side-resi .title p {
  width: 400px;
  color: var(--gray-700);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.lister-resi .right-side-resi {
  height: 584px;
  flex: 1;
}

.lister-resi .right-side-resi :is(.alt-1-resi, .alt-2-resi) {
  position: absolute;
  border-radius: 30px;
  right: 0;
  top: 0;
  height: 457px;
  width: 304px;
  object-fit: cover;
}

.lister-resi .right-side-resi .alt-1-resi {
  right: 325px;
}
.lister-resi .right-side-resi .alt-2-resi {
  top: 127px;
}

.design-card {
  position: absolute;
  width: 225px;
  height: 76px;
  z-index: 2;
}
.design-card .contains {
  width: 100%;
  height: 100%;
}
.design-card .contains > div {
  width: 100%;
  height: 100%;
}
.design-card .contains .blur {
  z-index: 1;
  background: #f57148;
  filter: blur(18px);
  border-radius: 11px;
}
.design-card .contains .content {
  position: absolute;
  z-index: 2;
  background: #f57148;
  border-radius: 11px;
  column-gap: 10px;
  padding: 13px 8px;
}
.design-card .contains .content p {
  color: var(--shade-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.design-card .contains .content .circle-prof {
  width: 50px;
  height: 50px;
  padding: 9px;
  background: #f9aa91;
  border-radius: 50%;
}
.design-card .contains .content .circle-prof .second-prof {
  width: 32px;
  height: 32px;
  padding: 6px;
  background: #fde5de;
  border-radius: 50%;
}
.design-card .contains .content .circle-prof .second-prof > svg {
  width: 20px;
  height: 20px;
}

.lister-resi .right-side-resi .design-card:nth-of-type(1) {
  top: 38px;
  left: 0px;
}
.lister-resi .right-side-resi .design-card:nth-of-type(2) {
  bottom: 91px;
  right: 216px;
}

.lister-resi .right-side-resi .design-card:nth-of-type(2) .contains .blur {
  z-index: 1;
  background: #a273ff;
  filter: blur(18px);
  border-radius: 11px;
}
.lister-resi .right-side-resi .design-card:nth-of-type(2) .contains .content {
  position: absolute;
  z-index: 2;
  background: #a273ff;
  border-radius: 11px;
  column-gap: 10px;
  padding: 13px 8px;
}
.lister-resi .right-side-resi .design-card:nth-of-type(2) .contains .content p {
  color: var(--shade-white);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.lister-resi
  .right-side-resi
  .design-card:nth-of-type(2)
  .contains
  .content
  .circle-prof {
  width: 50px;
  height: 50px;
  padding: 9px;
  background: #c7abff;
  border-radius: 50%;
}
.lister-resi
  .right-side-resi
  .design-card:nth-of-type(2)
  .contains
  .content
  .circle-prof
  .second-prof {
  width: 32px;
  height: 32px;
  padding: 6px;
  background: #eee6ff;
  border-radius: 50%;
}

.vector-right-paiement {
  right: 4px;
  transition: 0.3s;
}

@media screen and (max-width: 1439px) {
  .vector-right-paiement {
    opacity: 0;
  }
}

@media screen and (max-width: 1317px) {
  .lister-resi {
    padding: 80px;
  }

  .lister-resi {
    flex-direction: column;
    row-gap: 56px;
  }
  .lister-resi .left-side-resi {
    width: 100%;
    align-items: center;
    justify-content: center;
    order: 2;
  }
  .lister-resi .left-side-resi .title {
    align-items: center;
    justify-content: center;
  }
  .lister-resi .left-side-resi .title h2 {
    text-align: center;
  }
  .lister-resi .left-side-resi .title p {
    width: 100%;
    text-align: center;
  }
  .lister-resi .right-side-resi {
    order: 1;
    flex: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .lister-resi .right-side-resi .alt-1-resi {
    right: 50%;
    transform: translateX(calc(-50% + 130px));
  }
  .lister-resi .right-side-resi .alt-2-resi {
    left: 50%;
    transform: translateX(calc(50% - 139px));
  }
  .lister-resi .right-side-resi .design-card:nth-of-type(1) {
    left: 50%;
    transform: translateX(calc(50% - 491px));
  }
  .lister-resi .right-side-resi .design-card:nth-of-type(2) {
    right: 50%;
    transform: translateX(calc(-50% + 259px));
  }
}
@media screen and (max-width: 950px) {
  .lister-resi {
    padding: 80px 40px;
  }
}
@media screen and (max-width: 834px) {
  .lister-resi {
    padding: 80px 32px;
  }
}
@media screen and (max-width: 795px) {
  .lister-resi .right-side-resi {
    transform: scale(0.8);
  }
}
@media screen and (max-width: 612px) {
  .lister-resi .left-side-resi .title h2 {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
  .lister-resi .right-side-resi .design-card:nth-of-type(1) {
    left: 0;
    transform: translateX(-122px);
  }
  .lister-resi .right-side-resi .design-card:nth-of-type(2) {
    bottom: -29px;
  }
  .lister-resi .right-side-resi {
    transform: scale(0.7);
    height: 456px;
  }
}
@media screen and (max-width: 550px) {
  .lister-resi {
    padding-left: 16px;
    padding-right: 16px;
  }
  .lister-resi .left-side-resi .title h2 {
    font-size: 32px;
    letter-spacing: -0.64px;
  }
  .lister-resi .left-side-resi .title p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }
  .lister-resi .right-side-resi {
    transform: scale(0.6);
    height: 400px;
  }
}
@media screen and (max-width: 400px) {
  .lister-resi .right-side-resi .design-card:nth-of-type(1) {
    transform: translateX(-196px);
  }
  .lister-resi {
    padding-top: 0;
  }
  .lister-resi .right-side-resi {
    transform: scale(0.45);
    height: 315px;
  }
}
