.signalement-modal .title-modal label {
  width: 438px;
}
.signalement-modal .title-modal p {
  overflow: hidden;
  color: var(--shade-black, #1e1a21);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.signalement-modal {
  width: 100%;
  height: auto;
  row-gap: 24px;
}

.signalement-modal .contents {
  width: 100%;
}

.signalement-modal .contents .selectors {
  width: 100%;
  row-gap: 8px;
}

.signalement-modal .contents .select-btn {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--primary-200, #dac7ff);
  padding: 16px;
  justify-content: space-between;
}

.signalement-modal .contents .select-btn:hover {
  background: var(--primary-50);
}

.signalement-modal .contents .select-btn span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.signalement-modal .contents .select-btn .selector-circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid var(--gray-300);
}

.signalement-modal .contents .select-btn .selector-circle > span {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-color: var(--primary-200);
  background: var(--primary-200);
  transform: translate(-50%, -50%) scale(0.65);
}

.signalement-modal .validate-btn {
  padding: 16px 34px;
  margin-bottom: 8px;
}

.signalement-modal .validate-btn span {
  font-weight: 600;
}
