.main-residence-section .logement-residence {
  padding: 64px 0;
  border-top: 1px solid var(--gray-200);
  row-gap: 48px;
}

.main-residence-section .logement-residence label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.main-residence-section .logement-residence > div {
  border-radius: 30px;
  aspect-ratio: 1280/492;
  height: auto;
  width: 100%;
}
.main-residence-section .logement-residence .gm-style-cc a {
  display: none;
}
.main-residence-section .logement-residence span {
  display: none;
}
.main-residence-section .logement-residence .gmnoprint {
  display: none;
}
.main-residence-section .logement-residence .map-show {
  width: 100%;
  height: auto;
  background: var(--gray-200);
  user-select: none;
  pointer-events: none;
  border-radius: 30px;
}
.main-residence-section .logement-residence .map-show div {
  border-radius: 30px;
}
.main-residence-section .logement-residence .home-place {
  border-radius: 50%;
  border-radius: 358px;
  width: 30%;
  height: auto;
  aspect-ratio: 1/1;
  overflow: hidden;
}

.main-residence-section .logement-residence .home-place div {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  background: var(--primary-300);
  opacity: 0.4;
}

.main-residence-section .logement-residence .home-place .home {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 8px;
  background: var(--primary-500);
  width: 52px;
  height: 52px;
}
.main-residence-section .logement-residence .home-place .home svg {
  width: 80%;
  height: 80%;
}

.main-residence-section .logement-residence .info-reglement-warning {
  max-width: 70%;
  width: fit-content;
  aspect-ratio: auto;
  border-radius: 6px;
  padding: 10px 16px;
  transform: translateY(-25px);
}
@media screen and (max-width: 650px) {
  .main-residence-section .logement-residence .info-reglement-warning {
    width: 100%;
    max-width: 100%;

    transform: translateY(0);
  }
}
