.hd-inp {
  position: relative;
  transition: 0.3s;
  flex: 1;
}

.crt-inp {
  /* flex: 1.3 !important; */
}
.inval-inp {
  transform: translateY(5px);
}

.hd-inp input {
  width: 100%;
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--shade-black);
  padding: 16px;
  text-align: left;
  border: none;
  -webkit-appearance: none;
}

.hd-inp label {
  font-size: 12px;
}

.hd-inp input::placeholder {
  color: var(--shade-black, #1e1a21);
  text-overflow: ellipsis;
  font-weight: 500;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.location-search {
  left: -8px;
  position: absolute;
  padding: 24px 16px 24px 16px;
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  row-gap: 16px;
  top: calc(100% + 16px);
  width: 417px;
  animation: show-search 0.1s linear forwards;
}

@keyframes show-search {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.location-search .select-location {
  padding: 0;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--gray-200);
  justify-content: flex-start;
  border-radius: 0;
}
.location-search .select-location > div {
  width: 36px;
  height: 36px;
  padding: 6px;
  border-radius: 50%;
  background: var(--gray-200);
}
.location-search .select-location > img {
  width: 24px;
  height: 24px;
}
.location-search .select-location span,
.location-search .load-location span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.location-search .select-location:last-child {
  padding-bottom: 0;
  border: none;
}

.lds-ripple-load {
  display: inline-block;
  position: relative;
  width: 36px;
  height: 36px;
}
.lds-ripple-load div {
  position: absolute;
  border: 4px solid var(--gray-600);
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple-load 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple-load div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple-load {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}

.location-search.little-search {
  left: 0;
  /* width: 100%; */
  padding: 16px;
}
.location-search.little-search.header-search {
  max-width: 270px;
}
.location-search.little-search .select-location {
  padding: 0;
  padding-bottom: 8px;
}
.location-search.little-search .select-location:last-child {
  padding: 0;
  padding-bottom: 0px;
}
.location-search.little-search .select-location > div {
  width: 24px;
  height: 24px;
  padding: 4px;
}
.location-search.little-search .select-location > div img {
  width: 100%;
  height: 100%;
  transform: translateY(-2.5px);
}
.location-search.little-search .select-location span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  max-width: none !important;
}
.location-search.little-search .load-location span {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 950px) {
  .location-search {
    left: 0px;
  }
}
