.galerie-residence {
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  animation: showMe-galerie 0.3s linear forwards;
  background: var(--background);
}

@keyframes showMe-galerie {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.galerie-residence .full-contains {
  width: 100%;
  background: var(--background-bg-secondary);
  overflow-y: scroll;
  height: 100%;
  gap: 16px;
  padding-top: 20px;
  padding-bottom: 20px;
}
.contains-photos-zoom > button {
  position: fixed;
  z-index: 6;
}
.contains-photos-zoom .info-panel {
  position: fixed;
  z-index: 6;
  left: 0;
  bottom: 36px;
  width: 100vw;
  opacity: 1;
}
.contains-photos-zoom .info-panel p,
.contains-photos-zoom .info-panel span {
  color: var(--shade-white);
  font-family: Mori Gothic;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}
.contains-photos-zoom .info-panel span {
  font-size: 14px;
  font-weight: 400;
}
.contains-photos-zoom .info-panel .move-back svg {
  transform: rotate(180deg);
}
.contains-photos-zoom .info-panel .move-back,
.contains-photos-zoom .info-panel .move-next {
  padding: 10px 16px;
  align-items: center;
  justify-content: center;
}
.contains-photos-zoom .contains-photos-zoom button.close-btn {
  left: calc(15% / 2 - 24px);
  top: 56px;
  border-radius: 50%;
  padding: 18px;
  flex-shrink: 0;
}
.contains-photos-zoom .close-btn {
  position: fixed;
  left: calc(15% / 2 - 24px);
  top: 70px;
  border-radius: 50%;
  padding: 14px;
  flex-shrink: 0;
}
.contains-photos-zoom {
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  animation: showMe-galerie 0.3s linear forwards;
}
.contains-photos-zoom .background-photo {
  position: fixed;
  z-index: 4;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  opacity: 0.95;
  background: var(--shade-black, #1e1a21);
}
.contains-photos-zoom .react-transform-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
  width: calc(100% - 15%);
  height: calc(100% - 180px);
  border-radius: 16px;
  overflow: hidden;
}
.contains-photos-zoom .react-transform-wrapper > div {
  width: 100%;
  height: 100%;
  background: var(--shade-black);
  border-radius: 16px;
}
.contains-photos-zoom .react-transform-wrapper > div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 16px;
}
.galerie-residence .header-galerie-residence {
  background: var(--shade-white, #fff);
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--gray-200, #e5e7eb);
  box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.4),
    0px 18px 18px 0px rgba(255, 255, 255, 0.27),
    0px 41px 25px 0px rgba(255, 255, 255, 0.2),
    0px 74px 29px 0px rgba(255, 255, 255, 0.06),
    0px 115px 32px 0px rgba(255, 255, 255, 0);
}

.contains-photos-zoom .transform-photos {
  transition: 0.2s;
  width: 100vw;
  height: 100dvh;
  transform: translateX(0);
  opacity: 1;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 6;
}
.contains-photos-zoom .transform-photos.prev {
  transform: translateX(-100vw);
  opacity: 0;
}
.contains-photos-zoom .transform-photos.next {
  transform: translateX(100vw);
  opacity: 0;
}

.galerie-residence .header-galerie-residence button {
  background: var(--primary-50);
}

.galerie-residence .one-row {
  width: 100%;
  height: 536px;
  column-gap: 16px;
}
.galerie-residence .one-row > div {
  width: 100%;
  height: 536px;
}
.galerie-residence .one-row img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
  pointer-events: none;
  user-select: none;
}
.galerie-residence .one-row > .normal-div {
  /* width: calc(33% - 8px); */
  flex: 1;
  transition: 0.3s;
}
.galerie-residence .one-row > .full.normal-div {
  flex: 2;
}
.galerie-residence .one-row > div:hover {
  flex: 5.5;
  cursor: pointer;
}
.galerie-residence .one-row > .full.normal-div:hover {
  flex: 3;
  cursor: pointer;
}
.galerie-residence .only-one > .normal-div {
  width: 100%;
}
@media screen and (max-width: 610px) {
  .galerie-residence .one-row > .full.normal-div {
    flex: none !important;
  }
  .galerie-residence .one-row > .normal-div {
    flex: none !important;
  }
}
/* .galerie-residence .contains > div:first-child {
  width: calc(70% - 8px);
}
.galerie-residence .contains > div:nth-child(4n) {
  width: calc(70% - 8px);
}
.galerie-residence .contains > div:nth-of-type(5),
.galerie-residence .contains > div:nth-of-type(10),
.galerie-residence .contains > div:nth-of-type(15) {
  width: 100% !important;
}
.galerie-residence .contains > div:nth-child(5n) + div + div {
  width: calc(70% - 8px);
}
.galerie-residence .contains > div:nth-of-type(13) {
  width: calc(70% - 8px);
} */
