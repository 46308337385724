.step-15 .right-part-add-resi {
  border: none;
  padding: 0;
}
.step-15 .right-part-add-resi .evenement-type {
  padding: 0;
  border: none;
  row-gap: 24px;
  width: 100%;
}
.step-15 .right-part-add-resi .evenement-type .contains-select {
  column-gap: 20px;
  padding: 12px 0;
  border-bottom: 1px solid var(--gray-200);
}
.step-15 .right-part-add-resi .evenement-type .contains-select .left-side {
  align-items: flex-start;
  row-gap: 4px;
  flex: 1;
}
.step-15 .right-part-add-resi .evenement-type .contains-select .left-side p {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.56px;
}
.step-15 .right-part-add-resi .evenement-type .contains-select .left-side span {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.step-15
  .right-part-add-resi
  .evenement-type
  .contains-select
  .left-side
  :is(p, span) {
  text-align: left;
}

.step-15 .right-part-add-resi .evenement-type .contains-select .btn-actions {
  column-gap: 16px;
}

.step-15
  .right-part-add-resi
  .evenement-type
  .contains-select
  .btn-actions
  button {
  border-radius: 50%;
  padding: 8px;
  border: 1px solid var(--gray-200);
  width: 40px;
  height: 40px;
}
.step-15
  .right-part-add-resi
  .evenement-type
  .contains-select
  .btn-actions
  button
  svg {
  width: 100%;
  height: 100%;
}
