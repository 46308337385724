.modal-change-name .input {
  width: 100%;
  position: relative;
}

.modal-change-name .modal-contains {
  width: 370px !important;
  min-height: 275px;
}

.modal-change-name .input-field {
  row-gap: 10px !important;
  border: none !important;
}

.modal-change-name .input svg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}

.modal-change-name .input input {
  /* width: 100%;
  padding: 10px 10px 10px 30px;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  color: var(--shade-black);
  font-family: Mori Gothic;
  transition: all 0.3s ease; */
  border-radius: 5px;

  border: 1px solid #ccc;
  padding-left: 40px !important;
}
.full-panel-askcooking {
  display: flex;
  align-items: flex-end;
}
.full-panel-askcooking .background-touch {
  opacity: 0.5;
}

.full-panel-askcooking .information {
  z-index: 1;
  background: var(--shade-white);
  padding: 16px;
  width: 100vw;
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 810px) {
  .full-panel-askcooking .information :is(p, button, span) {
    font-size: 14px;
    line-height: 20px;
  }
}
