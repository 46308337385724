.reservation-list {
  padding-top: 30px;
  height: auto;
  min-height: calc(100dvh - 85px);
  padding-left: 0;
  padding-right: 0;
  width: 100%;
}

.reservation-list .btns-action {
  width: 100vw;
  border-bottom: 1px solid var(--gray-200);
  position: fixed;
  top: -10px;
  /* padding-top: 83px; */
  background: var(--shade-white);
  z-index: 1;
  margin-top: 93px;
  left: 0;
}
.reservation-list .contains-all {
  margin-top: 2px;
  width: 100%;
  height: calc(100dvh - 170px);
}
.reservation-list .btns-action button {
  padding: 14px 28px;
  border-radius: 0;
  border-bottom: 2px solid transparent;
}
.reservation-list .btns-action button span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
}
.reservation-list .btns-actions-line {
  flex-wrap: nowrap;
  width: 100%;
  overflow-x: scroll;
  /* scrollbar-color: rebeccapurple green; */
  scrollbar-color: transparent;
  /* scrollbar-width: thin; */
  scrollbar-width: none;
}
.reservation-list .btns-action button.current-btn span {
  color: var(--shade-black);
}

.reservation-list .btns-action button.current-btn {
  border-color: var(--primary-500);
}

.reservation-list .content-list {
  width: 100%;
  height: auto;
}

.reservation-list .no-data {
  row-gap: 16px;
  transform: translate(-50%, calc(-50% - 50px));
}
.reservation-list .no-data img {
  width: 32px;
  height: 32px;
}
.reservation-list .no-data .text {
  row-gap: 4px;
}
.reservation-list .no-data .text p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
}

.reservation-list .no-data .text span {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
}
@media screen and (max-width: 480px) {
  .reservation-list .no-data .text span {
    white-space: normal;
    max-width: calc(100vw - 32px);
  }
}
@media screen and (max-width: 320px) {
  .reservation-list .no-data .text p {
    white-space: normal;
    max-width: calc(100vw - 32px);
  }
}
.reservation-list .content-list {
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 20px;
  flex-wrap: wrap;
  height: 100%;
  /* overflow: scroll; */
}

.reservation-list .content-list {
  transition: 0.3s;
  position: absolute;
  width: 100%;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateX(0);
  opacity: 0;
  align-content: flex-start;
  max-width: 1440px;
  padding: 0 80px;
}
.reservation-list .content-list.visible {
  opacity: 1;
  pointer-events: all;
  transition-delay: 0.15s;
  overflow-x: visible;
  overflow-y: auto;
  white-space: nowrap;
  padding-top: 30px;
  padding-bottom: 40px;
}
.reservation-list .content-list.visible::-webkit-scrollbar {
  transform: translateX(10px);
}

.reservation-list .content-list.go-left {
  transform: translateX(-100px);
  pointer-events: none;
  user-select: none;
}
.reservation-list .content-list.go-right {
  transform: translateX(100px);
  pointer-events: none;
  user-select: none;
}
.reservation-list .content-list .load-skeleton-card,
.reservation-list .content-list .reservation-card {
  border-radius: 20px;
  border: 1px solid var(--primary-50, #f6f1ff);
  background: var(--shade-white, #fff);
  width: calc(100% / 2 - 10px);
  height: auto;
  height: 284px;
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  column-gap: 20px;
}

.reservation-list .content-list .load-skeleton-card .load-img,
.reservation-list .content-list .reservation-card > .left-side {
  height: 100%;
  width: 44%;
}

.reservation-list .content-list .reservation-card {
}
.reservation-list .content-list .reservation-card .txt-part {
  width: calc(100% - 44% - 16px);
}
.reservation-list .content-list .reservation-card .left-side img {
  width: 100%;
  border-radius: 16px;
  height: 100%;
  object-fit: cover;
}
.reservation-list .content-list .load-skeleton-card .txt-part {
  flex: 1;
  row-gap: 32px;
}

.reservation-list .contains-all {
  flex: 1;
}

.content-list .reservation-card .top-title .title {
  overflow: hidden;
  color: var(--shade-black);
  text-overflow: ellipsis;
  font-family: Mori Gothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
  max-width: 100%;
  width: calc(100% - 16px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-list .reservation-card .top-title .location {
  column-gap: 8px;
}
.content-list .reservation-card hr {
  width: 2px;
  height: 16px;
  margin: 0;
  /* border-radius: 1px; */
  background: var(--gray-200);
}
.content-list .reservation-card .top-title .location p {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  max-width: 100%;
  width: calc(100% - 16px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content-list .reservation-card .top-title .location .star {
  column-gap: 4px;
}
.content-list .reservation-card .top-title .location .star svg {
  width: 16px;
  height: 16px;
}
.content-list .reservation-card .top-title .location .star span {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}
.content-list .reservation-card .middle-part {
  row-gap: 8px;
}
.content-list .reservation-card .middle-part .one-part {
  column-gap: 8px;
  border-radius: 4px;
  background: var(--primary-50);
  padding: 4px 8px;
  width: min-content;
  /* flex-wrap: wrap; */
}
.content-list .reservation-card .middle-part .one-part span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  white-space: nowrap;
}
.content-list .reservation-card .middle-part .one-part p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  white-space: nowrap;
}
.content-list .reservation-card .middle-part .one-part p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  white-space: nowrap;
}
.content-list .reservation-card .actions-btn {
  column-gap: 5px;
}
.content-list .reservation-card .actions-btn button span {
  font-weight: 600;
}
.content-list .reservation-card .actions-btn .cancel-btn {
  background: var(--destructive-400);
  padding-left: 24px;
  padding-right: 24px;
}
.content-list .reservation-card .actions-btn button {
  flex: 1;
}
.content-list .reservation-card .actions-btn .cancel-btn {
  background: var(--destructive-400);
  /* flex: none; */
}
.content-list .reservation-card .actions-btn .cancel-btn:disabled {
  background: var(--issue-100) !important;
}

.content-list .reservation-card .top-corner-info {
  position: absolute;
  top: 28px;
  left: 28px;
  color: var(--shade-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  border-radius: 6.5px;
  padding: 7px;
  background: var(--shade-white);
}
.modal-details .modal-contains {
  width: 510px;
  max-width: 510px;
  height: fit-content;
  min-height: auto;
  padding-bottom: 80px;
}
.modal-details .check-modal-just .modal-contains {
  width: fit-content;
  max-width: calc(100vw - 32px);
  padding-bottom: 24px;
}
.modal-details .check-modal-just .modal-contains .input-div-versement {
  width: 100%;
}
.modal-details.cancel-confirm .modal-contains {
  padding-bottom: 20px;
}
.modal-details-perso {
  row-gap: 24px;
  padding-bottom: 20px;
}
.modal-details-perso .top-info {
  padding: 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.modal-details-perso .top-info img {
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  aspect-ratio: 441/248;
}
/* .modal-details-perso .top-info .title{
  padding-bottom: ;
} */
.modal-details-perso .top-info .title > label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  white-space: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.56px;
  text-align: left;
}
.modal-details-perso .top-info .title span {
  overflow: hidden;
  color: var(--gray-600);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
}
.modal-details-perso .top-info .title .star {
  column-gap: 4px;
}
.modal-details-perso .top-info .title .star svg {
  width: 24px;
  height: 24px;
}

.modal-details-perso .separator {
  width: 100%;
  height: 2px;
  border-radius: 100px;
  opacity: 0.5;
  background: var(--gray-200);
}
.modal-details-perso .hote-info {
  column-gap: 16px;
}
.modal-details-perso .hote-info img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}
.modal-details-perso .hote-info span {
  flex: 1;
}
.modal-details-perso .hote-info span,
.modal-details-perso .hote-info a {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-align: left;
  line-height: 24px;
}

.modal-details-perso .hote-info .call-hote {
  background: var(--primary-800);
  padding: 4px 8px;
  border-radius: 6px;
  border: 1px solid var(--primary-500);
  color: var(--shade-white);
  position: absolute;
  top: 0;
  right: 0;
}
.modal-details-perso .hote-info .call-hote span {
  color: var(--shade-white);
  font-family: Inter;
  font-weight: 600;
  font-size: 14px;
}
.modal-details-perso .hote-info .call-hote img {
  width: 24px;
  height: 24px;
}
.modal-details-perso .hote-info p {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  letter-spacing: -0.4px;
}

.modal-details-perso .info-reservation {
  row-gap: 16px;
}
.modal-details-perso .one-row {
  row-gap: 6px;
}
.modal-details-perso .one-row label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.modal-details-perso .one-row p {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.modal-details-perso .localisation p {
  line-height: 20px;
}
.modal-details-perso .localisation img {
  width: 36px;
  height: 36px;
}
.modal-details-perso .one-row:last-child {
}
.modal-details-perso button:last-child {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 32px);
  border-radius: 6px;
  font-weight: 600;
}
.modal-details-perso button:last-child span {
  font-weight: 600;
}
/* .modal-details-perso .see-info-btn span {
  font-weight: 600;
} */

.refund-btn-cancel {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: var(--shade-white);
}

.cancel-reason-container {
  row-gap: 10px;
}
.cancel-reason-container-parent .comment-div,
.cancel-reason-container-parent .comment-div {
  width: 100%;
  height: auto;
  row-gap: 8px;
}
.cancel-reason-container-parent .comment-div textarea,
.cancel-reason-container-parent .comment-div textarea {
  width: 100%;
  height: 92px;
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  resize: none;
}
.cancel-reason-container-parent .comment-div textarea:focus,
.cancel-reason-container-parent.comment-div textarea:focus {
  outline-color: var(--primary-200);
  border: 1px solid var(--primary-200);
}
.cancel-reason-container-parent .comment-div textarea::placeholder,
.cancel-reason-container-parent .comment-div textarea::placeholder {
  color: var(--gray-400);
}

.cancel-reason-container-parent .comment-div > span,
.cancel-reason-container-parent .comment-div > span {
  width: 100%;
  text-align: right;
}
.cancel-reason-container-parent p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  text-align: center;
}
.cancel-reason-container-parent .selectors {
  margin-bottom: 16px;
  row-gap: 6px;
}

.cancel-confirm .second-conf {
  width: 400px;
  /* max-width: calc(100vw - 32px); */
}
.refund-modal .modal-contains {
  width: 400px;
}

.refund-modal .confirm-panel {
  width: 100%;
}

.cancel-confirm .select-btn {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--primary-200, #dac7ff);
  padding: 16px;
  justify-content: space-between;
}

.cancel-confirm .second-conf .select-btn:hover {
  background: var(--primary-50);
}

.cancel-confirm .second-conf .select-btn span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.cancel-confirm .second-conf .select-btn .selector-circle {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 2px solid var(--gray-300);
}
.modal-write .modal-contains {
  width: 350px;
  height: fit-content;
  min-height: fit-content;
}
.modal-write .modal-contains textarea {
  width: 100%;
  height: 200px;
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  padding: 16px;
  resize: none;
  outline: none;
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.cancel-confirm .second-conf .select-btn .selector-circle > span {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border-color: var(--primary-200);
  background: var(--primary-200);
  transform: translate(-50%, -50%) scale(0.65);
}

.cancel-confirm .second-conf .validate-btn {
  padding: 16px 34px;
  margin-bottom: 8px;
}

.cancel-confirm .second-conf .validate-btn span {
  font-weight: 600;
}
.cancel-confirm .second-conf .info-select {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 550px) {
  .modal-details .modal-contains {
    max-width: calc(100vw - 32px);
  }
}
@media screen and (max-width: 450px) {
  .modal-details .modal-contains {
    max-width: calc(100vw - 32px);
  }
  .cancel-confirm .second-conf {
    width: calc(100vw - 70px);
    /* max-width: calc(100vw - 32px); */
  }
}

@media screen and (max-width: 1350px) {
  .reservation-list .content-list.visible {
  }
  .reservation-list .content-list .reservation-card .txt-part {
    width: 50%;
  }
}
@media screen and (max-width: 1350px) {
  .reservation-list .content-list.visible .reservation-card {
    min-height: 284px;
    height: auto;
  }
  .reservation-list .content-list .reservation-card .txt-part {
    width: 50%;
  }
  .content-list .reservation-card .actions-btn {
    flex-wrap: wrap;
    row-gap: 4px;
  }
}
@media screen and (max-width: 1200px) {
  .reservation-list .content-list.visible .reservation-card {
    position: relative;
  }
  .reservation-list .content-list .reservation-card > .left-side {
    position: absolute;
    width: 94px !important;
    height: 86px !important;
    top: 11px;
    left: 14px;
  }

  .reservation-list .content-list .reservation-card .txt-part {
    width: 100%;
    justify-content: flex-start;
    row-gap: 20px;
  }
  .reservation-list .content-list .reservation-card .txt-part .top-title {
    margin-left: 110px;
    margin-top: 25px;
  }
  .content-list .reservation-card .top-corner-info {
    position: absolute;
    top: 9px !important;
    left: 120px !important;
  }
}

@media screen and (max-width: 1050px) {
  .reservation-list .content-list {
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 55px !important;
  }
}
@media screen and (max-width: 950px) {
  .reservation-list .btns-action {
    margin-top: 88px;
  }
}
@media screen and (max-width: 700px) {
  .reservation-list .content-list {
    padding-left: 16px;
    padding-right: 16px;
  }
  .reservation-list .content-list .reservation-card {
    width: 100%;
  }
  .content-list .reservation-card .top-corner-info {
    position: absolute;
    top: 28px !important;
    left: 28px !important;
  }
  .reservation-list .content-list .reservation-card .txt-part {
    width: calc(100% - 44% - 16px) !important;
  }
  .reservation-list .content-list .reservation-card .txt-part .top-title {
    margin-left: 0;
    margin-top: 0;
  }
  .reservation-list .content-list .reservation-card .txt-part {
    width: 100%;
    justify-content: space-between;
    row-gap: 2px;
  }
  .reservation-list .content-list .reservation-card > .left-side {
    width: calc(44% - 16px) !important;
    height: calc(100% - 33px) !important;
    top: auto;
    left: 16px;
    opacity: 1;
  }
  .reservation-list .content-list.visible .reservation-card {
    justify-content: flex-end;
  }
  .reservation-list .btns-actions-line {
    flex-wrap: nowrap;
    width: 100%;
    overflow-x: scroll;
    /* scrollbar-width: thin; */
  }
  .reservation-list .btns-action button span {
    white-space: nowrap;
  }
  .reservation-list .btns-actions-line:hover {
    cursor: grabbing;
  }
  .reservation-list .btns-actions-line::-webkit-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .reservation-list .btns-actions-line::-webkit-scrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .reservation-list .btns-actions-line::-webkit-scrollbar-thumb {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .reservation-list .btns-actions-line::-webkit-scrollbar-track {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
@media screen and (max-width: 550px) {
  .reservation-list .btns-action {
    margin-top: 88px;
  }
}
@media screen and (max-width: 540px) {
  .reservation-list .content-list.visible .reservation-card {
    position: relative;
  }
  .reservation-list .content-list .reservation-card > .left-side {
    position: absolute;
    width: 94px !important;
    height: 86px !important;
    top: 11px;
    left: 14px;
  }

  .reservation-list .content-list .reservation-card .txt-part {
    width: 100%;
    justify-content: flex-start;
    row-gap: 20px;
  }
  .reservation-list .content-list .reservation-card .txt-part .top-title {
    margin-left: 110px;
    margin-top: 25px;
  }
  .content-list .reservation-card .top-corner-info {
    position: absolute;
    top: 9px !important;
    left: 120px !important;
  }
  .reservation-list .content-list.visible .reservation-card {
    justify-content: flex-start;
  }
  .reservation-list .content-list .load-skeleton-card {
    width: calc(100%) !important;
  }
  .reservation-list .content-list .reservation-card .txt-part {
    width: calc(100%) !important;
  }
}
@media screen and (max-width: 480px) {
  .bottom-price label {
    text-wrap: wrap !important;
    /* 2 lignes max eclipsis */

    max-width: 100%;

    overflow: hidden;

    text-overflow: ellipsis;

    white-space: nowrap;
  }
  .bottom-price > .one-row {
    width: 60%;
    flex: content !important;
  }
  .bottom-price .recap-panel {
    width: calc(100vw - 66px) !important;
    min-width: 100px;
    row-gap: 0;
  }
  .bottom-price .recap-panel .line {
    position: relative;
    margin-bottom: 12px;
    max-width: 100%;
    text-wrap: wrap !important;
  }
  .bottom-price .recap-panel .line:first-child {
    margin-top: 12px;
  }
  .bottom-price .recap-panel .line:last-child {
    margin-bottom: 0;
  }
  .bottom-price .recap-panel .line > p {
    text-wrap: wrap !important;
    font-size: 10px !important;
  }
  .bottom-price .recap-panel .line > p strong {
    text-wrap: wrap !important;
    line-height: 16px;
  }
  .bottom-price .recap-panel .line > p > span {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(45%);
  }
}
@media screen and (max-width: 420px) {
  .rlve.bottom-price {
    flex-direction: column !important;
  }
  .bottom-price > .sepa-vert {
    width: 100% !important;
    height: 2px !important;
    margin: 10px 0 !important;
  }
  .bottom-price > .one-row {
    width: 100% !important;
    flex: content !important;
  }
  .bottom-price .tooltip-recap {
    transform: translateY(5%);
  }
}
@media screen and (max-width: 340px) {
  .content-list .reservation-card .middle-part .one-part:nth-of-type(2) {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 300px) {
  .content-list .reservation-card .top-corner-info {
    overflow: hidden;
    max-width: 40%;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
