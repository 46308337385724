.left-part-add-resi {
  width: calc(45.1388%);
}
.right-part-add-resi {
  flex: 1;
  height: 100%;
}

.cover .content {
  width: 100%;
  height: 100%;
  padding-left: 0;
}
.cover .content .cover-div-left {
  height: 100%;
  background: var(--primary-50);
}
section.add-resi {
  /* width: 100vw; */
  z-index: 1;
  padding-left: 0 !important;
  height: 100dvh;
}
section.add-resi-input .contains {
  padding-left: 0 !important;
  height: 100%;
}
section.add-resi-input {
  transition: 0.3s;
  position: fixed;
  width: 100vw;
  height: 100dvh;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translateY(0);
  opacity: 0;
}
section.add-resi-input.visible {
  opacity: 1;
  pointer-events: all;
  transition-delay: 0.15s;
}
section.add-resi-input.go-top {
  transform: translateY(-100px);
  pointer-events: none;
  user-select: none;
}
section.add-resi-input.go-bottom {
  transform: translateY(100px);
  pointer-events: none;
  user-select: none;
}
.cover {
  position: fixed;
  z-index: 0;
  opacity: 1 !important;

  width: 100vw;
}
section .left-part-add-resi .content {
  width: 100%;
  padding: 0 14%;
  row-gap: 24px;
}

section .left-part-add-resi .content .step {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}
section .left-part-add-resi .content label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 52px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: -1.04px;
}
section.input-resi .left-part-add-resi .content label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.8px;
}
section.input-resi .left-part-add-resi .content > span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
section.add-resi-input.input-resi .right-part-add-resi > .contains-input {
  width: 100%;
  height: 100%;
  padding-left: 10.12%;
}
section .left-part-add-resi .content p {
  color: var(--shade-black, #1e1a21);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.modal-resi-container .modal-contains {
  width: 350px;
  min-height: fit-content;
}

@media screen and (max-width: 950px) {
  section.add-resi-input .contains {
    padding-left: 32px !important;
    padding-right: 32px;
    flex-direction: column;
    padding-bottom: 100px;
  }
  .left-part-add-resi {
    width: 100%;
    order: 2;
    /* padding-bottom: 50px; */
  }
  .stp1 .left-part-add-resi {
    width: 100%;
    order: 2;
    padding-bottom: 50px;
  }
  .step-1-annonce .right-part-add-resi img {
    width: 100% !important;
    max-height: calc(40dvh);
    object-fit: contain !important;
  }
  .cover .content {
    padding-right: 0;
  }
  /* .cover-div-left */
  .cover .content {
    display: none;
  }

  section .left-part-add-resi .content {
    padding: 0 !important;
  }
  .add-residence-next-footer .contains-next-footer {
    width: 100%;
    padding-right: 0px;
  }
  .add-residence-next-footer .contains-next-footer .left-part-add-resi {
    display: none;
    margin: 0;
  }
  .add-residence-next-footer .contains-next-footer .right-part-add-resi {
    margin: 0;
    padding-left: 32px;
    padding-right: 32px;
  }
  .add-residence-next-footer
    .contains-next-footer
    .right-part-add-resi
    .contains-btns {
    /* padding-bottom: 8px; */
  }
  .add-residence-next-footer {
    padding-bottom: 10px !important;
  }
  .contains-next-footer .right-part-add-resi .bars-load {
    width: 100% !important;

    padding-bottom: 8px !important;
  }
  section.add-resi-input {
    overflow-y: scroll;
  }
  .top-header-add-resi {
    background: var(--shade-white);
  }
  section.add-resi-input {
    top: 69px;
    height: calc(100dvh - 37px - 100px);
    padding-top: 25px;
  }
  .top-header-add-resi {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    border-bottom: 1px solid var(--gray-100, #f3f4f6);
    box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.2),
      0px 18px 18px 0px rgba(255, 255, 255, 0.17),
      0px 41px 25px 0px rgba(255, 255, 255, 0.1),
      0px 74px 29px 0px rgba(255, 255, 255, 0.03),
      0px 115px 32px 0px rgba(255, 255, 255, 0);
  }
  section.input-resi .right-part-add-resi {
    width: 100%;
    order: 2;
  }
  section.input-resi .left-part-add-resi {
    width: 100%;
    order: 1;
  }
  section.input-resi .right-part-add-resi .contains-input {
    width: 100%;
    padding: 0 !important;
    padding-bottom: 0 !important;
  }
  section.input-resi {
    padding-top: 5vh;
  }

  /* STEP 3 */
  section.input-resi .right-part-add-resi .filter-selected-panel {
    padding-bottom: 30px !important;
  }
  /* STEP 4 */
  section.input-resi .right-part-add-resi {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  section.step-4 .right-part-add-resi .contains-input > div {
    width: 100% !important;
    min-height: 200px;
    max-height: calc(50dvh);
    /* margin-bottom: 24px; */
  }
  section.step-4 .right-part-add-resi .contains-input {
    padding-bottom: 50px;
  }
  .step-4 .left-part-add-resi .content .input-location {
    z-index: 3;
  }

  /* STEP 9 */
  .step-9 .right-part-add-resi {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .step-9 .right-part-add-resi .contains-input .contains-photos {
    height: auto !important;
    overflow: visible !important;
  }
  .step-13 .right-part-add-resi .content button span {
    text-align: left;
  }

  .step-13 .right-part-add-resi .content {
    padding-left: 0 !important;
    padding-right: 0 !important;
    justify-content: flex-start;
  }
  .step-1-annonce .photos-adder {
    margin-bottom: 10px;
  }
  .step-1-annonce .map-google {
    margin-bottom: 10px;
  }
  /* STEP 15 */
  .step-15.input-resi .right-part-add-resi {
    padding-bottom: 20px;
  }
  section.step-16 .right-part-add-resi .contains-selects,
  section.step-17 .right-part-add-resi .contains-selects {
    width: 100%;
    height: auto;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 20px;
    overflow: auto;
  }
  section.step-16 .right-part-add-resi .activities-type,
  section.step-17 .right-part-add-resi .remboursement-type {
    overflow: visible !important;
    height: auto;
    padding-right: 0;
  }
  section.step-16 .left-part-add-resi .regle {
    padding: 8px;
  }
  section.step-16.input-resi .right-part-add-resi {
    height: auto !important;
  }
  section.step-15 .left-part-add-resi .content {
    row-gap: 16px;
  }
  section.step-17 .right-part-add-resi {
    justify-content: flex-start;
    align-items: flex-start;
    overflow: visible;
    height: auto;
  }
  section.input-resi .filter-selected-panel .residence-type button span {
    width: 100%;
    text-align: center;
  }
  section.step-2
    .right-part-add-resi
    .filter-selected-panel
    .residence-type
    button {
    justify-content: center;
    align-items: center;
  }

  section.step-9 {
    top: 0 !important;
    padding-top: 0 !important;
    height: 100dvh !important;
  }
  section.step-9 .left-part-add-resi {
    margin-bottom: 10px;
  }
  @media screen and (max-height: 900px) {
    section.step-9 .left-part-add-resi {
      display: none;
    }
  }
  @media screen and (max-width: 530px) {
    section.step-9 .right-part-add-resi .contains-input {
      row-gap: 16px;
    }
    section.step-9 .right-part-add-resi .title-show label {
      font-size: 18px;
      line-height: 24px;
    }
    section.step-9 .photos-adder {
      padding: 8px 12px;
    }
    section.step-9 .photos-adder span {
      font-size: 10px;
    }

    section.step-9 .right-part-add-resi {
      margin-top: 0px;
    }
  }
  section.step-9 .right-part-add-resi .contains-input {
    row-gap: 20px;
  }
  section.step-9 .right-part-add-resi {
    overflow: hidden;
    margin-bottom: 15px;
  }
  section.step-9 .contains {
    padding-top: 80px !important;
    padding-bottom: 70px !important;
    overflow: hidden;
  }
  section.step-9 .right-part-add-resi .contains-input .contains-photos {
    height: -webkit-fill-available !important;
    padding-bottom: 0 !important;
    overflow-x: hidden !important;
    overflow-y: scroll !important;
  }
}
@media screen and (max-width: 650px) {
  section.input-resi .left-part-add-resi .content label,
  section .left-part-add-resi .content label {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px;
  }
  .step-10 .right-part-add-resi .contains-input input {
    padding: 10px !important;
    border-radius: 6px !important;
    font-size: 18px !important;
  }
  section .left-part-add-resi .content p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  /* step 11 */
  .step-11 .right-part-add-resi .contains-input textarea {
    border-radius: 6px !important;
    padding: 10px !important;
  }
  .step-13 .left-part-add-resi .content {
    padding-bottom: 20px !important;
  }
  .step-14 .right-part-add-resi .price-manager .price-show span,
  .step-14 .right-part-add-resi .price-manager input {
    font-size: 42px !important;
  }
  .step-14 .right-part-add-resi .price-manager .price-show .devise {
    font-size: 22px !important;
  }
  .step-14 .right-part-add-resi .price-manager .price-show button {
    transform: scale(0.75);
  }
  section.step-15 .right-part-add-resi {
    margin-top: 40px;
  }
}
@media screen and (max-width: 550px) {
  .add-residence-next-footer .contains-next-footer .right-part-add-resi {
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
  }
  section.add-resi-input .contains {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }
  .step-13 .right-part-add-resi .content button .card-coche {
    width: 26px !important;
    height: 26px !important;
    border-radius: 4px !important;
    flex-shrink: 0;
  }
  .step-13 .right-part-add-resi .content button .card-coche > span {
    width: 18px !important;
    height: 18px !important;
    border-radius: 4px !important;
  }
  .contains-photos .one-img .select-options button {
    max-width: calc(100% - 4px);
  }
  .contains-photos .one-img .select-options {
    padding-top: 40px;
  }
  .step-11 .right-part-add-resi .contains-input textarea {
    /* height: 20dvh !important;
    min-height: 120px !important;
    max-height: 250px !important; */
  }
  section.step-15 .right-part-add-resi {
    margin-top: 60px;
  }
  section.step-15.input-resi .right-part-add-resi {
    margin-top: 80px;
  }
  section.step-15 .right-part-add-resi .evenement-type {
    padding-bottom: 10px;
    row-gap: 24px;
  }
  section.step-17 .right-part-add-resi .remboursement-type .contains-zone {
    padding: 0;
  }
}
@media screen and (max-width: 420px) {
  section.input-resi .left-part-add-resi .content label,
  section .left-part-add-resi .content label {
    font-size: 26px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 32px !important;
    letter-spacing: -0.44px !important;
  }
  .step-15 .right-part-add-resi .evenement-type .contains-select .left-side p {
    font-size: 22px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 28px !important;
    letter-spacing: -0.38px !important;
  }
}
@media screen and (max-width: 390px) {
  .step-9.add-resi-input.input-resi
    .right-part-add-resi
    > .contains-input
    .title-show {
    flex-direction: column !important;
    row-gap: 8px;
  }
}
