.tooltip-recap {
  position: relative;
  margin-left: auto;
  cursor: pointer;
  z-index: 2;
}
.tooltip-recap:hover {
  /* filter: brightness(1.1); */
  opacity: 1;
}

.recap-panel {
  border-radius: 5px;
  padding: 12px;
  position: absolute;
  right: 0;
  min-width: 350px;
  z-index: 10;
  background-color: var(--gray-700);
  row-gap: 6px;
  cursor: default;
}

.recap-panel.inverse {
  background-color: var(--gray-700);
  bottom: 100%;
}
.recap-panel p {
  align-items: center;
  column-gap: 4px;
}
.recap-panel :is(p, strong, span) {
  color: var(--shade-white) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  text-wrap: nowrap !important;
  font-family: "Inter" !important;
}

.recap-panel .line {
  display: flex;
  column-gap: 15px;
  justify-content: space-between;
  align-items: center;
}
.recap-panel strong {
  font-weight: 600 !important;
  font-size: 14px !important;
}

@media screen and (max-width: 480px) {
  .recap-panel {
    width: calc(100vw - 32px) !important;
    min-width: 100px;
    row-gap: 0;
    z-index: 10;
  }
  .recap-panel .line {
    position: relative;
    margin-bottom: 12px;
    max-width: 100%;
    text-wrap: wrap !important;
  }
  .recap-panel .line:first-child {
    margin-top: 12px;
  }
  .recap-panel .line:last-child {
    margin-bottom: 0;
  }
  .recap-panel .line > p {
    text-wrap: wrap !important;
    font-size: 10px !important;
  }
  .recap-panel .line > p strong {
    text-wrap: wrap !important;
    line-height: 16px;
  }
  .recap-panel .line > p > span {
    position: absolute;
    left: 0;
    bottom: 100%;
    transform: translateY(45%);
  }
}
@media screen and (max-width: 420px) {
  .tooltip-recap {
    transform: translateY(5%);
  }
}
