.favoris-section {
  padding-bottom: 40px;
}
.favoris-section .title label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.8px;
}
.favoris-section .second-info {
  row-gap: 16px;
  max-width: 519px;
}
.favoris-section .second-info p {
  transition: 0.3s;
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.favoris-section .second-info span {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.favoris-section .title {
  padding-top: 16px;
  padding-bottom: 16px;
}

.favoris-section .second {
  position: fixed;
  top: 72px;
  width: 100vw;
  height: auto;
  padding-top: 20px;
  padding-bottom: 12px;
  z-index: 2;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);
  animation: show-header-page-slow 0.2s linear forwards;
}

@keyframes show-header-page-slow {
  from {
    transform: translateX(-50%) translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}

.favoris-section .content {
  min-height: calc(70vh - 75px);
  row-gap: 24px;
}

.favoris-list {
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 32px;
}
.favoris-list > .card-resi-content,
.favoris-list > .card-resi {
  width: calc(100% / 3 - 40px / 3);
  height: auto;
}
.favoris-section .favoris-list > .card-resi-content .top-card,
.favoris-section .favoris-list > .card-resi .top-card {
  /* background: var(--gray-400);
  width: 100%;
  height: auto;
  aspect-ratio: 305/200; */
}

@media screen and (max-width: 1000px) {
  .favoris-section .favoris-list > .card-resi-content .bot-val,
  .favoris-section .favoris-list > .card-resi .bot-val {
    padding: 16px 16px 24px 16px;
    height: max-content;
  }
}
@media screen and (max-width: 950px) {
  /* .favoris-section .favoris-list > .card-resi-content .top-card {
    width: 100%;
    height: auto;
    aspect-ratio: 305/236; 
  } */
  .favoris-list {
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 16px;
  }
  .favoris-list > .card-resi-content,
  .favoris-list > .card-resi {
    width: calc(100% / 2 - 16px / 2);
    height: auto;
  }
  .favoris-section .title label {
    margin-top: 10px;
    font-size: 24px;
    line-height: 32px;
  }
  .favoris-section .title h2 {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 680px) {
  .favoris-section .second-info p {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
  .favoris-section .second-info span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
  .favoris-section .title h2 {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (max-width: 550px) {
  /* .favoris-section .favoris-list > .card-resi-content .top-card {
    width: 100%;
    height: auto;
    aspect-ratio: 305/236;
    max-height: 300px;
  } */
  .favoris-list > .card-resi-content,
  .favoris-list > .card-resi {
    width: calc(100%);
    height: auto;
  }
  .favoris-section .title label {
    margin-top: 10px;
    font-size: 18px;
    line-height: 28px;
  }
}
