.connexion-panel {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: fadeIn 0.3s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.connexion-panel .background-touch {
  width: 100%;
  height: 100%;
  background: rgba(30, 26, 33, 0.5);
}

.connexion-panel .contains {
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  width: 439px;
  padding: 24px 56px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  row-gap: 24px;
}
.connexion-panel .come-center {
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: all 0.2s ease-in-out;
}

.connexion-panel .go-left-side {
  left: -100%;
  transform: translate(100%, -50%);
  opacity: 0;
  transition: all 0.15s ease-in-out;
  pointer-events: none;
}

.connexion-panel .go-right-side {
  left: 100%;
  transform: translate(-100%, -50%);
  opacity: 0;
  transition: all 0.15s ease-in-out;
  pointer-events: none;
}

.connexion-panel .contains label {
  color: var(--gray-900);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.connexion-panel .contains .input-field {
  width: 100%;
  height: auto;
  row-gap: 16px;
}
.connexion-panel .contains .input {
  width: 100%;
  height: auto;
}
.connexion-panel .contains .input input {
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  padding: 16px;
  padding-left: 42px;
}
.input .indicatif-select {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  z-index: 2;
}
.input .indicatif-select .indicatif {
  position: relative;
  overflow: hidden;
  border-right: 1px solid var(--gray-200);
  padding-right: 4px;
  transition: 0.2s;
}

.input .indicatif-select .indicatif input {
  padding-left: 12px;
  padding-right: 0px;
  background: transparent !important;
  border: none;
  border-radius: 0;
}
.input .indicatif-select .indicatif::before {
  content: "+";
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  color: var(--gray-900);
  font-family: Mori Gothic;
  font-weight: 600;
}
.indicatif-select .indicatif-selected {
  position: absolute;
  bottom: calc(100% + 8px);
  left: -48px;
  z-index: 2;
  /* transform: translateY(-50%); */
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  width: 335px;
  max-width: calc(100vw - 48px);
  border-radius: 8px;
  background: var(--shade-white);
  justify-content: flex-start;
  align-items: flex-start;
  box-shadow: -3px 6px 14px 0px rgba(135, 135, 135, 0.05),
    -10px 23px 25px 0px rgba(135, 135, 135, 0.04),
    -23px 51px 34px 0px rgba(135, 135, 135, 0.03),
    -41px 91px 40px 0px rgba(135, 135, 135, 0.01),
    -63px 142px 43px 0px rgba(135, 135, 135, 0);
  padding: 4px 0;
  animation: show-me-slowly-to-top 0.3s ease-in-out forwards;
  background: var(--gray-100);
}
@media screen and (max-width: 550px) {
  .indicatif-select .indicatif-selected {
    width: 305px;
  }
}
@media screen and (max-width: 360px) {
  .indicatif-select .indicatif-selected {
    width: calc(100vw - 60px);
  }
}
.indicatif-select .indicatif-selected button {
  padding: 8px;
  border-bottom: 1px solid var(--gray-200);
  width: 100%;
  border-radius: 0;
  justify-content: flex-start;
}
.indicatif-select .indicatif-selected button span {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  max-width: fit-content;
}
.indicatif-select img {
  width: 30px;
  height: 20px;
  border-radius: 4px;
  background: var(--shade-white);
  object-fit: cover;
}
.indicatif > img {
  opacity: 0;
  animation: show-me-slowly-img 0.3s ease-in-out forwards;
}
@keyframes show-me-slowly-img {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes show-me-slowly-to-top {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.input .indicatif-select .indicatif.nothing::before {
  color: var(--gray-400);
}
.connexion-panel .contains .input.phone-input > input {
  padding-left: 95px;
}

.connexion-panel .contains .password input {
  padding-right: 42px;
}

.connexion-panel .contains .input input:focus {
  border: 1px solid var(--gray-600);
  background: var(--gray-50);
}

.connexion-panel .contains .input input {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
.connexion-panel .contains .input input::placeholder {
  color: var(--gray-400);
  font-weight: 400;
}
.connexion-panel .contains .input > svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  pointer-events: none;
}
.connexion-panel .contains .input > .see-pass {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  padding: 0;
}
.connexion-panel .contains .input .detect-issue-input {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  max-width: 100%;
  background: var(--issue-100);
  padding: 5px;
  border-radius: 4px;
  opacity: 0;
  animation: showMe 0.2s ease-in-out forwards;
  z-index: 2;
}
@keyframes showMe {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.connexion-panel .contains .input .detect-issue-input .chevron {
  position: absolute;
  top: -7px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--issue-100);
  left: 10px;
  transform: scale(1.3);
  z-index: 2;
}
.connexion-panel .contains .input .detect-issue-input span {
  color: var(--issue-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.connexion-panel .contains .next-btn {
  border-radius: 100px;
  background: var(--primary-500);
  padding: 16px 34px;
  align-items: center;
  justify-content: center;
  color: var(--shade-white);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
}
.connexion-panel .contains .separator {
  width: 100%;
  flex-wrap: nowrap;
}
.connexion-panel .contains .separator .line {
  flex: 1;
  height: 1px;
  background: var(--gray-200);
}
.connexion-panel .contains .label-separator .line {
  height: 2px;
}

.connexion-panel .contains .separator span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  padding: 8px;
}
.connexion-panel .contains .social-connect {
  width: 100%;
}
.connexion-panel .contains .social-connect :is(button, .fb-login button) {
  border-radius: 100px;
  border: 1px solid var(--gray-900, #111827);
  column-gap: 8px;
  width: 100%;
}
.connexion-panel .contains .social-connect :is(button, .fb-login button) span {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--gray-900);
  font-family: Inter;
}
.connexion-panel .contains .social-connect :is(button, .fb-login button) img {
  width: 20px;
  height: 20px;
}

.connexion-panel .contains .other-choice,
.connexion-panel .contains .label-second {
  width: 100%;
  text-align: center;
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.connexion-panel .contains .label-second {
  text-align: left;
}
.connexion-panel .contains .other-choice span,
.connexion-panel .contains .forget-pass {
  color: var(--primary-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  display: inline;
  width: auto;
}

.connexion-panel .contains .other-choice span:hover,
.connexion-panel .contains .forget-pass:hover {
  cursor: pointer;
}

.connexion-panel .contains .forget-pass {
  align-self: flex-start;
}
.connexion-panel .contains .back-btn {
  padding: 0;
  position: absolute;
  top: 24px;
  left: 56px;
}

@media screen and (max-width: 850px) {
  @media screen and (max-height: 550px) {
    .connexion-panel .contains {
      padding: 16px;
      width: calc(100% - 32px);
      margin: 0;
      row-gap: 16px;
      max-width: 328px;
    }
    .connexion-panel
      .contains
      .social-connect
      :is(button, .fb-login button)
      span {
      color: var(--gray-900);
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    .connexion-panel .contains .separator span {
      padding: 4px;
    }
    .connexion-panel .contains .next-btn {
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      padding: 12px 24px;
    }
    .connexion-panel .contains .back-btn {
      top: 16px;
      left: 16px;
    }
    .connexion-panel .contains .other-choice,
    .connexion-panel .contains .label-second {
      text-align: center;
    }
  }
}
@media screen and (max-width: 550px) {
  .connexion-panel .contains {
    padding: 16px;
    width: calc(100% - 32px);
    margin: 0;
    row-gap: 16px;
    max-width: 328px;
  }
  .connexion-panel
    .contains
    .social-connect
    :is(button, .fb-login button)
    span {
    color: var(--gray-900);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .connexion-panel .contains .separator span {
    padding: 4px;
  }
  .connexion-panel .contains .next-btn {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 12px 24px;
  }
  .connexion-panel .contains .back-btn {
    top: 16px;
    left: 16px;
  }
  .connexion-panel .contains .other-choice,
  .connexion-panel .contains .label-second {
    text-align: center;
  }
}
