.header-hote-select {
  column-gap: 16px;
}

.header-hote-select button,
.header-hote-select a {
  text-decoration: none;
  background: var(--primary-50);
  width: 126px;
}
.header-hote-select a:hover {
  text-decoration: none;
}
.header-hote-select button svg,
.header-hote-select a svg {
  width: 24px;
  height: 24px;
  display: none;
}
.header-hote-select button span,
.header-hote-select a span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  white-space: nowrap;
  line-height: 20px;
}

.header-hote-select button.active,
.header-hote-select a.active {
  background: var(--primary-800);
}
.header-hote-select button.active span,
.header-hote-select a.active span {
  color: var(--shade-white);
}

.hote-panel .header-actions > label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
}

@media screen and (max-width: 1024px) {
  .header-hote-select {
    column-gap: 4px;
  }
  .header-hote-select button,
  .header-hote-select a {
    width: auto;
    border-radius: 4px;
    padding: 12px 16px;
  }
  header .hd-right-btn .notif-btn {
    display: none;
  }
}
@media screen and (max-width: 860px) {
  /* header .tc-btn {
    display: none;
  }
  header .tc-btn-2 {
    display: block;
  } */
  .header-hote-select button,
  .header-hote-select a {
    border-radius: 4px;
    padding: 12px 8px;
  }
  .header-hote-select button span,
  .header-hote-select a span {
    font-size: 12px;
  }
}

@media screen and (max-width: 860px) {
  /* header .tc-btn {
    display: none;
  }
  header .tc-btn-2 {
    display: block;
  } */
  .header-hote-select button,
  .header-hote-select a {
    border-radius: 4px;
    padding: 12px 8px;
  }
  .header-hote-select button span,
  .header-hote-select a span {
    font-size: 12px;
  }
}
@media screen and (max-width: 700px) {
  header.shadow-box-active-hote .tc-btn {
    display: none;
  }
  header.shadow-box-active-hote .tc-btn-2 {
    display: block;
  }
  .hote-panel .header-actions > label {
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 650px) {
  header .tc-btn {
    display: block;
  }
  header .tc-btn-2 {
    display: none;
  }
  .header-hote-select {
    position: fixed;
    width: 100vw;
    left: 0;
    bottom: 0;
    padding-top: 5px;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
    background: var(--shade-white);
    z-index: 3;
  }
  .header-hote-select button,
  .header-hote-select a {
    width: calc(100% / 4 - 4px / 3);
  }
  .header-hote-select button span,
  .header-hote-select button a {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    /* display: -webkit-box; */
  }
  .header-hote-select button svg,
  .header-hote-select a svg {
    display: block;
  }
}

@media screen and (max-width: 500px) {
  .header-hote-select button,
  .header-hote-select a {
    flex-direction: column;
    row-gap: 2px;
  }
  .stats-hote-section .right-panel-side.gains .price-gains p {
    font-size: 32px !important;
    line-height: 36px !important;
  }
}
@media screen and (max-width: 350px) {
  .stats-hote-section
    .right-part
    .right-panel-side.note-global.gains
    .percent-txt
    div {
    width: calc(100%) !important;
  }
  .stats-hote-section
    .right-part
    .right-panel-side.note-global.gains
    .percent-txt
    div
    button,
  .stats-hote-section
    .right-part
    .right-panel-side.note-global.gains
    .percent-txt
    div
    a {
    width: 100%;
  }
}
