.step-4 .left-part-add-resi .content .input-location {
  width: 100%;
}
.step-4 .left-part-add-resi .content .input-location svg {
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}
.step-4 .left-part-add-resi .content .input-location input {
  width: 100%;
  border-radius: 1000px;
  border: 1px solid var(--gray-300);
  background: var(--shade-white);
  padding: 14px 10px 14px 40px;
}
.step-4 .left-part-add-resi .content .input-location input:focus {
  border: 1px solid var(--primary-200);
}

.step-4 .left-part-add-resi .content .input-location input {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.step-4 .left-part-add-resi .content .input-location input::placeholder {
  color: var(--gray-400);
}
.step-4 .right-part-add-resi .contains-input > .map-google {

}

.step-4 .right-part-add-resi .contains-input > .div {
  display: none;
}
.step-4 .right-part-add-resi .contains-input > .map-google {
  height: auto !important;
  width: 93.73% !important;
  border-radius: 16px;
  aspect-ratio: 630/480;
}
.step-4 .right-part-add-resi .contains-input > .map-google .gm-style-cc a {
  display: none;
}
.step-4 .right-part-add-resi .contains-input > .map-google span {
  display: none;
}
.step-4 .right-part-add-resi .contains-input > .map-google .gmnoprint {
  display: none;
}
.step-4 .right-part-add-resi .contains-input > .map-google:first-child {
  /* display: none; */
}

.modal-google-address .modal-contains {
  /* height: 200px; */
  min-height: fit-content;
}

@media screen and (max-width:500px) {
  .step-4 .right-part-add-resi .contains-input > .map-google {
    width: 100% !important;
    height: 100% !important;
}
}