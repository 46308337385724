.choice-user {
  width: 100%;
  max-width: 343px;
  row-gap: 32px;
  overflow: hidden;
}

.choice-user-prefs .modal-contains .content.bottom-child {
  margin-top: 50px !important;
}
.choice-user-prefs .modal-contains {
  min-height: min-content !important;
  width: 380px;
}
.choice-user .choose-menu {
  border-bottom: 1px solid var(--gray-200);
  background: var(--shade-white, #fff);
  justify-content: center;
}

.choice-user .choose-menu button {
  padding: 16px;
  border-radius: 0;
}
.choice-user .choose-menu .selected {
  border-bottom: 2px solid var(--primary-500, #a273ff);
}
.choice-user .choose-menu button span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.choice-user .side-scroll {
  column-gap: 24px;
  transition: 0.3s;
  flex-wrap: nowrap;
  height: auto;
}

.choice-user .side-scroll.show-devise {
  transform: translateX(calc(-100% - 24px));
}

.choice-user .side-scroll > div {
  flex-shrink: 0;
}

.choice-user .confirm-btn {
  margin-top: 10px;
  width: 100%;
  background-color: var(--primary-300);
  padding: 16px;
  border-radius: 8px;
}
.choice-user .confirm-btn span {
  font-size: 16px;
  font-weight: 600;
  color: var(--shade-white);
}

.choice-user .confirm-btn:hover {
  background-color: var(--primary-500);
}

.choice-user .confirm-btn:disabled {
  background-color: var(--gray-200);
  cursor: not-allowed;
}
