.hd-input-second {
  align-items: flex-start;
  padding: 16px 34px;
  border-radius: 1000px;
  margin: 0 8px;
}
.second-shower-lbl-inp {
  display: none;
}
.hero-section .header-search .hd-input-second:nth-of-type(4) {
  margin-right: 0px;
}

.hd-input-second-date {
  gap: 0;
  position: static;
}
@media screen and (min-width: 1050px) {
  .hd-input-second:nth-of-type(4) {
    padding-right: 10px;
  }
}

.hd-input-second-date > label {
  margin-bottom: 8px;
}

.hd-input-second input::placeholder {
  color: var(--gray-400);
  font-size: 14px;
  font-weight: 500 !important;
}
.hd-input-second input {
  padding: 0;
  font-size: 14px;
}

.hd-input-second > .spanner-show {
  position: absolute;
  font-size: 14px;
  max-width: calc(100% - 32px);
  top: auto;
  bottom: 16px;
  left: 34px;
  transform: translateY(0);
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hd-input-second .lbl-inp-second {
  position: relative;
  text-align: left;
  color: var(--shade-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

/*INPUT HEADER*/

.hd-input-second.date-header {
  padding: 0 16px;
  margin: 0;
}
.second-shower-lbl-inp {
  display: none;
}

.hd-input-second-date {
  gap: 0;
  position: static;
}

.hd-input-second-date > label {
  margin-bottom: 8px;
}

.hd-input-second input::placeholder {
  color: var(--gray-400);
  font-size: 14px;
  font-weight: 500 !important;
}
.hd-input-second input {
  padding: 0;
  font-size: 14px;
}

.hd-input-second > .spanner-show {
  position: absolute;
  font-size: 14px;
  max-width: calc(100% - 32px);
  top: auto;
  bottom: 16px;
  left: 34px;
  transform: translateY(0);
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hd-input-second.date-header .lbl-inp-second {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  margin: 0;
}

/*HEADER SECOND*/
.header-search .hd-input-second {
  padding: 0 4px;
  margin: 0;
  flex: 1 !important;
  row-gap: 2px;
}
.header-search button > span {
  width: 100%;
  max-width: 90px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.header-search .react-datepicker__navigation > span {
  width: auto;
  max-width: max-content;
  overflow: visible;
  white-space: nowrap;
}
.header-search .hd-input-second .lbl-inp-second {
  position: relative;
  text-align: left;
  color: var(--shade-black);
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  margin: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80px;
}

.header-search .filter-extend-searching {
  position: absolute;
  transform-origin: right;
  top: calc(100% + 16px);
  padding: 40px 16px 24px 16px;
  border-radius: 16px;
  border: 1px solid var(--gray-200, #e5e7eb);
  background: var(--shade-white, #fff);
  right: 0;
  /* CARD BOX SHADOW */
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  width: 417px;
  z-index: 3;
}
.header-search .background-touch-hide {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: none;
}
.header-search .filter-selected-panel {
  row-gap: 24px;
}
.header-search .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.header-search .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.header-search .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.header-search .filter-selected-panel .reset-done {
  width: 100%;
  background: var(--shade-white);
  padding: 8px 24px 0px 24px;
  border-top: 1px solid var(--gray-200, #e5e7eb);
}

.header-search .filter-selected-panel .reset-done .reset-btn {
  color: var(--destructive-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.header-search .filter-selected-panel .reset-done .reset-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-decoration-line: underline;
}

/*HEADER SEARCH*/

.header-search .filter-extend-searching {
  position: absolute;
  transform-origin: right;
  top: calc(100% + 16px);
  padding: 16px;
  border-radius: 16px;
  border: 1px solid var(--gray-200, #e5e7eb);
  background: var(--shade-white, #fff);
  right: 0;
  /* CARD BOX SHADOW */
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  width: 350px;
  z-index: 3;
}
.header-search .background-touch-hide {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: none;
}
.header-search .filter-selected-panel {
  row-gap: 8px;
}
.header-search .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-size: 14px;
  font-weight: 600;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.header-search .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.header-search .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.header-search .filter-selected-panel .reset-done {
  width: 100%;
  background: var(--shade-white);
  padding: 10px 8px 0px 8px;
  border-top: 1px solid var(--gray-200, #e5e7eb);
}

.header-search .filter-selected-panel .reset-done .reset-btn {
  color: var(--destructive-400);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.header-search .filter-selected-panel .reset-done .reset-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-decoration-line: underline;
}
