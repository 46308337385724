.step-14 .right-part-add-resi .price-manager {
  row-gap: 16px;
}
.step-14 .right-part-add-resi .price-manager p {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}
.step-14 .right-part-add-resi .price-manager input,
.step-14 .right-part-add-resi .price-manager .price-show .price {
  width: 300px;
  text-align: center;
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 68px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
  letter-spacing: -1.36px;
  border: none;
  border-radius: 16px;
  padding: 12px;
}
.step-14 .right-part-add-resi .price-manager .price-show {
  pointer-events: none;
}
.step-14 .right-part-add-resi .price-manager .price-show .price {
  padding: 0;
  white-space: nowrap;
}
.step-14 .right-part-add-resi .price-manager .price-show .price.focus {
  animation: alternate-show 1.2s linear infinite;
}
@keyframes alternate-show {
  from {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.step-14 .right-part-add-resi .price-manager input {
  z-index: 4;
  color: transparent;
}
.step-14 .right-part-add-resi .price-manager input::placeholder {
  color: transparent;
}
.step-14 .right-part-add-resi .price-manager .price-show {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 8px));
  width: auto;
  min-width: 32px;
  max-width: 350px;
  z-index: 5;
}
.step-14 .right-part-add-resi .price-manager .price-show .devise {
  position: absolute;
  top: -28px;
  right: -24px;
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.step-14 .right-part-add-resi .price-manager .price-show button {
  position: absolute;
  z-index: 3;
  padding: 8px;
  bottom: 16px;
  right: -48px;
  width: 38px;
  height: 38px;
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  border: 1px solid var(--gray-200);
  pointer-events: all;
}
.step-14 .right-part-add-resi .price-manager .price-show button.focus {
  background: var(--gray-200);
}

.step-14 .right-part-add-resi .price-manager .price-show button img {
  width: 100%;
  height: 100%;
}
