.step-5 .right-part-add-resi .filter-selected-panel {
  padding: 0;
  border: none;
}
.step-5 .right-part-add-resi .filter-selected-panel .info-localite {
  width: 100%;
  border: none;
  padding: 0;
  row-gap: 16px;
}
.step-5 .right-part-add-resi .filter-selected-panel .info-localite > div {
  width: 100%;
  padding: 16px 0;
  border-bottom: 1px solid var(--gray-200);
}
.step-5 .right-part-add-resi .filter-selected-panel .info-localite > div label {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
