.filter-accueil-mobile .next-step {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 24px;
  background: var(--shade-white);
    z-index: 3;
}
.filter-accueil-mobile .next-step button:first-child span {
  color: var(--destructive-500);
}
.filter-accueil-mobile .next-step button svg {
  fill: none;
}

.filter-accueil-mobile .header-galerie-residence {
  padding: 12px 16px !important;
}
.filter-accueil-mobile .lasted-search {
  row-gap: 8px;
}
.filter-accueil-mobile .lasted-search label {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.4px;
  color: var(--shade-black);
  font-weight: 500;
  font-family: Inter;
}
.filter-accueil-mobile .lasted-search .lasted-search-contain {
  row-gap: 2px;
  width: 100%;
}
.filter-accueil-mobile .lasted-search .lasted-search-contain button {
  justify-content: flex-start;
  padding-left: 0;
  column-gap: 8px;
  border-bottom: 1px solid var(--gray-100);
  width: 100%;
}
.filter-accueil-mobile .lasted-search .lasted-search-contain button:last-child {
  border-bottom: none;
}
.filter-accueil-mobile .lasted-search .lasted-search-contain button img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--gray-200);
  padding: 6px;
}
.filter-accueil-mobile .lasted-search button span {
  width: 100%;
  text-align: left;
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.filter-accueil-mobile .full-contains {
    padding-bottom: 110px !important;
}
