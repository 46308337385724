.step-13 .right-part-add-resi .content {
  padding-left: 20%;
  padding-right: 9.15%;
  justify-content: flex-start;
}
.step-13 .right-part-add-resi .content p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.step-13 .right-part-add-resi .content button {
  justify-content: flex-start;
  padding-left: 0;
  column-gap: 16px;
}
.step-13 .right-part-add-resi .content button span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.step-13 .right-part-add-resi .content button .card-coche {
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: 2px solid #1e1a21;
}

.step-13 .right-part-add-resi .content button .card-coche span {
  width: 22px;
  height: 22px;
  border-radius: 6px;
  background: var(--primary-500);
}
