.main-residence-section {
  padding-top: 40px;
  width: 100%;
}
@media screen and (max-width: 600px) {
  .main-residence-section {
    padding-top: 20px;
  }
}
.main-residence-section .contains-main-residence {
  width: 100%;
  /* width: calc(66.171%); */
  height: auto;
  padding-right: calc(100% - 66.171%);
}
.main-residence-section .contains-main-residence > div {
  padding: 32px 0;
  width: 100%;
  border-bottom: 1px solid var(--gray-200, #e5e7eb);
}
.main-residence-section .contains-main-residence > div:first-child {
  padding-top: 0;
  padding-bottom: 24px;
  align-items: flex-start;
}

.contains-main-residence .contains-main-residence .hote-litle-info {
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
}

.contains-main-residence .hote-litle-info > img {
  flex-shrink: 0;
  width: 96px;
  height: 96px;
  border-radius: 1000px;
  background: var(--primary-50);

  border: 1px solid var(--gray-200);
  object-fit: cover;
}
.contains-main-residence .hote-litle-info > .name-hote-info {
  width: 100%;
}
.contains-main-residence .hote-litle-info > .name-hote-info > span {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.contains-main-residence .hote-litle-info > .name-hote-info > h2 {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.72px;
  margin-bottom: 8px;
}
.contains-main-residence .hote-litle-info > .name-hote-info svg {
  width: 16px;
  height: 16px;
}
.contains-main-residence .hote-litle-info .info-resi {
  margin-left: 8px;
  column-gap: 16px;
}
.contains-main-residence .hote-litle-info .type-logement {
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  padding: 4px 8px;
  flex-shrink: 0;
  width: fit-content;
  margin-bottom: 8px;
}
.contains-main-residence .hote-litle-info > .name-hote-info p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.contains-main-residence .description-residence {
  row-gap: 8px;
  align-items: flex-start;
}
.contains-main-residence .description-residence p,
.contains-main-residence .description-residence li,
.contains-main-residence .cancel-condition-residence p,
.apercu-residence .contains-apercu .apercu-part p,
.apercu-residence .contains-apercu .apercu-part li {
  overflow: hidden;
  color: var(--gray-600, #4b5563);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4; /* Limite le nombre de lignes à afficher */
  text-overflow: ellipsis;
  white-space: normal; /* Permet le retour à la ligne */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.contains-main-residence .description-residence li {
  margin-bottom: 4px;
}
.contains-main-residence .description-residence li strong {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  line-height: 24px;
}
.sepa-vert {
  width: 2px;
  height: 50px;
  border-radius: 10px;
  /* position: absolute; */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  background: var(--gray-200);
}
.reservation-list .amount-txt {
  color: var(--destructive-500) !important;
  font-family: "Mori Gothic";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
}
.reservation-list .discount-txt {
  padding: 2px 5px;
  color: var(--shade-white);
  background: var(--destructive-500);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}
@media screen and (max-width: 600px) {
  .contains-main-residence .description-residence li {
    /* text-align: justify; */
  }
}
.contains-main-residence .description-residence button {
  padding: 4px 0;
}
.contains-main-residence .description-residence button span {
  color: var(--primary-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

/*COMODITIE*/

.contains-main-residence .comodities-residence,
.contains-main-residence .cancel-condition-residence {
  row-gap: 24px;
}
.contains-main-residence > div label {
  color: var(--shade-black, #1e1a21);
  font-family: Mori Gothic;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.72px;
}
.contains-main-residence .comodities-residence .comodities-contains,
.comodities-modal .contents {
  width: 100%;
  flex-wrap: wrap;
  gap: 24px 48px;
}
.comodities-modal {
  row-gap: 24px;
  max-width: 436px;
}
.comodities-modal .contents {
  gap: 24px;
  padding-bottom: 16px;
}
.comodities-residence .comodities-contains .comoditie,
.comodities-modal .contents .comoditie {
  column-gap: 8px;
}
.comodities-residence .comodities-contains .comoditie img,
.comodities-modal .contents .comoditie img {
  width: 20px;
  height: 20px;
}
.comodities-contains .comoditie svg,
.comodities-modal .contents .comoditie svg {
  width: 20px;
  height: 20px;
  stroke: var(--primary-500);
}
.comodities-contains .comoditie span,
.comodities-modal .contents .comoditie span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.contains-main-residence .comodities-residence .show-all-commodities {
  border-radius: 100px;
  border: 1px solid var(--primary-300);
  align-self: flex-start;
}
.comodities-residence .show-all-commodities span {
  color: var(--primary-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

/*Cancel Condition */
.contains-main-residence .cancel-condition-residence > div {
  height: auto;
}

/*Apercu Residence*/
.contains-main-residence .apercu-residence {
  row-gap: 24px;
}
.contains-main-residence .apercu-residence .contains-apercu {
  flex-wrap: wrap;
  gap: 24px 16px;
}
.apercu-residence .contains-apercu .apercu-part {
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(45% - 8px);
}
.apercu-residence .contains-apercu .apercu-part img {
  width: 24px;
  height: 24px;
}
.apercu-residence .contains-apercu .apercu-part > div {
  width: 100%;
}
.apercu-residence .contains-apercu .apercu-part h4 {
  overflow: hidden;
  color: var(--gray-700);
  text-overflow: ellipsis;
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.apercu-residence .contains-apercu .apercu-part p,
.apercu-residence .contains-apercu .apercu-part li {
  -webkit-line-clamp: 2;
}
.contains-apercu .apercu-part .list-options {
  list-style-type: circle;
}
.apercu-residence .contains-apercu .apercu-part li {
  display: flex;
  overflow: visible;
}
.apercu-residence .contains-apercu .apercu-part li::before {
  background: var(--primary-500);
  content: "";
  width: 5px;
  height: 5px;
  background: var(--gray-700);
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
  position: absolute;
}

.cancel-condition-residence .list-condition {
  width: 100%;
  row-gap: 24px;
}
.cancel-condition-residence .btn-show-all {
  margin-right: auto;
}
.cancel-condition-residence .btn-show-all span {
  color: var(--primary-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* text-decoration: underline;
  text-underline-offset: 2px; */
}
.cancel-condition-residence .list-condition > li {
  width: 100%;
  column-gap: 4px;
  position: relative;
  padding-left: 35px;
  flex-wrap: wrap;
  align-items: flex-start;
}
.cancel-condition-residence .list-condition > li::before {
  content: "";
  position: absolute;
  left: 10px;
  top: 12px;
  transform: translateY(-40%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--shade-black);
  display: inline-block;
}

.cancel-condition-residence .list-condition > li p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.cancel-condition-residence .list-condition > li span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  transform: translateY(1.4px);
}

@media screen and (max-width: 980px) {
  .commentary-residence .contains-commentaires > .commentary:nth-of-type(3) {
    display: none;
  }
  .commentary-residence .contains-commentaires > .commentary {
    width: calc(50% - 10px) !important;
  }
}
@media screen and (max-width: 780px) {
  .main-residence-section .contains-main-residence {
    width: 100%;
    /* width: calc(66.171%); */
    height: auto;
    padding-right: calc(100% - 55.171%);
  }
  .main-residence-section .reservation-panel-bottom {
    width: calc(100% - 55.171% - 16px) !important;
  }
  .main-residence-section article.fixed-clamp-reservation,
  .main-residence-section .waiting-load.fixed-clamp-reservation {
    transform: translateX(calc(13.5% + 13px)) !important;
  }
}

@media screen and (max-width: 550px) {
  .commentary-residence .contains-commentaires > .commentary {
    width: calc(87%) !important;
  }
  .commentary-residence .contains-commentaires > .commentary:nth-of-type(3) {
    display: flex;
  }
}
