.contents-commentary {
  width: 882px;
  column-gap: 40px;
}
.contents-commentary .commentary-recap {
  row-gap: 24px;
}
.contents-commentary .commentary-recap .title svg {
  width: 24px;
  height: 24px;
}
.contents-commentary .commentary-recap .title label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.56px;
}
.contents-commentary .bar-state > div {
  width: 365px;
}
.contents-commentary .bar-state > div > span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.contents-commentary .bar-state > div .right-side span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.contents-commentary .bar-state > div .right-side .bar {
  width: 145px;
  height: 6px;
  border-radius: 6px;
  background: var(--gray-200);
  overflow: hidden;
}
.contents-commentary .bar-state > div .right-side .bar .remplissage {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 100%;
  width: 10px;
  background: var(--primary-500);
  animation: show-bar-comment 0.8s ease-out forwards;
}

.contents-commentary .commentary-list {
  row-gap: 12px;
  overflow-y: scroll;
  max-height: 500px;
  height: max-content;
  padding-bottom: 20px;
}
.contents-commentary .commentary-list::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}
@keyframes show-bar-comment {
  from {
    transform: translateX(-100%) translateY(-50%);
  }
  to {
    transform: translateX(0) translateY(-50%);
  }
}

.contents-commentary .commentary-list .commentary {
  width: 100%;
}

.new-commentary-contains {
  width: 882px;
  row-gap: 24px;
}

.new-commentary-contains .residence-view {
  column-gap: 8px;
}

.new-commentary-contains .residence-view > img {
  height: 76px;
  width: 115px;
  object-fit: cover;
  border-radius: 6px;
}

.new-commentary-contains .residence-view > .info-residence-view {
  row-gap: 4px;
  align-items: flex-start;
  justify-content: flex-start;
}

.residence-view > .info-residence-view > .label-title {
  color: var(--shade-black) !important;
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  width: 300px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.residence-view > .info-residence-view .info-second {
  align-items: flex-start;
  justify-content: flex-start;
}

.residence-view > .info-residence-view .info-second > p,
.info-residence-view .info-second .star-info span {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.info-residence-view .info-second .star-info {
  column-gap: 4px;
}
.info-residence-view .info-second .star-info svg {
  width: 14px;
  height: 14px;
}

.new-commentary-contains .stars-selector {
  width: 100%;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
}

.new-commentary-contains .stars-selector .stars {
  column-gap: 24px;
}
.new-commentary-contains .stars-selector button {
  border-radius: 0;
  padding: 0;
}
.new-commentary-contains .stars-selector button svg {
  width: 32px;
  height: 32px;
  /* clip-path: inset(0 0 0 0); */
}
.new-commentary-contains .stars-selector button svg {
  transition: clip-path 0.45s ease-out;
}
.new-commentary-contains .stars-selector button.not svg {
  transition: clip-path 0.17s ease-out;
}
.new-commentary-contains .stars-selector button svg:last-child {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  right: 100%;
}
.new-commentary-contains .stars-selector .comment-div,
.cancellation .comment-div {
  width: 100%;
  height: auto;
  row-gap: 8px;
}
.stars-selector .comment-div textarea,
.cancellation .comment-div textarea {
  width: 100%;
  height: 92px;
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding: 16px;
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  resize: none;
}
.stars-selector .comment-div textarea:focus,
.cancellation .comment-div textarea:focus {
  outline-color: var(--primary-200);
  border: 1px solid var(--primary-200);
}
.stars-selector .comment-div textarea::placeholder,
.cancellation .comment-div textarea::placeholder {
  color: var(--gray-400);
}

.stars-selector .comment-div > span,
.cancellation .comment-div > span {
  width: 100%;
  text-align: right;
}

.stars-selector .others-note-div {
  row-gap: 12px;
}

.stars-selector .others-note-div > span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.stars-selector .others-note-div .others-note {
  width: 100%;
  column-gap: 16px;
  flex-wrap: wrap;
  row-gap: 16px;
}
.others-note-div .others-note .card-note {
  width: calc(100% / 3 - 32px / 3);
  border-radius: 16px;
  border: 1px solid var(--gray-300);
  background: var(--shade-white);
  padding: 8px 16px 16px 16px;
  row-gap: 16px;
  justify-content: center;
}
@media screen and (min-width: 951px) {
  .others-note-div .others-note .card-note:nth-child(4),
  .others-note-div .others-note .card-note:nth-child(5) {
    width: calc(100% / 2 - 16px / 2);
  }
  .new-commentary-contains
    .stars-selector
    .others-note
    .card-note:nth-child(4)
    .stars,
  .new-commentary-contains
    .stars-selector
    .others-note
    .card-note:nth-child(5)
    .stars {
    justify-content: center;
    column-gap: 16px;
  }
}

.others-note-div .others-note .card-note .title {
  row-gap: 8px;
}
.others-note-div .others-note .card-note .title label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.others-note-div .others-note .card-note .title span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.new-commentary-contains .stars-selector {
  width: 100%;
  justify-content: center;
  align-items: center;
  row-gap: 16px;
}

.new-commentary-contains .stars-selector .others-note .card-note .stars {
  column-gap: 5px;
}
.new-commentary-contains .stars-selector .others-note .card-note button {
  border-radius: 0;
  padding: 0;
}

.new-commentary-contains .btn-send-commentary {
  justify-content: flex-end;
}
.new-commentary-contains .btn-send-commentary button {
  width: 364px;
}
.new-commentary-contains .btn-send-commentary button span {
  color: var(--shade-white);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media screen and (max-width: 920px) {
  .contents-commentary {
    width: 100%;
    column-gap: 40px;
  }
  .contents-commentary .bar-state > div {
    width: 270px;
  }
}
