.step-3 .right-part-add-resi .filter-selected-panel {
  border: none;
  padding: 0;
}
.step-3 .right-part-add-resi .filter-selected-panel .accessibility-type {
  padding: 0;
  border: none;
  row-gap: 20px;
  width: 100%;
}
.step-3 .right-part-add-resi .filter-selected-panel .accessibility-type button {
  border: 1px solid var(--primary-200);
  padding: 16px;
  row-gap: 20px;
  width: 100%;
  border-radius: 10px;
  column-gap: 60px;
}
.step-3
  .right-part-add-resi
  .filter-selected-panel
  .accessibility-type
  button.selected-type {
  background: var(--primary-50);
}
.step-3
  .right-part-add-resi
  .filter-selected-panel
  .accessibility-type
  button
  .info-btn {
  flex: 1;
  align-items: flex-start;
  background: none;
}
.step-3
  .right-part-add-resi
  .filter-selected-panel
  .accessibility-type
  button
  .info-btn
  label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.56px;
  text-align: left;
}
.step-3
  .right-part-add-resi
  .filter-selected-panel
  .accessibility-type
  button
  .info-btn
  span {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}
.step-3
  .right-part-add-resi
  .filter-selected-panel
  .accessibility-type
  button
  svg {
  width: 32px;
  height: 32px;
}
