.photos-adder {
  border-radius: 20px;
  border: 1px dashed var(--gray-400, #9ca3af);
  background: var(--shade-white, #fff);
  width: 100%;
  height: auto;
  aspect-ratio: 630/480;
  cursor: pointer;
  padding: 20px;
}

.step-8 .photos-adder img {
  width: 40px;
  height: 40px;
}

.step-8 .photos-adder label {
  margin-top: 16px;
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
  text-align: center;
}
.step-8 .photos-adder p {
  margin-top: 8px;
  color: var(--gray-500, #6b7280);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.step-8 .photos-adder span {
  margin-top: 32px;
  color: var(--primary-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
}
