.card-store-map {
  border-radius: 12px;
  overflow: hidden;
  width: 532px;
  height: 162px;
  background-color: white;
  box-shadow: 0px 13px 29px 0px #6d72740d, 0px 52px 52px 0px #6d72740a,
    0px 118px 71px 0px #6d727408, 0px 209px 84px 0px #6d727403,
    0px 327px 91px 0px #6d727400;
  position: absolute;
  z-index: 10;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.card-store-map .img-src {
  height: 100%;
  object-fit: cover;
  width: 100%;
}
.card-store-map .contains-info {
  height: 100%;
  flex: 1;
}
.card-store-map .card-store-map-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  height: 100%;
  justify-content: space-between;
}
.card-store-map .card-store-map-info > div {
  width: 100%;
}

.card-store-map .card-store-map-info .top-part {
  row-gap: 6px;
}
.card-store-map .card-store-map-info .top-part .address,
.card-store-map .card-store-map-info .top-part label,
.card-store-map .card-store-map-info .assets,
.card-store-map .card-store-map-info .price-separator .price {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-store-map .card-store-map-info .top-part .address {
  font-size: 14px;
  line-height: 20px;
  font-family: "Inter";
  font-weight: 700;
  max-width: 95%;
  color: var(--primary-500);
  text-transform: uppercase;
}
.card-store-map .card-store-map-info .top-part label {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
  color: black;
}

.card-store-map .card-store-map-info .price-separator {
  gap: 8px;
}
.card-store-map .card-store-map-info .price-separator .price {
  color: var(--gray-600);
  font-weight: 500;
  font-size: 14px;
  font-family: "Inter";
  line-height: 20px;
}
.card-store-map .card-store-map-info .price-separator .separator {
  width: 1px;
  height: 12px;
  background-color: #e5e5e5;
}
.card-store-map .card-store-map-info .price-separator > div:last-child {
  gap: 4px;
}
.card-store-map .card-store-map-info .price-separator > div:last-child .rating,
.card-store-map .card-store-map-info .assets {
  font-size: 12px;
  line-height: 20px;
  font-family: "Inter";
  font-weight: 500;
}

.card-store-map .card-store-map-info .assets {
  font-weight: 400;
  color: var(--gray-600);
}

.card-store-map .contains-info {
  padding: 16px;
}
.card-store-map .btn-cross-card {
  padding: 4px;
  width: 36px;
  height: 36px;
  transform: translate(4px, -9px);
}
.card-store-map .btn-cross-card svg {
  width: 20px;
  height: 20px;
}

.card-store-map .swiper-img {
  height: 100%;
  width: 227px;
}
.card-store-map .link-residence {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  opacity: 1;
}

.card-store-map .swiper-pagination-bullet {
  background-color: white;
  border: 1px solid var(--gray-600);
}
.card-store-map .swiper-pagination-bullet-active {
  background-color: white;
}

.card-store-map .swiper-button-prev,
.card-store-map .swiper-button-next {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.09);
  z-index: 2;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 24 24'%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z'/%3E%3C/svg%3E");
  background-position: center;
  opacity: 0.4;
  transition: opacity 0.15s;
}
.card-store-map .swiper-button-next {
  left: auto;
  right: 8px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 24 24'%3E%3Cpath d='M8.59 16.59L10 18l6-6-6-6-1.41 1.41L13.17 12l-4.58 4.59z'/%3E%3C/svg%3E");
}
.card-store-map .swiper-img:hover .swiper-button-prev,
.card-store-map .swiper-img:hover .swiper-button-next {
  opacity: 0.9;
}
.card-store-map .swiper-button-prev:hover,
.card-store-map .swiper-button-next:hover {
  opacity: 1 !important;
}

.card-store-map .btn-fav {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: var(--shade-white);
}
.card-store-map .btn-fav svg {
  width: 100%;
  height: 100%;
}
.card-store-map .txt-reduc {
  text-wrap: nowrap;
  font-size: 13px;
  color: var(--destructive-500);
  /* Paragraph/Medium/semibold */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.card-store-map .txt-normal {
  text-wrap: nowrap;
  color: var(--gray-500);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
  text-decoration-line: strikethrough;
}
.card-store-map .discount-txt {
  color: var(--shade-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 800;
  line-height: normal; /* 133.333% */
  letter-spacing: -0.48px;
  padding: 6px 6px;
  background: var(--destructive-500);
  position: absolute;
  left: 16px;
  bottom: 22px;
  z-index: 2;
}
@media screen and (max-width: 550px) {
  .card-store-map .txt-reduc,
  .card-store-map .txt-normal {
    font-size: 12px;
  }
  .card-store-map {
    width: calc(100vw - 24px);
    bottom: 20px;
    height: 135px;
  }
  .card-store-map .swiper-img .swiper-button-prev,
  .card-store-map .swiper-img .swiper-button-next {
    display: none;
  }
  .card-store-map .swiper-img {
    height: 100%;
    width: 40%;
  }
  .card-store-map .contains-info {
    padding: 14px;
  }
}
@media screen and (max-width: 490px) {
  .card-store-map {
    height: 120px;
  }
  .card-store-map .contains-info {
    padding: 12px;
  }
}

@media screen and (max-width: 550px) {
  .card-store-map .card-store-map-info .top-part .address {
    font-size: 12px;
  }
  .card-store-map .card-store-map-info .top-part label {
    font-size: 14px;
    line-height: 20px;
  }

  .card-store-map .card-store-map-info .price-separator {
    gap: 4px;
  }
  @media screen and (max-width: 420px) {
    .card-store-map .card-store-map-info .price-separator.reduced .separator,
    .card-store-map .card-store-map-info .price-separator.reduced .rating,
    .card-store-map .card-store-map-info .price-separator.reduced svg {
      display: none;
    }
  }
  .card-store-map .card-store-map-info .price-separator .price {
    font-size: 12px;
  }
  .card-store-map .card-store-map-info .assets {
    font-size: 10px;
    line-height: 16px;
  }
  .card-store-map .card-store-map-info .top-part {
    row-gap: 1.5px;
  }
}

@media screen and (min-width: 300px) {
  @media screen and (max-height: 450px) {
    .card-store-map .price-separator {
      flex-wrap: wrap;
    }
    .card-store-map {
      height: 85px;
      max-width: 350px;
    }
    .card-store-map .contains-info {
      padding: 8px;
      row-gap: 4px;
    }
    .card-store-map .swiper-img {
      width: 40%;
    }
    .card-store-map .card-store-map-info .top-part {
      row-gap: 0px;
    }
    .card-store-map .card-store-map-info .top-part .address {
      display: none;
    }
  }
}
