.share-modal {
  width: 404px;
}

.share-modal .btns-action {
  width: 100%;
  flex-wrap: wrap;
}

.share-modal .btns-action button {
  border-radius: 8px;
  border: 1px solid var(--gray-200, #e5e7eb);
  width: calc(50% - 8px);
  padding: 14px 28px;
  overflow: hidden;
  justify-content: flex-start;
}

.share-modal .btns-action button img {
  width: 18px;
  height: 18px;
}

.share-modal .btns-action button span {
  color: var(--gray-700);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.share-modal .btns-action button:disabled {
  background: rgb(255, 255, 255, 0.4) !important;
}
.share-modal .btns-action button::before {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 0px;
  border-radius: 0;
  content: "";
  background: var(--primary-50);
  z-index: -1;
  transition: 0.2s;
  opacity: 0;
}
.share-modal .btns-action button:hover:before {
  width: 100%;
  opacity: 1;
}
.share-modal .btns-action button:disabled::before {
  animation: load-to-active 4s linear forwards;
}

@keyframes load-to-active {
  from {
    background: var(--gray-500);
    width: 100%;
    opacity: 1;
  }
  10% {
    background: var(--gray-500);
    width: 0px;
    opacity: 1;
  }
  95% {
    background: var(--gray-500);
    width: 100%;
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  to {
    background: var(--primary-50);
    opacity: 0;
  }
}

.share-modal .contents {
  row-gap: 24px;
}
.share-modal .residence-show {
  width: 100%;
}
.share-modal .residence-show img {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  object-fit: cover;
}
.share-modal .residence-show img {
  width: 64px;
  height: 64px;
  border-radius: 6px;
  object-fit: cover;
}
.share-modal .residence-show .infos > label {
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  color: var(--gray-500);
  text-align: left;
  -webkit-appearance: none;
  width: calc(404px - 64px - 32px);
  overflow: hidden; /* Ajout de la propriété overflow */
  text-overflow: ellipsis;
  white-space: nowrap;
}

.share-modal .residence-show .second-info .note {
  column-gap: 6px;
}
.share-modal .residence-show .second-info .note svg {
  width: 16px;
  height: 16px;
}
.share-modal .residence-show .second-info .note span {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.share-modal .residence-show .second-info > span {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  white-space: nowrap;
  max-width: calc(100%);
  text-overflow: ellipsis;
}
