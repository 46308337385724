.second-search-bar {
  margin: 0 180px;
  width: 945px;
  height: 100px;
  pointer-events: all;
  border-radius: 1000px;
  border: 1px solid var(--gray-200, #e5e7eb);
  background: var(--shade-white, #fff);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
}

.second-search-bar .go-search {
  margin-left: 20px;
  margin-right: 20px;
  padding: 14px;
}

.second-search-bar .go-search > svg {
  width: 20px;
  height: 20px;
}

.second-search-bar hr {
  border-width: 1px;
  width: 1px;
  height: 20px;
  opacity: 0.4;
  margin: 0;
  background: var(--gray-200);
  border: 1px solid var(--gray-200);
}

/* .datepicker {
  position: absolute;
  width: 100%;
  top: calc(100% + 10px);
  left: 0;
  height: 200px;
  background: var(--shade-white, #fff);
  border-radius: 10px;
} */
.first-shower-lbl-inp {
  display: block !important;
}
@media screen and (max-width: 1050px) {
  .second-search-bar {
    width: 100%;
  }
  .first-shower-lbl-inp {
    display: none !important;
  }
  .second-shower-lbl-inp {
    display: block !important;
  }
}
@media screen and (max-width: 980px) {
  .second-search-bar {
    width: 100%;
  }
}
@media screen and (max-width: 950px) {
  .second-search-bar {
    height: auto;
    margin: 0 auto;
    width: 700px;
  }
  .second-search-bar .hd-input-second {
    padding: 8px 6px;
    row-gap: 4px;
    margin: 1px;
  }
  .second-search-bar .hd-input-second-date {
    row-gap: 0;
    padding-left: 6px;
    padding-right: 6px;
  }
  .second-search-bar .date-part .lbl-inp-second {
    margin-bottom: 4px !important;
  }
  .second-search-bar .go-search {
    margin-left: 8px;
    margin-right: 8px;
    padding: 14px;
  }
  .second-search-bar .hd-input-second:nth-of-type(1),
  .second-search-bar .hd-input-second:nth-of-type(4) {
    flex: 1 !important;
  }
  .second-search-bar .hd-input-second:nth-of-type(4) {
    /* margin-left: 10px; */
    padding-left: 20px;
  }
  .second-search-bar .hd-input-second:nth-of-type(2),
  .second-search-bar .hd-input-second:nth-of-type(3) {
    flex: 0.6 !important;
    width: 127px;
  }
  .second-search-bar .hd-input-second:nth-of-type(1) {
    padding-left: 32px;
  }
  .second-search-bar .hd-input-second > .spanner-show {
    position: absolute;
    /* max-width: calc(100% - 12px); */
    bottom: 8px;
    left: 32px;
    overflow: hidden;
    width: 100%;
    text-overflow: ellipsis;
  }
  .second-search-bar .hd-input-second:nth-of-type(4) > .spanner-show {
    position: absolute;
    max-width: calc(100% - 12px);
    bottom: 8px;
    left: 20px;
  }
}
@media screen and (max-width: 750px) {
  .second-search-bar {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .second-search-bar .hd-input-second:nth-of-type(2),
  .second-search-bar .hd-input-second:nth-of-type(3) {
    border-radius: 8px;
  }
}
.second-search-bar .arrow-localisation {
  display: none;
}
.second-search-bar .contains-btn-filters.is-store .separator {
  display: none;
}
@media screen and (max-width: 550px) {
  .second-search-bar .arrow-localisation {
    display: block;
    padding: 8px;
  }
  .second-search-bar .arrow-localisation img {
    width: 16px;
    height: 16px;
  }
  .second-search-bar {
    padding-left: 32px;
  }
  .second-search-bar .go-search {
    padding: 8px;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
  .second-search-bar .contains-btn-filters.is-store .separator {
    display: block;
    width: 1.5px;
    height: 20px;
    background: var(--gray-200, #e5e7eb);
    border-radius: 100px;
  }
  .second-search-bar .contains-btn-filters.is-store {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 4px;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 100px;
    border: 1px solid var(--gray-200, #e5e7eb);
    background: var(--shade-white, #fff);
    padding: 0 10px;
    position: absolute;
    right: 0px;
  }
  .second-search-bar .contains-btn-filters.is-store .go-search {
    right: 0;
    transform: translate(0);
    top: 0;
    position: relative;
    margin: 0;
    border: none;
  }
  .second-search-bar .go-search svg {
    width: 16px;
    height: 16px;
  }
}

.second-search-bar .hd-input-second:nth-of-type(4) button > span {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* max-width: 148px; */
  /* margin-right: 0px; */
}

.second-search-bar .filter-extend-searching {
  position: absolute;
  transform-origin: right;
  top: calc(100% + 16px);
  padding: 40px 16px 24px 16px;
  border-radius: 16px;
  border: 1px solid var(--gray-200, #e5e7eb);
  background: var(--shade-white, #fff);
  right: 0;
  /* CARD BOX SHADOW */
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  width: 417px;
  z-index: 3;
}
.second-search-bar .background-touch-hide {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: none;
}
.second-search-bar .filter-selected-panel {
  row-gap: 24px;
}
.second-search-bar .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.second-search-bar .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.second-search-bar .filter-selected-panel .pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.second-search-bar .filter-selected-panel .reset-done {
  width: 100%;
  background: var(--shade-white);
  padding: 8px 24px 0px 24px;
  border-top: 1px solid var(--gray-200, #e5e7eb);
}

.second-search-bar .filter-selected-panel .reset-done .reset-btn {
  color: var(--destructive-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.second-search-bar .filter-selected-panel .reset-done .reset-btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  text-decoration-line: underline;
}
