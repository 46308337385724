.filters-panel {
  width: 100vw;
  height: 100dvh;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  animation: fadeIn 0.3s ease-in-out;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.filters-panel .background-touch {
  width: 100%;
  height: 100%;
  background: rgba(30, 26, 33, 0.5);
}

.filters-panel .contains {
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);

  flex-direction: column;
  align-items: center;
  position: absolute;
  row-gap: 24px;
  overflow: hidden;
  right: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  height: 722px;
  max-height: calc(100dvh - 96px);
  width: 841px;
  max-width: calc(100vw - 32px);
}
@media screen and (min-width: 1440px) {
  /* .filters-panel .contains {
    /* transform: translateX(38%); */
  /* transform: translateX(-50%); */
  /* width: 595px; */
  /* max-width: calc(100vw - 32px); */
  /* overflow: hidden; */
  /* } */
}
.filters-panel .contains .title {
  width: 100%;
  border-bottom: 1px solid var(--gray-200);
  background: var(--shade-white);
  padding: 16px 24px;
}
.filters-panel .contains .title .close-btn {
  border-radius: 100px;
  background: var(--primary-50);
  padding: 8px;
  position: absolute;
  left: 24px;
  top: 16px;
  width: 32px;
  height: 32px;
}
.filters-panel .contains .title label {
  color: var(--shade-black);
  font-family: "Mori Gothic";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.filters-panel .contains .reset-done {
  width: 100%;
  background: var(--shade-white);
  box-shadow: 0px -2px 4px 0px rgba(112, 112, 112, 0.02),
    0px -6px 6px 0px rgba(112, 112, 112, 0.02),
    0px -15px 9px 0px rgba(112, 112, 112, 0.01),
    0px -26px 10px 0px rgba(112, 112, 112, 0),
    0px -40px 11px 0px rgba(112, 112, 112, 0);
  padding: 8px 24px;
}

.filters-panel .contains .reset-done .reset-btn {
  color: var(--destructive-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.filters-panel .contains .all-filters {
  /* background: var(--primary-50); */
  width: 100%;
  padding: 8px 24px;
  flex: 1;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  row-gap: 24px;
  overflow-x: hidden;
}
.all-filters .filter-selected-panel .nbr-config .nbrs {
  /* background: var(--primary-200); */
  width: 50%;
  justify-content: space-between;
}
/* .all-filters .filter-selected-panel .nbr-config .nbrs button {
  padding: 8px;
}
.all-filters .filter-selected-panel .nbr-config .nbrs span {
  padding-top: 0;
  padding-bottom: 0;
  padding: 8px 16px;
} */
.all-filters .filter-selected-panel .select-acces-resi {
  width: 100%;
  align-items: stretch;
}
.all-filters .filter-selected-panel .select-acces-resi .one-select-resi {
  width: calc(50% - 8px);
  border-radius: 10px;
  border: 1px solid var(--primary-200, #dac7ff);
  padding: 16px;
}
.all-filters
  .filter-selected-panel
  .select-acces-resi
  .one-select-resi.selected {
  background: var(--primary-50);
}
.all-filters .filter-selected-panel .select-acces-resi .one-select-resi .left {
  flex: 1;
}
.all-filters .filter-selected-panel .select-acces-resi .one-select-resi svg {
  width: 24px;
  height: 24px;
}
.all-filters .filter-selected-panel .select-acces-resi .one-select-resi label {
  color: var(--shade-black, #1e1a21);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: left;
}
.all-filters .filter-selected-panel .select-acces-resi .one-select-resi p {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 24px;
}

.filter-selected-panel .residence-type {
  width: 100%;
}
.filter-selected-panel .residence-type button {
  width: calc(100% / 3 - 8px);
  padding: 16px;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid var(--shade-black);
  align-items: flex-start;
}
.filter-selected-panel .residence-type button span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.filter-selected-panel .residence-type .selected-type {
  background: var(--primary-50);
  border: 1px solid var(--primary-200, #dac7ff);
}
.filter-selected-panel .residence-type .selected-type span {
  /* color: var(--shade-white); */
  /* font-weight: 600; */
}
.filter-selected-panel .select-contains.case-selectors {
  flex-wrap: wrap;
  width: 100%;
}
.filter-selected-panel strong {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 15px;
  line-height: 24px;
}
.filter-selected-panel .case-selectors {
  width: 100%;
}
.filter-selected-panel .case-selectors .open-select {
  justify-content: space-between;
  column-gap: 20px;
  width: 50%;
  align-items: center;
  margin-top: 16px;
}
.filter-selected-panel .case-selectors .open-select:first-child {
  margin-top: 0;
}
.filter-selected-panel .case-selectors .open-select svg {
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
  transition: 0.15s;
}
.filter-selected-panel .case-selectors .open-select.opened svg {
  transform: rotate(0deg);
}
.filter-selected-panel .case-selectors button span {
  color: var(--gray-600, #4b5563);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.filter-selected-panel .select-contains .case-selector {
  width: calc(100% / 3 - 40px / 3);
}

.filter-selected-panel.commodities-select {
  row-gap: 24px;
}
.filter-selected-panel .case-selectors .opened-activitie {
  transition: 0.15s;
  height: 0;
  opacity: 0;
  overflow: hidden;
}
.filter-selected-panel .case-selectors .opened-activitie.opened {
  height: auto;
  opacity: 1;
  margin-top: 16px;
}
@media screen and (max-width: 1050px) {
  .filters-panel .contains {
    right: 32px;
  }
}
@media screen and (max-width: 850px) {
  .filter-selected-panel .case-selectors .open-select {
    width: 80%;
  }
}

@media screen and (max-width: 550px) {
  .filters-panel .contains {
    right: 16px;
  }

  .filters-panel .contains {
    right: 0px;
    width: calc(100% - 32px);
    max-width: calc(100%);
    margin: 0px;
  }
  .filters-panel .contains .title label {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .filter-selected-panel .residence-type button span {
    font-size: 14px;
    font-weight: 500;
  }
  .filter-selected-panel .residence-type button {
    padding: 16px 8px;
  }
  .filters-panel .contains .all-filters {
    padding: 8px 16px;
  }
  .filter-selected-panel .residence-type button {
    border-radius: 10px;
    border: 1px solid var(--primary-200, #dac7ff);
    width: calc(100% / 3 - 4px);
  }
  .filter-selected-panel .residence-type button span {
    max-width: 100%;
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    text-align: left;
    white-space: nowrap;
    max-width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .filters-panel .contains .reset-done .reset-btn {
    font-size: 12px;
    line-height: 20px; /* 166.667% */
    text-decoration-line: underline;
  }
  .filters-panel .contains .reset-done .done-btn {
    font-size: 12px;
    line-height: 20px;
  }
}

@media screen and (max-width: 500px) {
  .all-filters .filter-selected-panel .select-acces-resi {
    flex-direction: column;
    row-gap: 16px;
  }
  .all-filters .filter-selected-panel .select-acces-resi button {
    width: 100% !important;
  }
}
@media screen and (max-width: 450px) {
  .filter-selected-panel .case-selectors button {
    /* width: calc(50% - 10px); */
  }
}
@media screen and (max-height: 810px) {
  .filters-panel .contains {
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-height: 670px) {
  @media screen and (max-width: 550px) {
    .filters-panel .contains {
      width: 100%;
      height: 100dvh;
      right: 0;
      margin: 0;
      top: 0;
      border-radius: 0;
      transform: translate(-50%, 0);
    }
  }
}
@media screen and (max-width: 690px) {
  .all-filters
    .filter-selected-panel
    .select-acces-resi
    .one-select-resi
    label {
    font-size: 16px;
    line-height: 24px;
  }
  .all-filters .filter-selected-panel .select-acces-resi .one-select-resi p {
    font-size: 14px;
    line-height: 22px;
  }
  .filter-selected-panel .select-contains .case-selector {
    width: calc(100% / 2 - 20px / 2);
}
}
@media screen and (max-width: 390px) {
  .filter-selected-panel .case-selectors .open-select {
    width: 100%;
  }
  .filters-panel .contains {
    width: 100%;
    height: 100dvh;
    right: auto;
    margin: 0;
    top: 0;
    left: 0;
    transform: translate(0);
    max-height: 100dvh !important;
    border-radius: 0;
  }
}
