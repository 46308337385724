.contains-hero-residence {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 28.906%;
  grid-template-rows: calc(50% - 7px) calc(50% - 7px);
  gap: 14px 14px;
  height: 480px;
  grid-template-areas:
    "img0 img1"
    "img0 img2";
}
@media screen and (max-width: 610px) {
  .contains-hero-residence {
   height: auto;
   max-height: 410px;
  }
}

.contains-hero-residence img {
  cursor: pointer;
}
.contains-hero-residence img,
.contains-hero-residence .skeleton-img {
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.contains-hero-residence .img0 {
  grid-area: img0;
  /* aspect-ratio: 894 / 500; */
  height: 100%;
}
.contains-hero-residence .img-skel {
  aspect-ratio: auto;
}
.contains-hero-residence .img1 {
  grid-area: img1;
}
.contains-hero-residence .img2 {
  grid-area: img2;
}

.not-loaded {
  opacity: 0;
}

.loaded-img {
  opacity: 1;
}

.contains-hero-residence .open-galerie-btn {
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 100px;
  background: rgba(30, 26, 33, 0.7);
}

.contains-hero-residence .open-galerie-btn span {
  color: var(--shade-white);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.title-residence-share {
  padding: 12px 0;
  border-bottom: 1px solid var(--gray-200);
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
}

.title-residence-share.fixed-to-header {
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s;
  position: fixed;
  top: 70px;
  max-width: 1440px;
  width: 100%;
  padding: 24px 80px;
  padding-bottom: 8px;
  margin: 0 auto;
  background: rgb(255, 255, 255, 0.6);
  backdrop-filter: blur(4px);
  z-index: 4;
  animation: show-me-just 0.2s forwards;
  box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.2),
    0px 18px 18px 0px rgba(255, 255, 255, 0.17),
    0px 41px 25px 0px rgba(255, 255, 255, 0.1),
    0px 74px 29px 0px rgba(255, 255, 255, 0.03),
    0px 115px 32px 0px rgba(255, 255, 255, 0);
}
@media screen and (max-height: 550px) {
  .title-residence-share.fixed-to-header {
    opacity: 0 !important;
    user-select: none;
    pointer-events: none;
  }
}
@media screen and (min-width: 1441px) {
  .title-residence-share.fixed-to-header {
    /* left: ; */
    border: none;
  }
}

@keyframes show-me-just {
  from {
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  to {
    left: 50%;
    transform: translateX(-50%);
    opacity: 1;
  }
}

.title-residence-share .resi-info {
  flex: 1;
}
.title-residence-share .in-load {
  display: inline-flex;
  flex-direction: column;
  row-gap: 16px;
}

.title-residence-share .resi-info h2 {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.title-residence-share .resi-info .stars-location {
  column-gap: 8px;
}
.title-residence-share .resi-info .stars-location .star-resi {
  column-gap: 6px;
}

.title-residence-share .resi-info .stars-location .star-resi span,
.title-residence-share .resi-info .stars-location h3 {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.title-residence-share .resi-info .stars-location hr {
  width: 2px;
  height: 16px;
  background: var(--gray-500);
  opacity: 0.6;
  margin: 0;
  border: none;
}

.title-residence-share .actions-btn button {
  border-radius: 100px;
  background: var(--primary-50);
}

@media screen and (max-width: 1050px) {
  .title-residence-share.fixed-to-header {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media screen and (max-width: 610px) {
  .title-residence-share.fixed-to-header {
    padding-left: 16px;
    padding-right: 16px;
  }
}
