.step-10 .right-part-add-resi .contains-input div {
  width: 100%;
}
.step-10 .right-part-add-resi .contains-input input {
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--gray-400);
  background: var(--shade-white);
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding: 20px;
}
.step-10 .right-part-add-resi .contains-input input.disabled-input {
  background: var(--gray-200);
  border-color: var(--gray-200) !important;
  /* color: var(--gray-400); */
}
.step-10 .right-part-add-resi .contains-input input:focus {
  border: 1px solid var(--primary-200);
}
.step-10 .right-part-add-resi .contains-input span {
  padding-left: 16px;
  width: 100%;
  text-align: left;
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.step-1-annonce.step-10.step-4-1
  .paiement-recap
  span.input-select-perso
  .select-visible {
  padding-right: 0 !important;
}
@media screen and (max-width: 950px) {
  .step-1-annonce.step-10.step-4-1.paiement-recap
    span.input-select-perso
    .select-visible {
    max-height: 210px !important;
  }
  .step-1-annonce.step-10.step-4-1 .left-part-add-resi .content p {
    margin-bottom: 20px !important;
  }
}
/* 
.step-1-annonce.step-10.step-4-1 .add-resi-select-country .preview span{
  font-size: 24px !important;
}
.step-1-annonce.step-10.step-4-1 .add-resi-select-country .preview img{
  width: 32px !important;
  height: 32px !important;
}
.step-1-annonce.step-10.step-4-1 .paiement-recap span.input-select-perso .select-visible {
  height: 200px !important;
  padding-right: 0 !important;
}
.step-4-1 .add-resi-select-country {
  border-radius: 16px !important;
  border: 1px solid var(--gray-400) !important;
  background: var(--shade-white) !important;
  color: var(--shade-black);
  font-family: Mori Gothic !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  padding: 20px;
} */
