.destination-input-m {
  border-radius: 16px;
  border: 1px solid var(--gray-100, #f3f4f6);
  background: var(--shade-white, #fff);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  padding: 8px 16px;
}

.destination-input-m > label {
    margin-bottom: 16px;
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}

.destination-input-m .input-contain {
  width: 100%;
  height: auto;
  margin-bottom: 16px;
}
.destination-input-m .input-contain svg {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 8px;
}
.destination-input-m .input-contain input {
  width: 100%;
  padding: 16px;
  color: var(--shade-black);
  font-style: normal;
  letter-spacing: -0.4px;
  outline: none;
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  padding-left: 48px;

  font-weight: 500;
  font-family: Inter;
  font-size: 14px;
  line-height: 20px;
}
.destination-input-m .input-contain input::placeholder {
  color: var(--gray-400);
  font-weight: 400;
}
.destination-input-m .input-contain input:focus-within {
  border: 1px solid var(--primary-500);
}

.destination-input-m .input-contain .searchbar-destination{
    z-index: 3;
}
.destination-input-m .transition-height {
  transition: height 0.3s ease-in-out;
}