.btn-signal {
  padding: 18px 0px;
  border-top: 1px solid var(--gray-200);
  border-radius: 0;

  display: none;
  justify-content: space-between;
  margin-bottom: 130px;
}
.btn-signal .left-side {
  column-gap: 8px;
}

.btn-signal .left-side span {
  color: var(--destructive-500);
}
.signalement-modal-second .signalement-modal .title-modal label {
  overflow: hidden;
  color: var(--shade-black);
  text-overflow: ellipsis;
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
}
@media screen and (max-width: 550px) {
  .btn-signal {
    display: flex;
  }
}

.mobile-reservation-panel {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 16px 24px;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  background: var(--shade-white);
  box-shadow: 0px -2px 4px 0px rgba(112, 112, 112, 0.02),
    0px -6px 6px 0px rgba(112, 112, 112, 0.02),
    0px -15px 9px 0px rgba(112, 112, 112, 0.01),
    0px -26px 10px 0px rgba(112, 112, 112, 0),
    0px -40px 11px 0px rgba(112, 112, 112, 0);
  z-index: 2;
}

.reservation-btn-bot .price-show > span:first-child {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.reservation-btn-bot .price-show > span:last-child {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.mobile-reservation-panel .date-one > label {
  color: var(--gray-700);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.48px;
}
.mobile-reservation-panel .date-one > span {
  white-space: nowrap;
  overflow: hidden;
  color: var(--gray-700);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
