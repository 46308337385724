.btn-select-defaut {
  padding: 10px 8px;
  border-radius: 6px;
  column-gap: 10px;
  justify-content: flex-start;
  width: 100%;
}

.btn-select-defaut > img {
  width: 34px;
  height: 24px;
  object-fit: contain;
}
.btn-select-defaut .details {
  row-gap: 5px;
}
.btn-select-defaut div h3 {
  font-size: 12px;
  line-height: normal;
  text-align: left;
  font-weight: 400;
  /* font-family: Mori Gothic; */
}

@media screen and (max-width: 400px) {
  .modif-versement-modal {
    width: 100% !important;
  }
}

.btn-select-defaut div p {
  font-size: 16px;
  line-height: normal;
  text-align: left;
  font-weight: 600;
}

.btn-select-defaut > span {
  border-radius: 7px;
  padding: 5px;
  border: 0.5px solid var(--gray-300);
  margin-left: auto;
  font-weight: 500;
  font-size: 12px;
}

.btn-select-defaut:hover {
  background-color: var(--gray-50);
  padding: 12px 8px;
}
.btn-select-defaut.active {
  background-color: var(--primary-50);
  border: 1px solid var(--primary-100);
  opacity: 0.8;
}
.btn-select-defaut :is(p, h3, span) {
  color: black;
}
.btn-select-defaut.active :is(p, h3, span) {
  color: var(--shade-black);
}

.btn-select-defaut.btn-action:last-child {
  background-color: var(--gray-100);
  /* padding: 10px 8px !important; */
  height: 59px;
  text-align: center;
  column-gap: 4px;
  justify-content: center;
  font-size: 16px;
}
.btn-select-defaut.btn-action:last-child :is(span, strong) {
  text-align: center;
  font-size: 16px;
}
.input-div-versement {
  row-gap: 4px;
}
.input-div-versement > span {
  font-size: 13px;
  font-weight: 500;
  color: var(--gray-500);
  display: block;
}

.input-div-versement input {
  padding: 10px 8px;
  border-radius: 6px;
  border: 1px solid var(--gray-300);
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-500);
}
.input-div-versement select {
  padding: 10px 8px;
  /* padding-left: 4px; */
  padding-right: 12px;
  border-radius: 6px;
  border: 1px solid var(--gray-300);
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-500);
}
/* retirer le outline bleu sur le select */
.input-div-versement select:focus {
  outline: none;
}
/* pousser un peu plus vers la gauche le chevron */
.input-div-versement select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23A0AEC0' viewBox='0 0 24 24'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px top 50%;
}
/* styliser les options du select */
.input-div-versement select option {
  padding: 10px 8px;
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-500);
}
.input-div-versement .indicatif-inp {
  position: relative;
}
/* mettre un + derriere l'input */
.input-div-versement .input-field-indicatif::after {
  content: "+";
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-400);
  transition: color 0.15s;
}
.input-div-versement .input-field-indicatif.contain::after {
  color: var(--gray-500);
}
.input-div-versement .input-field-indicatif > img {
  width: 34px;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.input-div-versement input::placeholder {
  color: var(--gray-400);
}

/* filter style */
.input-div-versement .filter-indicatif {
  position: absolute;
  bottom: calc(100% + 8px);
  max-height: 165px;
  height: auto;
  left: 0px;
  width: 300px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  animation: show-filter-sl 0.3s ease-in-out;
}

@keyframes show-filter-sl {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.input-div-versement .filter-indicatif button {
  padding: 6px 4px;
  width: 100%;
  border-radius: 0;
  justify-content: flex-start;
}
@media screen and (max-width: 365px) {
  .input-div-versement .filter-indicatif {
    width: calc(100vw - 66px);
  }
}
.input-div-versement .filter-indicatif button:hover {
  background-color: var(--gray-50);
  padding: 7.5px 4px;
}
.input-div-versement .filter-indicatif button img {
  width: 34px;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.input-div-versement .filter-indicatif button .dial-code-countrie {
  font-size: 14px;
  font-weight: 800;
  color: var(--gray-500);
}
.input-div-versement .filter-indicatif button .countrie-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 500;
  color: var(--gray-500);
}

.temporary-versement-btn {
  margin-top: 6px;
  padding: 10px 8px;
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  width: 100%;
  font-size: 16px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: var(--gray-50);
}

.temporary-versement-btn span {
  font-size: 13px;
  font-weight: 500;
  color: var(--gray-500);
}

.temporary-versement-btn .case {
  /* faire un carré */
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: 1px solid var(--gray-300);
  background: var(--shade-white);
  position: relative;
}

.temporary-versement-btn .case.active {
  /* background: var(--primary-50); */
  /* border: 1px solid var(--shade-white); */
}
/* mettre un signe yes dans la case en after */
.temporary-versement-btn .case.active::after {
  content: "✓";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 800;
  color: var(--shade-black);
}

.temporary-versement-btn.active {
  background: var(--warning-100);
  border: 1px solid var(--warning-200);
}
.temporary-versement-btn.active .case {
  background: var(--shade-white);
  border: 1px solid var(--gray-300);
}
/* .temporary-versement-btn.active span {
   color: var(--shade-black); 
} */

.btn-open-versement {
  padding: 10px 8px;
  border-radius: 6px;
  border: 1px solid var(--gray-300);
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-500);
  justify-content: flex-start;
}
.btn-open-versement:disabled {
  background: none !important;
  opacity: 1 !important;
}

.btn-open-versement img {
  aspect-ratio: 3/2;
  object-fit: contain;
  width: 34px;
}
.btn-open-versement span {
  font-size: 16px;
  font-weight: 500;
  color: var(--gray-500);
  display: block;
}

.select-option-versement {
  position: absolute;
  width: calc(100%);
  /* bottom: calc(100% + 8px); */
  max-height: 154px;
  bottom: 100%;
  height: auto;
  left: 0px;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  animation: show-me-slow-vers 0.15s ease-out forwards;
}
.select-option-versement::-webkit-scrollbar,
.input-div-versement .filter-indicatif::-webkit-scrollbar {
  width: 4px !important;
}

.select-option-versement::-webkit-scrollbar-thumb,
.input-div-versement .filter-indicatif::-webkit-scrollbar-thumb {
  background-color: var(--primary-500) !important;
  border-radius: 12px !important;
}

.select-option-versement::-webkit-scrollbar-track,
.input-div-versement .filter-indicatif::-webkit-scrollbar-track {
  background-color: var(--primary-100) !important;
  border-radius: 12px !important;
}
@keyframes show-me-slow-vers {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(15px);
  }
}
.select-option-versement .opt-option-vers {
  justify-content: flex-start;
  border-radius: 0;
  padding: 8px 6px;
  border-bottom: 1px solid var(--gray-200);
}

.select-option-versement .opt-option-vers:hover {
  background-color: var(--gray-50);
  padding: 9px 6px;
}
