.tile-residence {
  column-gap: 8px;
  width: 100%;
}

.tile-residence.pader {
  padding: 16px 0;
  border-bottom: 1px solid var(--gray-200);
}

.tile-residence > img {
  width: 139px;
  height: auto;
  border-radius: 6px;
  object-fit: cover;
  aspect-ratio: 139/93;
}

.tile-residence > .right-side {
  flex: 1;
}
.tile-residence > .right-side > label {
  width: 100%;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.tile-residence > .right-side .loc-star span,
.tile-residence > .right-side .loc-star .star > span {
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  color: var(--gray-600);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.tile-residence > .right-side .loc-star .star {
  column-gap: 4px;
}
.tile-residence > .right-side .loc-star .star > svg {
  width: 14px;
  height: 14px;
}
