.contains-input-section textarea {
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--gray-400);
  background: var(--shade-white);
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  padding: 8px;
  height: 130px;
  resize: none;
}

.contains-input-section textarea:focus {
  border: 1px solid var(--primary-200);
  outline-color: var(--primary-300);
}
.contains-input-section span {
  padding-left: 16px;
  width: 100%;
  text-align: right;
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.contains-input-section {
  padding: 12px 16px;
  width: 100%;
  background: var(--shade-white);
  border-radius: 16px;
  box-shadow: 8px 4px 4px 8px rgba(0, 0, 0, 0.01),
    -1px -0.5px 4px 8px rgba(0, 0, 0, 0.05);
}
.contains-input-section label {
  font-size: 14px !important;
}
.contains-input-section input {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  border-radius: 6px;
  padding: 8px;
  border: 1px solid var(--gray-400);
}
.contains-input-section input::placeholder {
  font-weight: 500;
}
.contains-input-section input:focus {
  border: 1px solid var(--primary-200);
  outline-color: var(--primary-300);
}
.contains-input-section .input-select-contains:nth-of-type(2) {
  margin-bottom: 10px;
}

.step-11 .right-part-add-resi .contains-input {
  margin-top: 95px;
  padding-top: 10px;
  padding-right: 10px;
  overflow-y: scroll;
  max-height: calc(100vh - 195px);
}

@media screen and (max-width: 950px) {
  .step-11 .right-part-add-resi .contains-input {
    margin-top: 0;
    max-height: auto;
    overflow-y: visible;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 10px;
  }
}
@media screen and (max-width: 650px) {
  .contains-input-section textarea {
    height: 90px !important;
  }
}
