.residence-store-list {
  margin-top: 48px;
  min-height: calc(80vh - 300px);
  padding-bottom: 20px;
  max-height: calc(100% - 60px);
  /* height: calc(100dvh - 243px);
  overflow-y: scroll; */
}

.residence-store-list .content-residence {
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 32px;
}
.residence-store-list .content-residence > .card-resi-content,
.residence-store-list .content-residence > .card-resi {
  width: calc(100% / 4 - 60px / 4);
  height: auto;
}
.residence-store-list .content-residence > .card-resi-content .top-card,
.residence-store-list .content-residence > .card-resi .top-card {
  width: 100%;
  height: 290px;
  max-height: 270px;
}
.residence-store-list .content-residence > .card-resi-content .bot-val,
.residence-store-list .content-residence > .card-resi .bot-val {
  padding: 16px 16px 24px 16px;
  height: 112px;
}
.residence-store-list .content-residence > .card-resi-content .bot-val > h3,
.residence-store-list .content-residence > .card-resi .bot-val > h3 {
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.residence-store-list .content-residence > .card-resi-content .bot-val > label,
.residence-store-list .content-residence > .card-resi .bot-val > label {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
}

.residence-store-list
  .content-residence
  > .card-resi-content
  .bot-val
  .price-note
  > span,
.residence-store-list
  .content-residence
  > .card-resi
  .bot-val
  .price-note
  > span {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.residence-store-list
  .content-residence
  .card-resi-content
  .bot-val
  > .price-note
  .star-resi
  > span,
.residence-store-list
  .content-residence
  .card-resi
  .bot-val
  > .price-note
  .star-resi
  > span {
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.residence-store-list .no-residence {
  row-gap: 24px;
  max-width: 519px;
}
.residence-store-list .no-residence p {
  padding-top: 100px;
  /* font-size: px; */
}
.residence-store-list .no-residence span {
}

@media screen and (max-width: 1200px) {
  .residence-store-list .content-residence > .card-resi-content,
  .residence-store-list .content-residence > .card-resi {
    width: calc(100% / 3 - 40px / 3);
  }
}
@media screen and (max-width: 834px) {
  .residence-store-list .content-residence > .card-resi-content,
  .residence-store-list .content-residence > .card-resi {
    width: calc(100% / 2 - 16px / 2);
  }
  .residence-store-list .content-residence {
    column-gap: 16px;
    row-gap: 16px;
  }
}
@media screen and (max-width: 550px) {
  .residence-store-list .no-residence p {
    padding-top: 10px;
  }
  .residence-store-list {
    margin-top: 15px;
  }
}
@media screen and (max-width: 465px) {
  .residence-store-list .content-residence > .card-resi-content .top-card,
  .residence-store-list .content-residence > .card-resi .top-card {
    width: 100%;
    height: auto;
    aspect-ratio: 305/200;
  }
  .residence-store-list .content-residence > .card-resi-content,
  .residence-store-list .content-residence > .card-resi {
    width: 100%;
  }
  .residence-store-list .content-residence {
    row-gap: 20px;
  }
}

@media screen and (min-width: 1440px) {
  .residence-store-list .content-residence > .card-resi-content,
  .residence-store-list .content-residence > .card-resi {
    /* width: calc(100% / 5 - 80px / 5); */
  }
}
@media screen and (min-width: 1800px) {
  .residence-store-list .content-residence > .card-resi-content,
  .residence-store-list .content-residence > .card-resi {
    width: calc(100% / 6 - 100px / 6);
  }
}
