.store-map .btn-open-map,
.store-map .store-map-container .close-listing {
  background-color: #23272e;
  padding: 12px 24px;
  border-radius: 100px;
  align-items: center;
  column-gap: 8px;
  position: fixed;
  left: 50%;
  bottom: 60px;
  transform: translateX(-50%);
  width: fit-content;
  z-index: 3;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 10px 10px rgba(0, 0, 0, 0.09);
}

.store-map .btn-open-map.open {
  transition: background-color 0.3s;
}
.store-map .btn-open-map.open:hover,
.store-map .close-listing:hover {
  opacity: 1;
  background-color: var(--gray-600);
}
.store-map .close-listing:hover,
.store-map .map-controls button:hover {
  background-color: rgb(251, 248, 255) !important;
  transform: scale(1.06) !important;
  opacity: 1;
}

.store-map .btn-open-map span,
.store-map .store-map-container .close-listing span {
  font-family: "Inter";
  font-weight: bold;
  line-height: 24px;
  color: white;
  font-size: 16px;
}

.store-map .container-map {
  position: relative;
  width: 100%;
  height: 100%;
}

.store-map .btn-open-map span img {
  width: 16px;
  height: 16px;
}
.store-map .store-map-container {
  position: fixed;
  top: 158px;
  width: 100vw;
  height: calc(100dvh - 158px);
  z-index: 2;
}
@media screen and (max-width: 550px) {
  .store-map .btn-open-map {
    bottom: 32px;
    padding: 10px 20px;
  }
  .store-map .btn-open-map span {
    font-size: 14px;
    line-height: 20px;
  }
  .store-map .btn-open-map span img {
    width: 14px;
    height: 14px;
  }
  .store-map .store-map-container {
    top: 155px;
    height: calc(100dvh - 155px);
  }
}
.store-map .store-map-container .close-listing,
.store-map .map-controls button {
  top: 32px;
  left: 32px;
  position: absolute;
  background: white;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
  height: fit-content;
  column-gap: 0;
  padding: 8px 16px;
  transform: translateX(0);
}
.store-map .store-map-container .close-listing img {
  margin-right: 2px;
}
.store-map .store-map-container .close-listing img,
.store-map .map-controls button img {
  width: 24px;
  height: 24px;
}
.store-map .store-map-container .close-listing img {
  width: 20px;
  height: 20px;
}
.store-map .store-map-container .close-listing span {
  color: black;
  font-size: 12px;
  line-height: 20px;
}
.store-map .marker-map {
  background-color: #a273ff;
  border-radius: 12px;
  position: relative;
  padding: 10px;
  border: 1px solid #dac7ff;
  /* transition:  background et scale; */
  transition: background-color 0.2s, transform 0.2s;
}
.store-map .marker-map:hover {
  transform: scale(1.2) !important;
}

.store-map .marker-map::before {
  content: "";
  position: absolute;
  bottom: -12px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid var(--primary-200);
  transition: border-top-color 0.2s;
  z-index: 10;
}
.store-map .marker-map::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid var(--primary-500);
  transition: border-top-color 0.2s;
  z-index: 10;
}
.store-map .marker-map:hover:before,
.store-map .marker-map:hover::after {
  z-index: 10;
  transform: translateX(-50%), scale(1.2);
}

.store-map .marker-map.selected {
  background-color: var(--primary-900);
}
/* .marker-map::before, */
.store-map .marker-map.selected::after {
  border-top-color: var(--primary-900);
}
.store-map .marker-map span {
  color: white;
  font-weight: bold;
  font-family: "Inter";
  font-size: 12px;
}
@media screen and (max-width: 550px) {
  .store-map .marker-map {
    padding: 6px 8px;
    border-radius: 12px;
  }
  .store-map .marker-map span {
    font-size: 10.5px;
    line-height: 17px;
  }
  @media screen and (max-width: 450px) {
    .store-map .marker-map span {
      font-size: 10px;
      line-height: 16px;
    }
  }
}

.store-map .map-controls {
  position: absolute;
  top: 32px;
  right: 32px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  gap: 10px;
  justify-content: center;
}
.store-map .map-controls button {
  position: static;
  left: 0;
  top: 0;
  border-radius: 50%;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.store-map .marker-pos {
  background-color: var(--gray-900);
  border-radius: 50%;
  width: 17px;
  height: 17px;
  position: relative;
  transform: translate(-50%, -50%);
  z-index: 1;
}
/* animation de sonar du after de marker-pos avec un primary-100 */
.store-map .marker-pos::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: var(--gray-700);
  animation: sonar 1.5s infinite ease-out;
}
/* au dessus du marker pos en after */
.store-map .marker-pos::after {
  content: "Ma position";
  position: absolute;
  top: -10px;
  left: 50%;
  white-space: nowrap;
  transform: translateX(-50%);
  font-size: 7px;
  color: var(--gray-900);
  font-weight: bold;
}
@keyframes sonar {
  0% {
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    width: 55px;
    height: 55px;
    opacity: 0;
  }
}
.store-map .loading-map {
  background-color: white;
  border-radius: 12px;
  padding: 8px;
  height: 60px;
  transform-origin: center;
  position: absolute;
  top: 32px;
  left: 50%;
  transform: translateX(-50%) scale(0.75);
  z-index: 10;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.15);
}
@media screen and (max-width: 550px) {
  .store-map .loading-map {
    top: 64px;
    transform: translateX(-50%) scale(0.5);
  }
}
@media screen and (min-width: 300px) {
  @media screen and (max-height: 450px) {
    .store-map .loading-map {
      top: 20px;
      transform: translateX(-50%) scale(0.4);
    }
  }
}
.lds-ellipsis {
  /* color: var(--gray-600); */
}
.lds-ellipsis span {
  text-align: center;
  font-size: 12px;
  color: var(--gray-600);
  font-weight: bold;
}
.lds-ellipsis,
.lds-ellipsis div {
  box-sizing: border-box;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 100%;
  transform: translateY(-15px);
}
.lds-ellipsis div {
  position: absolute;
  top: 33.33333px;
  width: 13.33333px;
  height: 13.33333px;
  border-radius: 50%;

  background: var(--gray-600);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(0.65);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(0.65);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media screen and (max-width: 950px) {
  .store-map .store-map-container {
    top: 250px;
    height: calc(100dvh - 250px);
  }
}
@media screen and (max-width: 550px) {
  .store-map .store-map-container {
    top: 155px;
    height: calc(100dvh - 155px);
  }
  .store-map .store-map-container .close-listing {
    left: 16px;
  }
  .store-map .store-map-container .close-listing img {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
  .store-map .map-controls {
    right: 16px;
  }
}
