.when-travel {
  row-gap: 16px;
  width: 100%;
  padding: 24px 16px;
  border-radius: 16px;
  border: 1px solid var(--gray-100);
  background: var(--shade-white);

  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
}

.when-travel .react-datepicker__day--selected,
.when-travel .react-datepicker__day--in-selecting-range,
.when-travel .react-datepicker__day--in-range,
.when-travel .react-datepicker__month-text--selected,
.when-travel .react-datepicker__month-text--in-selecting-range,
.when-travel .react-datepicker__month-text--in-range,
.when-travel .react-datepicker__quarter-text--selected,
.when-travel .react-datepicker__quarter-text--in-selecting-range,
.when-travel .react-datepicker__quarter-text--in-range,
.when-travel .react-datepicker__year-text--selected,
.when-travel .react-datepicker__year-text--in-selecting-range,
.when-travel .react-datepicker__year-text--in-range {
  color: var(--shade-white, #fff) !important;
  border-radius: 50% !important;
  background: var(--primary-500) !important;
}
.when-travel .no-violet-survol {
  background: none !important;
  color: var(--shade-black) !important;
}
.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}
.when-travel > label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.when-travel .react-datepicker {
  box-shadow: none !important;
  border: none;
  padding: 0;
  width: 100%;
}
.when-travel .react-datepicker__month-container {
  width: 100%;
}
.when-travel .react-datepicker__navigation--next,
.when-travel .react-datepicker__navigation--previous {
  top: 8px;
  left: auto;
  right: 0;
}
.when-travel .react-datepicker__navigation--previous {
  right: 48px;
}
.when-travel
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__current-month {
  text-align: left;
  padding-left: 8px;
}

.when-travel .react-datepicker .day-date-picker,
.when-travel .react-datepicker .react-datepicker__day-name {
  padding: 6px;
  width: 33px;
  height: 33px;
  font-size: 16px;
  width: 36px;
  height: 36px;
  font-weight: 400;
  text-align: center;
}
.when-travel .react-datepicker .day-date-picker {
  font-weight: 600;
}
.when-travel .react-datepicker__day-names,
.when-travel .react-datepicker__week {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.when-travel .react-datepicker__month {
  margin: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.when-travel .react-datepicker .day-date-picker,
.when-travel .react-datepicker .react-datepicker__day-name {
  background: none;
}

.when-travel
  .period-selector-content
  .react-datepicker
  .react-datepicker__day-name {
  margin: 2px;
}

@media screen and (min-width: 450px) {
  .when-travel .react-datepicker .day-date-picker,
  .when-travel .react-datepicker .react-datepicker__day-name {
    padding: 6px;
    font-size: 18px;
    width: 45px;
    height: 45px;
    padding-top: 11px;
    font-weight: 600;
    text-align: center;
  }
}
