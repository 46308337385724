.main-residence-section article,
.main-residence-section .waiting-load {
  position: absolute;
  right: 0;
  top: 40px;
  width: calc(100% - 66.171% - 16px);
  height: auto;
  row-gap: 16px;
}
.main-residence-section .waiting-load {
  right: 0 !important;
  top: 40px !important;
  width: calc(100% - 66.171% - 16px) !important;
  height: auto !important;
  row-gap: 16px !important;
  border-bottom: 0 !important;
}
.main-residence-section .waiting-load .faker-contains-load {
  width: 100%;
  height: 539px;
  border-radius: 16px;
  border: 1px solid hsl(0, 0%, 90%);
  right: 0;
  top: 0;
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
}

.main-residence-section article .signal-resi {
  padding: 16px 34px;
}
.main-residence-section article .signal-resi span {
  color: var(--destructive-500);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.main-residence-section article .contains-reservation {
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: var(--primary-50);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  padding: 16px 24px 32px 24px;
  height: fit-content;
  /* height: auto; */
  row-gap: 8px;
}
/*RESERVATION CONTAINS*/
article .contains-reservation .price-show {
  column-gap: 6px;
  padding: 12px 0;
  border-bottom: 1px solid var(--gray-200, #e5e7eb);
  align-items: flex-start;
  flex-wrap: wrap;
}
article .contains-reservation .price-show.reduced {
  padding-top: 0.5px;
  padding-bottom: 3.5px;
}

article .contains-reservation .price-show .big-price {
  color: var(--shade-black) !important;
  font-family: Mori Gothic !important;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 28px !important;
  letter-spacing: -0.56px !important;
}
article .contains-reservation .price-show span {
  white-space: nowrap;
}
article .contains-reservation .price-show .price-part {
  flex: 1;
  align-items: flex-end;
  gap: 6px;
}
article .contains-reservation .price-show .big-price.barre-price {
  color: var(--gray-500) !important;
  font-family: Inter;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 24px;
  /* ofset line trought to -2px */
  text-decoration-thickness: 2px;
  position: relative;
}
article .contains-reservation .price-show .barre-price-line {
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--gray-500);
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(calc(-50% - 0.7px));
}
article .contains-reservation .price-show .big-price + span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
article .contains-reservation .date-info {
  row-gap: 8px;
  justify-content: flex-start;
}
article .contains-reservation .date-info label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
article .contains-reservation .date-info span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
article .contains-reservation .options-select .date-select {
  border-radius: 6px 6px 0px 0px;
  border: 1px solid var(--gray-500);
  border-bottom: 0;
  padding: 16px;
  width: 100%;
}
article .contains-reservation .options-select .nbr-config {
  border-radius: 0px 0px 6px 6px;
  border: 1px solid var(--gray-500);
  padding: 16px;
  padding-right: 0;
}
article .contains-reservation .options-select .nbr-config label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
article .contains-reservation .options-select .date-select hr {
  width: 0px;
  height: 36px;
  margin: 0;
  border: 1px solid var(--gray-500, #6b7280);
}
article .contains-reservation .options-select .residence-article {
  padding: 0;
  background: none !important;
}
article
  .contains-reservation
  .options-select
  .residence-article:nth-of-type(2) {
  padding-left: 16px;
}
article
  .contains-reservation
  .options-select
  .residence-article:nth-of-type(2) {
  padding-left: 16px;
}
article
  .contains-reservation
  .options-select
  .date-header
  .react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 79px;
  padding: 0;
  inset: 0px auto auto 0px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}
article
  .contains-reservation
  .options-select
  .date-header
  .react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 79px;
  /* inset: 0px auto auto 0px !important; */
}
article .contains-reservation .options-select .custom-date-input {
  max-width: calc(100% - 16px) !important;
}
article .contains-reservation .commande-btn {
  width: 100%;
  row-gap: 16px;
}
article .contains-reservation .commande-btn button {
  width: 100%;
}
article .contains-reservation .commande-btn > span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
article .contains-reservation .commande-btn > span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
article .contains-reservation .total-info {
  margin-top: auto;
  padding-top: 24px;
  border-top: 1px solid var(--gray-200);
}
article .contains-reservation .total-info .txt-normal {
  color: var(--gray-500);
  font-size: 14px;
  font-weight: 500;
  text-decoration: line-through;
  text-decoration-line: strikethrough;
}
article .contains-reservation .total-info .txt-real-price {
  color: var(--destructive-500);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
}
article .contains-reservation .total-info .discount-txt {
  color: var(--shade-white);
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  padding: 4px 5px;
  background: var(--destructive-500);
  line-height: normal;
}
article .contains-reservation .total-info span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
}
.reservation-panel .react-datepicker-popper[data-placement^="top"] {
  inset: auto auto 0px 0px !important;
  left: 50% !important;
}
/*SUITE*/
@media screen and (min-height: 551px) {
  .main-residence-section article.fixed-clamp-reservation,
  .main-residence-section .waiting-load.fixed-clamp-reservation {
    position: fixed;
    top: 210px;
    /* width: calc(100% - 66.171% - 16px); */
    left: 50%;
    transform: translateX(calc(50% + 16px));
  }
  .main-residence-section
    article.fixed-clamp-reservation.fixed-clamp-reservation-bottom,
  .main-residence-section
    .waiting-load.fixed-clamp-reservation.fixed-clamp-reservation-bottom {
    position: absolute;
    top: auto;
    bottom: 0px;
    right: 0;
  }
}

.main-residence-section .reservation-panel-bottom {
  width: calc(100% - 66.171% - 16px);
  top: auto;
  bottom: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  user-select: none;
}

@media screen and (max-width: 1050px) {
  .main-residence-section article .contains-reservation {
    padding: 16px 16px 32px 16px;
  }
  article .contains-reservation .options-select .nbr-config {
  }
}
@media screen and (max-width: 950px) {
  .main-residence-section article .contains-reservation {
    padding: 8px 8px 16px 8px;
    row-gap: 4px;
  }

  article .contains-reservation .price-show .big-price {
    color: var(--shade-black);
    font-size: 24px;
    font-style: normal;
    /* font-weight: 400; */
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
  }
}
@media screen and (max-width: 1050px) {
  article .contains-reservation .total-info {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 900px) {
  .main-residence-section article .contains-reservation {
    padding: 8px;
  }
  article .contains-reservation .total-info {
    flex-wrap: wrap;
  }
  article .contains-reservation .price-show {
    padding: 6px 0;
  }
  article .contains-reservation .price-calcul {
    gap: 0px;
  }
  article .contains-reservation .commande-btn {
    width: 100%;
    row-gap: 6px;
  }
  article .contains-reservation .commande-btn > span {
    text-align: center;
  }
  article .contains-reservation .total-info {
    padding: 2px 0 !important;
    flex-direction: column;
    border-top: 1px solid var(--gray-200) !important;
  }
}
@media screen and (max-width: 830px) {
  article .contains-reservation .options-select .nbr-config {
    flex-direction: column;
    width: 100%;
    padding: 4px;
  }

  article .contains-reservation .price-show .big-price {
    color: var(--shade-black);
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.48px;
  }
  .main-residence-section article .contains-reservation {
    row-gap: 4px;
  }
  article .contains-reservation .price-show {
    column-gap: 6px;
    flex-direction: column;
    padding: 10px 0;
  }
  article .contains-reservation .date-info {
    align-items: center;
  }
  article .contains-reservation .date-info span {
    text-align: center;
  }
}
@media screen and (max-width: 550px) {
  .main-residence-section .waiting-load {
    display: none;
  }
}
