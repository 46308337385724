.modal-select-resi .btn-select-resi {
  width: 100%;
  justify-content: space-between;
  padding: 0;
  border-radius: 0;
  column-gap: 8px;
}
.modal-select-resi .btn-select-resi .left-side {
  column-gap: 16px;
  flex: 1;
}
.modal-select-resi .select-all .left-side > span {
  border-radius: 6px;
  background: var(--primary-500);
  padding: 20px;
  flex-shrink: 0;
  width: 64px;
  height: 64px;
}
.modal-select-resi .btn-select-resi .left-side > img {
  border-radius: 6px;
  background: var(--gray-500);
  width: 64px;
  height: 64px;
  object-fit: cover;
}
.modal-select-resi .btn-select-resi .left-side label {
  color: var(--shade-black, #1e1a21);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: left;
}
.modal-select-resi > button:hover {
  padding: 0 2px;
  cursor: pointer;
}
/* .modal-select-resi .current-select {
  background: var(--gray-100);
  padding: 2px;
  border-radius: 8px;
} */

.modal-select-resi .btn-select-resi .left-side .location-stars span {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.modal-select-resi
  .btn-select-resi
  .left-side
  .location-stars
  > span:last-child {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;

  width: calc(100% - 84px);
}
.modal-select-resi .btn-select-resi .left-side .location-stars svg {
  width: 16px;
  height: 16px;
}
.modal-select-resi .btn-select-resi .left-side .location-stars .stars {
  column-gap: 6px;
}
.modal-select-resi .btn-select-resi .left-side .verti-sepa {
  width: 1px;
  height: 12px;
  border-radius: 58px;
  background: var(--gray-400, #9ca3af);
}

@media screen and (max-width: 850px) {
  .modal-select-resi .btn-select-resi .left-side label {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.44px;
  }
  .modal-select-resi .select-all .left-side > span {
    border-radius: 6px;
    width: 52px;
    height: 52px;
    padding: 14px;
  }
  .modal-select-resi .btn-select-resi .left-side .location-stars span {
    font-size: 14px;
    line-height: 22px;
  }
  .modal-select-resi .btn-select-resi .left-side > img {
    width: 52px;
    height: 52px;
  }
  .select-resi-modal-container .modal-contains {
    width: 430px !important;
  }
}
