.pac-container {
  transform: translateY(18px) !important;
  padding: 0;
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  row-gap: 16px;
  animation: show-search 0.1s linear forwards;
  min-width: 270px;
  max-width: 350px;
  overflow: hidden;
}

.pac-container .pac-item {
  cursor: pointer;
  transition: 0.2s;
  padding: 16px !important;
  padding-top: 4px !important;
  padding-bottom: 2px !important;
  border-bottom: none !important;
  border-top: 1px solid var(--gray-200);
  display: grid;
  grid-template-columns: 24px 1fr;
  column-gap: 4px;
  align-items: center;
  border-radius: 0;
}
.pac-container .pac-item:hover {
  background: var(--gray-100);
}
.pac-container .pac-item:first-child {
  border-top: none !important;
  /* padding-top: 0 !important; */
}
.pac-container::after,
.pac-logo:after {
  content: none !important;
  background: none !important;
  background-image: none !important;
  height: 0 !important;
  overflow: hidden !important;
}
.pac-container .pac-item:nth-child(5) {
  display: none;
}
@media screen and (max-height: 800px) {
  @media screen and (max-width: 600px) {
    .pac-container .pac-item:nth-child(4) {
      display: none;
    }
  }
}
.pac-container .pac-item span {
  flex-shrink: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--shade-black);
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pac-container .pac-item > span:last-child,
.pac-container .pac-item > span:last-child .pac-matched {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--gray-400);
  width: 100%;
  display: inline;
  grid-column-start: 2;
}
.pac-container .pac-item span.pac-matched {
  font-weight: 700 !important;
}
.pac-container .pac-item .pac-icon {
  background-image: url("../../../public/icons/marker-pin-01.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 22px;
  height: 22px;
  background-color: var(--gray-200);
  border-radius: 50%;
  background-size: 70%;
  margin: 0;
  flex-shrink: 0;
  transform: translateY(2px);
}
