.profile-page {
  gap: 24px;
  flex-wrap: wrap;
  padding-bottom: 40px;
}

.profile-page .profil-photo {
  row-gap: 16px;
  padding: 16px 16px 32px 16px;
  width: calc(100% / 3 - 48px / 3);
  height: 464px;
  background: var(--shade-white);
  border-radius: 30px;
  background: var(--secondary-primary-white, #fff);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
}

.profile-page .profil-photo > img {
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 367/170;
}

.profile-page .profil-photo .photo-panel {
  position: absolute;
  top: 82px;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: auto;
  row-gap: 24px;
}
.profil-photo .photo-panel .img-show {
  row-gap: 16px;
  cursor: pointer;
  background: var(--shade-white);
  border-radius: 50%;
}
.profil-photo .photo-panel .img-show > img {
  border-radius: 50%;
  width: 220px;
  height: 220px;
  object-fit: cover;
  border: 4px solid var(--shade-white);
  transition: 0.2s;
}
.profil-photo .photo-panel .img-show > .not-photo {
  border-radius: 50%;
  width: 220px;
  height: 220px;
  color: var(--gray-700);
  font-size: 16px;
  display: block;
  text-align: center;
  font-family: Mori Gothic;
  pointer-events: none;
  font-weight: 600;
  padding-top: calc(50% - 10px);
}
.profil-photo .photo-panel .img-show > div {
  padding: 4px 8px;
  column-gap: 8px;
  border-radius: 100px;
  background: var(--shade-white);
  box-shadow: 0px 0px 0px 0px rgba(20, 20, 20, 0.1),
    1px 6px 14px 0px rgba(20, 20, 20, 0.1),
    4px 25px 26px 0px rgba(20, 20, 20, 0.09),
    9px 57px 35px 0px rgba(20, 20, 20, 0.05),
    16px 102px 41px 0px rgba(20, 20, 20, 0.01),
    25px 159px 45px 0px rgba(20, 20, 20, 0);
  flex-shrink: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.2s;
}

.profil-photo .photo-panel .img-show:hover img {
  opacity: 0.8;
}
.profil-photo .photo-panel .img-show:hover div {
  background: var(--gray-200);
}
.profil-photo .photo-panel .img-show > div img {
  width: 14px;
  height: 14px;
}
.profil-photo .photo-panel .img-show > div span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.profil-photo .name-status {
  row-gap: 8px;
}

.profil-photo .name-status span {
  color: var(--neutral-black-black-700);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.profil-photo .name-status .edit-name-btn {
  border-radius: 50%;
  border: 1px solid transparent;
}

.profil-photo .name-status .status {
  border-radius: 100px;
  background: var(--primary-primary-50);
  padding: 2px 6px;
}

.profil-photo .name-status .status span {
  color: var(--primary-primary-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.profile-page .fichier-upload {
  padding: 16px;
  border-radius: 20px;
  background: var(--shade-white, #fff);
  width: 400px;
  row-gap: 24px;
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
}

.profile-page .fichier-upload .content-upload {
  border-radius: 10px;
  padding: 18px 29px 19px 29px;
  width: 100%;
  border: 1px dashed var(--primary-500);
  background: var(--primary-50);
  cursor: pointer;
  transition: 0.2s;
}
.profile-page .fichier-upload .content-upload.inload {
  column-gap: 20px;
}
.profile-page .fichier-upload .content-upload.inload :is(img, .pdf-file) {
  aspect-ratio: 5/4;
  width: 80px;
  min-height: 70px;
  height: 70px;
  background: var(--gray-700);
  object-fit: contain;
  border-radius: 10px;
}
.profile-page .pdf-file {
  color: var(--shade-white);
  font-weight: 800;
  display: flex;
  justify-content: center;
  font-size: 14px;
  align-items: center;
}
.profile-page .fichier-upload .content-upload.inload a img {
  display: inline-block; /* Assurez-vous que le lien a une largeur et une hauteur définies */
  text-align: center; /* Centre le contenu dans le lien */

  background: var(--gray-200);
  background-size: cover; /* Ajustez la taille de l'image pour couvrir tout le contenu du lien */
  background-repeat: no-repeat; /* Empêche l'image de se répéter */
  background-position: center; /* Centrez l'image dans le lien */
  color: var(--shade-black);
  font-weight: 600;
  font-style: italic;
}
.profile-page .fichier-upload .content-upload:hover {
  opacity: 0.8;
}
.profile-page .fichier-upload .bot-info span {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
}
.profile-page .content-upload svg {
  width: 56px;
  height: 56px;
}
.profile-page .content-upload .upload-txt {
  row-gap: 8px;
}
.profile-page .content-upload .upload-txt span {
  color: var(--primary-500);
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.profile-page .content-upload .upload-txt p,
.profile-page .fichier-upload p {
  color: var(--secondary-dark-grey-500);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.profile-page .info-personnelle {
  height: auto;
  padding: 32px;
  row-gap: 32px;
  flex-shrink: 0;
  border-radius: 24px;
  background: var(--secondary-primary-white, #fff);
  width: calc(100% / 3 - 48px / 3);
  /* CARD BOX SHADOW */
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
}
.profile-page .contains-digit {
  justify-content: space-between;
  width: 100%;
}
.profile-page .contains-digit .code-input {
  border-radius: 12px;
  border: 1px solid var(--Neutral-Neutral---200, #8aa0bd);
  background: var(--Neutral-Neutral---100, #f1f5f9);
  width: 68px;
  height: 68px;
  padding: 8px 16px;
  color: var(--Neutral-Neutral---900, #0f172a);
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 36px; /* 128.571% */
  letter-spacing: -0.56px;
  text-align: center;
}

@media screen and (max-width: 400px) {
  .profile-page .contains-digit .code-input {
    width: calc(100% / 4 - 48px / 4);
    aspect-ratio: 1/1 !important;
    height: auto;
    padding: 2px 7px;
  }
}
.profile-page .contains-digit .code-input::placeholder {
  color: var(--Neutral-Neutral---900, #0f172a);
  text-align: center;
}
.profile-page .info-personnelle .info-sec {
  color: var(--gray-800, #1f2937);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.profile-page .info-personnelle .separator {
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background: var(--gray-200, #e5e7eb);
}
.profile-page .info-personnelle > label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.56px;
  text-align: center;
  width: 100%;
}

.profile-page .info-personnelle .info-perso {
  row-gap: 24px;
  width: 100%;
}
.profile-page .info-personnelle .info-perso .input-select {
  width: 100%;
  column-gap: 16px;
  padding-right: 0px;
}
.profile-page .info-personnelle .info-perso .input-select .circle-icone {
  padding: 8px;
  border-radius: 50%;
  background: var(--primary-50);
  flex-shrink: 0;
  width: 40px;
  height: 40px;
}
.profile-page .info-personnelle .info-perso .input-select .circle-icone svg {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 24px;
}
.profile-page .info-personnelle .info-perso .input-select .right-content {
  row-gap: 4px;
  flex: 1;
}
.profile-page
  .info-personnelle
  .info-perso
  .input-select
  .right-content
  > label {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.profile-page .info-personnelle .info-perso .input-select .right-content > span,
.profile-page
  .info-personnelle
  .info-perso
  .input-select
  .right-content
  div
  > span {
  color: var(--shade-black, #1e1a21);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile-page .info-personnelle .info-perso .input-select .right-content {
  max-width: calc(70% - 24px - 16px);
}
.profile-page .info-personnelle .info-perso .input-select button {
  margin-left: auto;
  padding: 6px;
  border: 1px solid var(--shade-white);
  transition: 0.25s;
  border-radius: 50%;
}
.profile-page .info-personnelle .info-perso .input-select button img {
  width: 24px;
  height: 24px;
}
.profile-page .info-personnelle .info-perso .input-select button:hover,
.profil-photo .name-status .edit-name-btn:hover {
  background: var(--primary-50);
  border: 1px solid var(--gray-300);
}
.profile-page .verification {
  border-radius: 20px;
  border: 1px solid var(--gray-400);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  padding: 16px;
  background: var(--shade-white);
  row-gap: 24px;
}
.profile-page .verification label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
}
.profile-page .verification div {
  justify-content: flex-start;
  column-gap: 16px;
}
.profile-page .verification div img,
.profile-page .verification div svg {
  width: 24px;
  height: 24px;
}
.profile-page .verification div span {
  overflow: hidden;
  color: var(--gray-700);
  text-overflow: ellipsis;
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.profile-page .verification div.not span {
  color: var(--destructive-500);
}
.profile-page .verification div.is-ok svg:nth-of-type(1) {
  display: none;
}
.profile-page .verification div.not svg:nth-of-type(2) {
  display: none;
}

/*PIECE SEND*/

.piece-send-modal .modal-piece :is(img, .pdf-file) {
  width: 300px;
  aspect-ratio: 8/6;
  height: auto;
  border-radius: 16px;
  background: var(--gray-200);
  object-fit: contain;
}
.piece-send-modal .modal-piece .pdf-file {
  color: var(--shade-black);
  font-size: 14px;
  row-gap: 2px;
  flex-direction: column;
  aspect-ratio: auto;
  background: var(--gray-100);
}
.piece-send-modal .modal-piece .pdf-file span {
  margin: 4px 0;
  color: var(--shade-black);
  font-size: 16px;
}
.piece-send-modal .modal-piece.profil-pic img {
  width: 250px;
  aspect-ratio: 1/1;
  height: auto;
  border-radius: 50%;
  background: var(--gray-200);
  object-fit: cover;
  border: 2px solid var(--gray-200);
}

.piece-send-modal .modal-piece span {
  color: var(--gray-700);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
  margin: 10px 0;
  text-align: center;
  width: 70%;
}
/* CHANGE MDP ETC MODAL */

.modal-change-edit {
  width: 350px;
  max-width: 100%;
  /* padding-top: 16px; */
}

.modal-change-edit label {
  color: var(--gray-400);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
  text-align: center;
}
.modal-change-edit .input-field {
  width: 100%;
  height: auto;
  row-gap: 16px;
}
.modal-change-edit .input {
  width: 100%;
  height: auto;
}
.modal-change-edit .input input {
  border-radius: 6px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  padding: 16px;
  padding-left: 42px;
}

.modal-change-edit .password input {
  padding-right: 42px;
}

.modal-change-edit .input input:focus {
  border: 1px solid var(--gray-600);
  background: var(--gray-50);
}

.modal-change-edit .input input {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: all 0.2s ease-in-out;
  width: 100%;
}
.modal-change-edit .input input::placeholder {
  color: var(--gray-400);
  font-weight: 400;
}
.modal-change-edit .input > svg {
  position: absolute;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  pointer-events: none;
}
.modal-change-edit .input > .see-pass {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  padding: 0;
}
.modal-change-edit .input .detect-issue-input {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  max-width: 100%;
  background: var(--issue-100);
  padding: 5px;
  border-radius: 4px;
  opacity: 0;
  animation: showMe-2 0.2s ease-in-out forwards;
  z-index: 2;
}
@keyframes showMe-2 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.modal-change-edit .input .detect-issue-input .chevron {
  position: absolute;
  top: -7px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--issue-100);
  left: 10px;
  transform: scale(1.3);
  z-index: 2;
}
.modal-change-edit .input .detect-issue-input span {
  color: var(--issue-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
}
.modal-change-edit .next-btn {
  border-radius: 100px;
  background: var(--primary-500);
  padding: 16px 34px;
  align-items: center;
  justify-content: center;
  color: var(--shade-white);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
  margin-top: 16px;
}
@media screen and (max-width: 1270px) {
  .profile-page .profil-photo {
    width: 400px;
  }
  .profile-page .info-personnelle {
    height: auto;
    padding: 32px;
    row-gap: 32px;
    flex-shrink: 0;
    border-radius: 24px;
    background: var(--secondary-primary-white, #fff);
    width: 415px;
  }
}
@media screen and (max-width: 890px) {
  .profile-page > div {
    width: calc(50% - 24px / 2) !important;
  }
  .profile-page > .modal-content {
    width: 100% !important;
  }
}
@media screen and (max-width: 750px) {
  .profile-page > div {
    width: calc(100%) !important;
  }
}
