.description-modal {
  max-width: 624px;
  row-gap: 24px;
}
.description-modal .contents {
  flex: 1;
  /* overflow-y: scroll; */
  padding-bottom: 16px;
}
.description-modal .contents {
  flex: 1;
  /* overflow-y: scroll; */
  padding-bottom: 16px;
}
