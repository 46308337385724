.step-16 .left-part-add-resi .regle {
  border: none;
  padding: 24px;
  border-radius: 6px;
  border: 1px solid var(--warning-200);
  background: var(--warning-100);
  column-gap: 16px;
}
.step-16 .left-part-add-resi .regle p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.step-16 .left-part-add-resi .regle .circle {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 4px;
  background: var(--warning-50);
}

.step-16 .right-part-add-resi {
}
.step-16 .right-part-add-resi .contains-selects {
  width: 100%;
  height: 100%;
  padding-top: 155px;
  padding-left: 12%;
  padding-bottom: 95px;
}
.step-16 .right-part-add-resi .activities-type {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 12%;
  row-gap: 24px;
}
.step-16 .right-part-add-resi .activities-type::-webkit-scrollbar {
  width: 6px; 
}

.step-16 .right-part-add-resi .activities-type::-webkit-scrollbar-thumb {
  background-color: var(--primary-500); 
  border-radius: 12px; 
}

.step-16 .right-part-add-resi .activities-type::-webkit-scrollbar-track {
  background-color: var(--primary-100);
  border-radius: 12px; 
}

.step-16 .right-part-add-resi .activities-type .contains-zone {
  padding: 0 12px;
  padding-bottom: 24px;
  row-gap: 8px;
  border-bottom: 1px solid var(--gray-200);
  height: auto;
}

.step-16 .right-part-add-resi .activities-type .contains-zone label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
  margin-bottom: 12px;
}

.step-16 .right-part-add-resi .activities-type .contains-zone button {
  padding: 4px 0;
  border-radius: 0;
  justify-content: flex-start;
  column-gap: 20px;
}

.step-16 .right-part-add-resi .activities-type .contains-zone button span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
  flex: 1;
}

.step-16
  .right-part-add-resi
  .activities-type
  .contains-zone
  button
  .case-cocher {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  border: 2px solid #1e1a21;
}

.step-16
  .right-part-add-resi
  .activities-type
  .contains-zone
  button
  .case-cocher
  div {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  background: var(--primary-500);
  flex:none;
}
