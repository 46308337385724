.paiement.second-how .contains {
  row-gap: 48px;
  padding: 64px 41.5px;
  padding-bottom: 110px;
}

.paiement.second-how .contains > .title p {
  width: 100%;
}

.contents-how {
  column-gap: 24px;
}

.contents-how .card-how {
  padding: 20px 32px;
  border-radius: 30px;
  border: 1px solid var(--gray-200, #e5e7eb);
  background: var(--shade-white, #fff);
  row-gap: 20px;
  align-items: flex-start;
  flex: 1;
}
.contents-how .card-how:nth-of-type(1),
.contents-how .card-how:nth-of-type(3) {
  transform: translateY(48px);
  /* margin-top: 48px; */
}
.contents-how .card-how .icon {
  border-radius: 50%;
  padding: 12px;
  background: var(--primary-100);
}
.contents-how .card-how svg {
  width: 24px;
  height: 24px;
}
.contents-how .card-how .content {
  width: 100%;
}
.contents-how .card-how .content h3 {
  color: var(--shade-black);
  font-family: "Mori Gothic";
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.48px;
}
.contents-how .card-how .content p {
  color: var(--shade-black);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.vector-left-how {
  top: 22px;
  left: -16px;
  transition: 0.3s;
}
@media screen and (max-width: 1439px) {
  .vector-left-how {
    opacity: 0;
  }
}
@media screen and (max-width: 1276px) {
  .contents-how {
    column-gap: 16px;
  }
}

@media screen and (max-width: 950px) {
  .paiement.second-how .contains {
    padding: 64px 32px;
  }
  .contents-how .card-how:nth-of-type(1),
  .contents-how .card-how:nth-of-type(3) {
    transform: translateY(0);
  }
}

@media screen and (max-width: 750px) {
  .contents-how .card-how {
    padding: 20px 16px;
  }
}
@media screen and (max-width: 680px) {
  .paiement.second-how .contains {
    padding: 32px 16px;
  }
  .contents-how {
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
  }
  .second-how .contains > .title {
    row-gap: 16px;
  }
  .contents-how .card-how .icon {
    padding: 8px;
  }
  .contents-how .card-how svg {
    width: 16px;
    height: 16px;
  }
  .contents-how .card-how .content h3 {
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
}
