.reservation-mobile-show {
  z-index: 6;
}

.reservation-mobile-show .full-containe {
  row-gap: 16px;
}

.add-personne-mobile {
  padding: 24px 16px;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  border: 1px solid var(--gray-100, #f3f4f6);
  background: var(--shade-white, #fff);

  /* CARD BOX SHADOW */
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  row-gap: 16px;
}
.add-personne-mobile > label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
  text-align: left;
  width: 100%;
}
.add-personne-mobile .nbrs {
  width: 100%;
  justify-content: space-between;
}

.reservation-mobile-show .info-reglement-warning {
  padding: 8px;
}
.reservation-mobile-show .info-reglement-warning p {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.reservation-mobile-show .next-step {
  padding: 8px 0;
  border-top: 1px solid var(--gray-300);
  /* background: var(--shade-white, #fff); */
}
.reservation-mobile-show .next-step button {
  width: 100%;
}

.reservation-mobile-show .confirm-reservation-info {
  row-gap: 8px;
  align-items: flex-start;
  padding-bottom: 16px;
  width: 100%;
}
.reservation-mobile-show .confirm-reservation-info .tile-residence img {
  width: 70px;
  object-fit: cover;
}
.reservation-mobile-show .confirm-reservation-info label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.reservation-mobile-show .discount-txt {
  color: var(--shade-white) !important;
  font-size: 13px;
  font-style: normal;
  font-weight: 800;
  padding: 4px 5px;
  background: var(--destructive-500);
  line-height: normal;
}
.reservation-mobile-show .confirm-reservation-info span {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
