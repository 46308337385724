.step-7 .right-part-add-resi .filter-selected-panel {
  padding: 0;
  border: none;
  column-gap: 20px;
}
.step-7 .right-part-add-resi .filter-selected-panel .commodities-select {
  padding: 0;
  border: none;
  gap: 20px;
  flex-wrap: wrap;
}
.step-7 .right-part-add-resi .filter-selected-panel .commodities-select button {
  width: calc(100% / 3 - 40px / 3);
  border: 1px solid var(--primary-200);
}
.step-7
  .right-part-add-resi
  .filter-selected-panel
  .commodities-select
  button
  img {
  width: 32px;
  height: 32px;
}

.step-7 .right-part-add-resi .contains-input {
  margin-top: 95px;
  max-height: calc(100vh - 195px);
  overflow-y: scroll;
}

@media screen and (max-width: 950px) {
  .step-7 .right-part-add-resi .contains-input {
    margin-top: 0;
    max-height: auto;
    overflow-y: visible;
  }
  .step-7
    .right-part-add-resi
    .filter-selected-panel
    .commodities-select
    button {
    width: calc(100% / 4 - 60px / 4);
  }
}
@media screen and (max-width: 800px) {
  .step-7
    .right-part-add-resi
    .filter-selected-panel
    .commodities-select
    button {
    width: calc(100% / 3 - 40px / 3);
  }
}
@media screen and (max-width: 450px) {
  .step-7
    .right-part-add-resi
    .filter-selected-panel
    .commodities-select
    button {
    width: calc(100% / 2 - 20px / 2);
  }
}
