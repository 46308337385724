.range-select {
  row-gap: 32px;
  margin-top: 10px;
  width: 100%;
}

.slider-price {
  width: 100%;
  margin-top: 0 !important;
}
.slider-price .rs-slider-bar {
  background-color: var(--gray-100);
  border-radius: 1000px;
}
.slider-price .rs-slider-bar .rs-slider-progress-bar {
  background: var(--primary-500);
}
.slider-price .rs-slider-handle::before {
  border: 6.5px solid var(--primary-500);
  background-color: #fff;
  width: 22px;
  height: 22px;
  transform: translateY(-5px);
}

.range-select .input-value > span {
  width: 10.907px;
  height: 1.363px;
  border-radius: 1.363px;
  background: var(--gray-900, #111827);
}
.input-value .rs-night {
  position: absolute;
  user-select: none;
  pointer-events: none;
  right: 16px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-500);
  top: 50%;
  transform: translateY(-50%);
}
.range-select .input-value .input-v {
  border: none;
  border-radius: 16px;
  width: 40%;
}
.range-select .input-value .input-v .txt {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: var(--shade-white);
  z-index: 2;
  border: 1.363px solid var(--gray-200, #e5e7eb);
  padding: 13.633px 16.36px;
  pointer-events: none;
  user-select: none;
}
.range-select .input-value .rs-input-number {
  border: none;
  border-radius: 16px !important;
  width: 100%;
  padding: 18px 16px;
  border: 1.363px solid var(--gray-200, #e5e7eb);
  font-family: Inter;
  font-style: normal;
  color: var(--shade-black);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.range-select .input-value .rs-input-number::placeholder {
  color: var(--gray-400);
}
.range-select .input-value .rs-input-number:focus-within {
  outline-color: var(--primary-500);
}
.range-select .input-value .rs-input-number input {
  border-radius: 16px !important;
  border: 1.363px solid var(--gray-200, #e5e7eb);
  padding: 13.633px 16.36px;
}
.range-select .input-value .rs-input-number-btn-group-vertical {
  display: none;
}

@media screen and (max-width: 560px) {
  .range-select .input-value {
    justify-content: center;
    gap: 20px;
  }
  .range-select .input-value > span {
    margin: 0 auto;
  }

  .range-select .input-value .input-v {
    width: 100%;
  }
}

@media screen and (max-width: 550px) {
  .range-select .input-value .rs-input-number {
    font-size: 14px;
    line-height: 20px;
    padding: 16px 12px;
  }
}
@media screen and (max-width: 440px) {
  .range-select .input-value {
    gap: 8px;
  }
}
@media screen and (max-width: 420px) {
  .range-select .input-value .rs-night {
    font-size: 10px;
  }
}
@media screen and (max-width: 380px) {
  .range-select .input-value .rs-input-number {
    font-size: 12px;
  }
  .range-select .input-value .rs-night {
    font-size: 9.5px;
  }
}
