.reservation-paiement .fixed-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: auto;
  padding-top: 12px;
  padding-bottom: 12px;
  background: var(--shade-white);
  z-index: 1;
}
.reservation-paiement .second-title-show {
  top: 85px;
  padding: 0;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(2px);
  animation: show-me-header-slow 0.2s ease-out forwards;
  z-index: 1;
}

.reservation-paiement .second-title-show .title {
}
.reservation-paiement .second-title-show .title button {
  padding: 4px;
}
.reservation-paiement .second-title-show .title button img {
  width: 32px;
  height: 32px;
}
.reservation-paiement .second-title-show .title label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
}
@keyframes show-me-header-slow {
  from {
    opacity: 0;
    transform: scale(1.15);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
.reservation-paiement .fixed-header .contains-fixed-header {
  padding-top: 16px;
  padding-bottom: 16px;
}
