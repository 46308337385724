.modal-content {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  left: 0;
  top: 0;
  z-index: 10;
  animation: show-me-slowly 0.3s forwards;
  transform: backdrop-filter 0.3s;
  /* overflow: visible !important; */
}
.modal-content.brum-background {
  overflow: visible !important;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(3px);
}

.modal-content.no-opacity {
  /* overflow: visible !important; */
}
.modal-content.no-opacity .content {
  /* transition: opacity 0.3s; */
  /* opacity: 0.2; */
  /* z-index: 0; */
  /* overflow: visible !important; */
  /* pointer-events: none; */
}
.modal-content.fit-hc .modal-contains {
  min-height: fit-content;
}
@keyframes show-me-slowly {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modal-content .background-touch {
  position: fixed;
  width: 100vw;
  height: 100dvh;
  z-index: 1;
  opacity: 0.2;
  background: var(--shade-black, #1e1a21);
}

.modal-content .background-touch.brum-background {
  /* backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.8); */
}

.modal-content .modal-contains {
  background: var(--shade-white);
  min-height: 320px;
  max-width: calc(100vw - 32px);
  max-height: calc(70dvh);
  /* overflow-y: scroll; */
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  z-index: 2;
  padding: 16px 24px;
}

.just-modal.modal-content .modal-contains {
  min-height: fit-content;
}
.just-modal.modal-content .modal-contains button:last-child {
  border-radius: 8px;
}
.top-header-modal {
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  position: absolute;
  top: 22px;
  left: 24px;
  width: calc(100% - 48px);
}
.modal-content .modal-contains .btn-close {
  padding: 8px;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  background: var(--primary-50, #f6f1ff);
}
.modal-content .modal-contains .title-put-top {
  max-width: calc(100% - 70px);
  text-overflow: ellipsis;
  text-align: left;
  border-radius: 100px;
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.4px;
  animation: showMe-slow-title 0.2s forwards;
}
@keyframes showMe-slow-title {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.modal-content .modal-contains .content.bottom-child {
  margin-top: 70px;
  overflow-y: scroll;
}
.modal-content .modal-contains .over-content {
  /* margin-top: 70px; */
  overflow: visible;
  overflow-y: visible !important;
}
@media screen and (max-height: 600px) {
  .modal-content .modal-contains .over-content {
    /* margin-top: 70px; */
    overflow-y: scroll !important;
  }
}
.visible-overflow-modal .modal-contains .content.bottom-child {
  overflow-y: visible;
  overflow-x: visible;
}

.modal-content .modal-contains .content.bottom-child::-webkit-scrollbar {
  width: 0;
  opacity: 0;
}

.modal-content .modal-contains .content > label {
  color: var(--shade-black, #1e1a21);
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.56px;
}

@media screen and (max-width: 550px) {
  .modal-content .content {
    overflow-x: hidden;
  }
  .residence-page .share-modal .residence-show .infos > label {
    /* font-size: 16px;
    line-height: 24px; */
    width: 100%;
  }
  .share-modal .residence-show .infos {
    width: calc(100% - 64px - 16px);
  }
  .share-modal .residence-show .second-info > span {
    overflow: hidden;
    width: calc(100vw - 45px - 64px - 32px - 58px);
  }
  .modal-content .modal-contains .title-put-top {
    top: 24px;
  }
}
@media screen and (max-width: 475px) {
  .modal-content .modal-contains {
    padding: 16px;
  }
  .residence-page .share-modal .residence-show .infos > label {
    /* font-size: 16px;
    line-height: 24px; */
    width: calc(100vw - 45px - 64px - 32px);
  }
  .share-modal .residence-show .second-info > span {
    overflow: hidden;
    width: calc(100vw - 45px - 64px - 32px - 58px);
  }
  .residence-page .share-modal .btns-action {
    flex-direction: column;
  }
  .residence-page .share-modal .btns-action button {
    width: calc(100vw - 70px);
  }
}
