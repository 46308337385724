:root {
  --background: #fff;
  --background-bg-secondary: #f3f5f6;
  --primary-900: #34176e;
  --primary-800: #502e92;
  --primary-700: #6b46b8;
  --primary-600: #8f63d6;
  --primary-500: #a273ff;
  --primary-300: #c7abff;
  --primary-200: #dac7ff;
  --primary-100: #ece3ff;
  --primary-50: #f6f1ff;
  --shade-black: #1e1a21;
  --shade-white: #fff;
  --gray-900: #111827;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-600: #4b5563;
  --gray-500: #6b7280;
  --gray-400: #9ca3af;
  --gray-300: #d1d5db;
  --gray-200: #e5e7eb;
  --gray-100: #f3f4f6;
  --gray-50: #f9fafb;
  --star-color: #facc15;
  --issue-500: #ff4d4f;
  --issue-300: #ff7875;
  --issue-200: #ffa39e;
  --issue-100: #ffd8d6;
  --issue-50: #fff1f0;
  --destructive-50: #fde8e8;
  --destructive-400: #ef4343;
  --destructive-500: #ef4444;
  --neutral-black-black-700: #333843;
  --SUPPORT-1: #ffa273;

  --success-500: #22c55e;
  --warning-500: #f59e0b;
  --warning-400: #f9b115;
  --warning-300: #fbc02d;
  --warning-200: #fbd99d;
  --warning-100: #fdecce;
  --warning-50: #fef5e7;
  --primary-primary-50: #efeffd;
  --primary-primary-500: #5c59e8;
  --secondary-dark-grey-500: #8f9bba;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  color: var(--gray-800);
}
h6,
h5,
h4,
h3,
h2,
h1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
ul,
li,
a {
  list-style: none;
  text-decoration: none;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flex {
  display: flex;
}

.flrow {
  display: flex;
  flex-direction: row;
}

.flcolm {
  display: flex;
  flex-direction: column;
}

.btw {
  justify-content: space-between;
}

.liner {
  align-items: center;
}

.center {
  align-items: center;
}

.jcenter {
  justify-content: center;
}

.allcenter {
  justify-content: center;
  align-items: center;
}

.maxwidth {
  width: 100%;
}

.addgap8 {
  gap: 8px;
}

.addgap16 {
  gap: 16px;
}

.addgap24 {
  gap: 24px;
}

button,
a {
  transition: 0.3s;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  padding-inline: 0;
  padding-block: 0;
}

button:hover,
a:hover {
  opacity: 0.7;
}

button:disabled:hover,
a:disabled:hover {
  opacity: 1;
  cursor: not-allowed;
}

input {
  outline: none;
  background: none;
}

.btn {
  padding: 14px 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 8px;
  border-radius: 100px;
  font-weight: 500;
  line-height: 20px;
}

.btn-new:hover {
  opacity: 1 !important;
}

.btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
  background: var(--gray-500) !important;
  color: var(--shade-white) !important;
}
main {
  overflow-x: hidden;
}
@media screen and (max-width: 950px) {
  .btn {
    padding: 12px 24px;
  }
}

.pry-btn {
  background: var(--primary-500);
  color: var(--shade-white);
}
.pry-btn span {
  color: var(--shade-white);
}
.pry-btn svg {
  fill: var(--shade-white);
}

.sd-btn {
  background: var(--primary-50);
  color: var(--shade-black);
}
.sd-btn span {
  color: var(--shade-black);
}
.sd-btn svg {
  fill: var(--shade-black);
}

.opt-btn {
  border: 1px solid var(--primary-200);
  background: var(--primary-50);
  color: var(--gray-900);
}
.opt-btn span {
  color: var(--gray-900);
}
.opt-btn svg {
  fill: var(--gray-900);
}
.opt-btn:disabled,
.not-active-opt {
  border: 1px solid transparent;
  background: var(--gray-100);
  color: var(--gray-400);
}
.opt-btn:disabled span {
  color: var(--gray-400);
}
.opt-btn:disabled svg {
  fill: var(--gray-400);
}
.pryy-btn {
  background: var(--primary-800);
  color: var(--shade-white);
  border-radius: 6px;
}
.pryy-btn span {
  color: var(--shade-white);
  font-weight: 600;
}
.pryy-btn svg {
  fill: var(--shade-white);
}
.cc-btn {
  border: 1px solid transparent;
  background: var(--destructive-400);
  color: var(--shade-white);
}
.cc-btn span {
  color: var(--shade-white);
}
.cc-btn svg {
  fill: var(--shade-black);
}
.info-btn {
  border: 1px solid transparent;
  border-radius: 6px;
  background: var(--primary-50);
}
.info-btn span {
  color: var(--shade-black);
}
.info-btn svg {
  fill: var(--shade-black);
}
.pry-btn.disabled {
  background: var(--gray-100);
  color: var(--gray-400);
}

.ssd-btn {
  border: 1px solid var(--primary-500, #a273ff);
  background: none;
  color: var(--primary-500);
}
.ssd-btn span {
  color: var(--primary-500);
}
.ssd-btn svg {
  fill: var(--primary-500);
}

.cch-btn {
  border: none;
  background: none;
  color: var(--destructive-500, #ef4444);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 6px;
  background: var(--destructive-50, #fde8e8);
}
.cch-btn span {
  color: var(--destructive-500, #ef4444);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.ach-btn {
  border: none;
  background: none;
  color: var(--success-500, #22c55e);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  border-radius: 6px;
  background: var(--success-50, #e9fbf0);
}
.ach-btn span {
  color: var(--success-500, #22c55e);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.cch-btn svg {
  fill: var(--destructive-500);
}

.max-container {
  margin: 0 auto;
  padding-left: 80px;
  padding-right: 80px;
  max-width: 1440px;
}
.max-container-hote {
  padding-left: 32px;
  padding-right: 32px;
  max-width: 1440px;
}

.lbl-modal {
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.56px;
}
.p-just {
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.-l {
  text-align: left;
}
.-c {
  text-align: center;
}

.rlve {
  position: relative;
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.tc-btn {
  width: 183.356px;
  height: 40px;
}
.tc-btn-2 {
  display: none;
  width: 60px;
  aspect-ratio: 1/1;
  height: auto;
  padding: 0;
}
.tc-btn-2 img {
  width: 100%;
  height: 100%;
}
.tc-btn img {
  width: 100%;
  height: 100%;
}

div > .lbl-inp {
  position: absolute;
  max-width: calc(100% - 16px);
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--shade-black, #1e1a21);
  font-weight: 500;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  transition: 0.15s;
  user-select: none;
  pointer-events: none;
}

div > .lbl-up {
  top: 10%;
  transform: translate(0);
  font-weight: 700;
  white-space: nowrap;
  font-size: 11px;
}

div .hd-inp > span {
  position: absolute;
  font-size: 13.5px;
  max-width: calc(100% - 16px);
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  visibility: hidden;
  pointer-events: none;
  user-select: none;
  overflow: hidden;
  text-align: justify;
  white-space: nowrap;
  text-overflow: ellipsis;
}

div .hd-inp .show-me {
  visibility: visible;
}

.despair {
  animation: despair-me 0.6s ease-in-out forwards;
  user-select: none !important;
  pointer-events: none !important;
}

.span-skeleton {
  height: 10px;
  border-radius: 17.5px;
}
.skeleton {
  background-color: hsl(0, 0%, 90%);
  animation: skeleton-loading 1s linear infinite alternate;
}
.design {
  position: absolute;
  user-select: none;
  pointer-events: none;
}
.desktop-vector {
  display: block;
}
.mobile-vector {
  display: none;
}
.filter-selected-panel {
  row-gap: 16px;
  padding-bottom: 24px;
  border-bottom: 1px solid var(--gray-200);
  width: 100%;
  align-items: flex-start;
}
.filter-selected-panel label {
  color: var(--shade-black);
  font-family: "Mori Gothic";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.filter-selected-panel .case-selectors {
  flex-wrap: wrap;
  column-gap: 20px;
  align-items: flex-start;
  row-gap: 16px;
}
.filter-selected-panel .case-selectors button {
  width: auto;
  padding: 0;
  justify-content: flex-start;
  align-items: flex-start;
}
.filter-selected-panel .case-selectors button span {
  /* white-space: nowrap; */
  text-align: left;
}
.filter-selected-panel .case-selectors button .close-selected {
  position: absolute;
  top: 2.4px;
  left: 2px;
  width: 16px;
  height: 16px;
  transform: scale(0.75);
}

.notification-system {
  position: fixed;
  left: 32px;
  bottom: 40px;
  border-radius: 8px;
  border: 1px solid var(--gray-100);
  background: var(--shade-white);
  box-shadow: 0px 0px 0px 0px rgba(20, 20, 20, 0.1),
    1px 6px 14px 0px rgba(20, 20, 20, 0.1),
    4px 25px 26px 0px rgba(20, 20, 20, 0.09),
    9px 57px 35px 0px rgba(20, 20, 20, 0.05),
    16px 102px 41px 0px rgba(20, 20, 20, 0.01),
    25px 159px 45px 0px rgba(20, 20, 20, 0);
  overflow: hidden;
  width: auto;
  height: auto;
  z-index: 9999;
  max-width: calc(100% - 32px - 32px);
  opacity: 0;
  transform: translateX(calc(-100% - 32px));
  animation: notification-slide 4.5s ease-in-out forwards;
}
.react-calendar__navigation__label {
  pointer-events: none !important;
  user-select: none !important;
}
@keyframes notification-slide {
  0%,
  100% {
    opacity: 0;
    transform: translateX(calc(-100% - 32px));
  }
  20%,
  80% {
    opacity: 1;
    transform: translateX(0);
  }
}
@media screen and (max-width: 610px) {
  .notification-system {
    bottom: 20px;
  }
}
.notification-system .notification {
  padding: 16px;
  gap: 16px;
  border-bottom: 1px solid var(--gray-100);
  column-gap: 16px;
  min-width: 313px;
  height: auto;
}
.notification-system .notification p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  white-space: normal;
}
.notification-system .notification .hallow {
  position: absolute;
  width: 212px;
  height: 212px;
  z-index: 0;
  left: -74px;
  bottom: -79px;
}
.notification-system .notification .check {
  width: 24px;
  height: 24px;
  z-index: 1;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(0, 0%, 90%);
  }
  100% {
    background-color: hsl(0, 0%, 95%);
  }
}
@keyframes despair-me {
  to {
    opacity: 0;
  }
}

@media screen and (max-width: 1050px) {
  .max-container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

/* @media screen and (max-width: 834px) {
  .max-container {
    padding-left: 32px;
    padding-right: 32px;
  }
} */

@media screen and (max-width: 610px) {
  .max-container {
    padding-left: 16px;
    padding-right: 16px;
  }
  .max-container-hote {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (max-width: 550px) {
  .desktop-vector {
    display: none;
  }
  .mobile-vector {
    display: block;
  }

  .filter-selected-panel label {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
}

.pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.pers-nbr label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

section .info-reglement-warning {
  border-radius: 6px;
  border: 1px solid var(--warning-200);
  background: var(--warning-100);
  width: min-content;
  padding: 24px;
  width: 100%;
}
section .info-reglement-warning > div {
  border-radius: 50%;
  padding: 4px;
  background: var(--warning-50);
}
section .info-reglement-warning p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
}

.btn-wha {
  position: fixed;
  z-index: 10;
  bottom: 24px;
  right: 80px;
  flex: none;
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--primary-500);
}
.btn-wha.survol {
  opacity: 0.2;
}
.btn-wha.survol:hover {
  opacity: 0.8 !important;
}
.btn-wha.hide {
  opacity: 0.05;
}
/* @media screen and (min-width: 1440px) {
  .btn-wha {
    right: auto;
    left: 50%;
    transform: translateX(600px);
  }
} */

@media screen and (max-width: 1050px) {
  .btn-wha {
    right: 32px;
  }
}

@media screen and (max-width: 550px) {
  .btn-wha {
    right: 16px;
  }
}

.load-not-touch {
  user-select: none;
  pointer-events: none;
  opacity: 1;
  animation: load-not-touch 1.5s ease-in-out infinite forwards !important;
}
button.load-not-touch:hover .top-card {
  opacity: 1;
  animation: load-not-touch 1.5s ease-in-out infinite forwards !important;
}
@keyframes load-not-touch {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.45;
  }
  100% {
    opacity: 1;
  }
}

.max-container-store {
  padding-left: 32px;
  padding-right: 32px;
  max-width: 100vw;
}

@media screen and (max-width: 1440px) {
  .max-container-store-fil {
    padding: 0;
  }
}
@media screen and (max-width: 610px) {
  .max-container-store {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.tooltip {
  display: none;
  position: absolute;
  bottom: 50%;
  transform: translateY(-10px);
  border-radius: 6px;
  background: var(--shade-black);
  color: var(--shade-white);
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  padding: 10px;
  font-family: Mori Gothic;
  left: 0;
}

.hv-tooltip {
  position: relative;
  pointer-events: all;
  overflow: visible !important;
}
.hv-tooltip .tooltip {
  /* animation: back-tooltip 0.3s ease-in-out forwards; */
}
.hv-tooltip:hover .tooltip,
.hv-tooltip.testing .tooltip {
  animation: show-tooltip 0.3s ease-in-out forwards !important;
  display: block;
  pointer-events: none;
  user-select: none;
}
.disable-tooltip {
  display: none !important;
}

@keyframes show-tooltip {
  0% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(-15px);
  }
}
@keyframes back-tooltip {
  0% {
    opacity: 1;
    transform: translateY(-10px);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
}

.flash-me {
  animation: flash-me 1.3s linear infinite;
}
@keyframes flash-me {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.show-slowly-anim {
  opacity: 0;
  animation: show-slowly-anim 0.2s 0.05s ease-in-out forwards;
}
@keyframes show-slowly-anim {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.icon-ww {
  width: 44px !important;
  height: 44px !important;
  aspect-ratio: 1/1 !important;
}

.info-reservation .btn-select-defaut:last-child {
  padding: 0 !important;
}
.info-reservation .btn-select-defaut > div {
  margin-top: 4px;
  row-gap: 1px;
}
