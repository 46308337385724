.modal-quit .modal-contains {
  min-height: fit-content;
  width: 350px;
}

.reservation-paiement .full-panel {
  margin: 0 auto;
  padding: 0 80px;
  margin-top: 104px;
  padding-top: 12px;
  padding-bottom: 40px;
  max-width: 1300px;
  row-gap: 32px;
}
.reservation-paiement .recap {
  row-gap: 32px;
  padding: 32px 0;
  padding-top: 16px;
  border-bottom: 1px solid var(--gray-200);
}
.reservation-paiement .recap .title button {
  padding: 4px;
}
.reservation-paiement .recap .title button img {
  width: 32px;
  height: 32px;
}
.reservation-paiement .recap label {
  white-space: nowrap;
  font-family: Mori Gothic;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: -0.8px;
}
.reservation-paiement .recap > label,
.reservation-paiement .paiement-recap label {
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.72px;
}
.reservation-paiement .recap .date-one > label,
.reservation-paiement .recap-paiement > div label {
  color: var(--gray-700);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: -0.48px;
}
.reservation-paiement .recap .date-one > span {
  white-space: nowrap;
  overflow: hidden;
  color: var(--gray-700);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.reservation-paiement .recap-paiement {
  display: none;
}
.reservation-paiement .recap-paiement {
  row-gap: 32px;
}
.reservation-paiement .recap-paiement > div p {
  overflow: hidden;
  color: var(--gray-700);
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.reservation-paiement .recap-paiement > div .total-show {
  font-weight: 600;
  color: var(--success-500);
}
.reservation-paiement .recap-paiement > div .red-txt {
  font-weight: 600;
  color: var(--destructive-500);
}
.reservation-paiement .recap-paiement .big-gras {
  font-weight: 600;
}
.reservation-paiement .paiement-recap {
  row-gap: 24px;
  padding-bottom: 32px;
  border-bottom: 1px solid var(--gray-200);
  width: min-content;
}

.reservation-paiement .paiement-recap img {
  height: 22px;
  width: auto;
}
.reservation-paiement .cancel-condition {
  row-gap: 24px;
}

.reservation-paiement .cancel-condition label {
  color: var(--shade-black);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Mori Gothic;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.72px;
}
.reservation-paiement .cancel-condition .list-condition {
  width: 90%;
  row-gap: 24px;
}
.reservation-paiement .cancel-condition .list-condition > li {
  width: 100%;
  column-gap: 4px;
  position: relative;
  padding-left: 16px;
}
.reservation-paiement .cancel-condition .list-condition > li::before {
  content: "";
  position: absolute;
  left: 0;
  /* top: 50%; */
  transform: translateY(-50%);
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--shade-black);
  display: inline-block;
}

.reservation-paiement .cancel-condition .list-condition > li p {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}
.reservation-paiement .cancel-condition .list-condition > li span {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  transform: translateY(1.4px);
}

.reservation-paiement .btn-infos {
  padding-top: 32px;
  padding-bottom: 32px;
  row-gap: 24px;
  width: 100%;
}

.reservation-paiement .btn-infos p {
  color: var(--gray-700);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.reservation-paiement .btn-infos p a {
  color: var(--primary-500);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration-line: underline;
  text-underline-offset: 2px;
}
.reservation-paiement .full-panel > div {
  width: 554px;
}
.reservation-paiement .full-panel div.paid-window {
  width: 100vw;
}
.reservation-paiement .full-panel .paid-window .modal-contains {
  width: 410px;
}
.reservation-paiement .full-panel .paid-window .modal-contains .btn-close {
  background: var(--gray-700);
  cursor: not-allowed;
}
.reservation-paiement .full-panel .paid-window .modal-contains p {
  font-size: 18px;
}
.reservation-paiement .full-panel .paid-window .modal-contains a {
  color: var(--shade-white);
  padding: 12px 16px;
  background: var(--primary-500);
  border-radius: 8px;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.reservation-paiement .btn-infos button {
  width: fit-content;
}

.reservation-paiement article {
  position: fixed;
  transition: 0.3s;
  right: 180px;
  top: 65px;
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: var(--primary-50);
  row-gap: 24px;
  padding: 16px 24px 32px 24px;
  width: 413px;
  height: auto;
  z-index: 0;
}
.modal-choosePaiement {
  padding-bottom: 58px;
  row-gap: 8px;
}
.modal-choosePaiement .paiement-method {
  padding: 8px 2px;
  background: var(--gray-200);
  border-radius: 8px;
}
.modal-choosePaiement .paiement-method:hover {
  cursor: pointer;
}
.modal-choosePaiement .paiement-method.selected {
  background: var(--primary-200);
}
.modal-choosePaiement .paiement-method label {
  width: 100%;
  display: flex;
  column-gap: 16px;
  justify-content: flex-start;
  align-items: center;
}
.modal-choosePaiement .paiement-method label span {
  font-style: 14px;
  font-weight: 600;
}
.modal-choosePaiement .paiement-method label img {
  height: 25px;
  width: auto;
  object-fit: contain;
  width: 40px;
  padding-left: 4px;
  border-radius: 6px;
  background: var(--gray-500);
}
.price-recap .txt-normal {
  color: var(--gray-500);
  font-size: 14px;
  text-decoration: line-through;
  font-weight: 400;
  text-decoration-line: strikethrough;
}
.price-recap .discount-txt {
  color: var(--shade-white);
  background: var(--destructive-500);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  padding: 2px 5px;
}
.price-recap .amount-txt {
  color: var(--gray-800);
  font-size: 14px;
  font-weight: 600;
}
@media screen and (min-width: 1440px) {
  .reservation-paiement article {
    position: fixed;
    right: 50%;
    top: 65px;
    transform: translateX(550px);
  }
}
@media screen and (max-width: 1300px) {
  .reservation-paiement article {
    right: 80px;
  }
}
@media screen and (max-width: 1170px) {
  .reservation-paiement article {
    width: 350px;
  }
  .reservation-paiement .full-panel > div {
    width: 484px;
  }
  .reservation-paiement .paiement-recap img {
    height: 22px;
    width: auto;
    object-fit: contain;
  }
}
@media screen and (max-width: 1050px) {
  .reservation-paiement article {
    width: 340px;
    right: 32px;
  }
  .reservation-paiement .full-panel {
    padding-left: 32px;
  }
}
@media screen and (max-width: 900px) {
  .reservation-paiement .full-panel > div {
    width: calc(57%);
  }
  .reservation-paiement .recap label {
    font-size: 32px;
    white-space: normal;
    width: 100%;
  }
}
@media screen and (max-height: 551px) {
  .reservation-paiement article {
    position: absolute;
  }
}
@media screen and (max-width: 775px) {
  .price-recap .amount-txt {
    font-size: 16px;
  }
  .reservation-paiement .second-title-show {
    top: 82px;
  }
  .reservation-paiement .full-panel {
    padding-left: 16px;
  }
  .reservation-paiement article {
    right: 16px;
    width: 44%;
    padding-bottom: 16px;
  }
  .reservation-paiement article .residence-part {
    flex-direction: column;
    row-gap: 16px;
  }
  .reservation-paiement article .residence-part .right-part {
    row-gap: 2px !important;
  }
  .reservation-paiement article .residence-part img {
    width: 100% !important;
    height: auto;
    object-fit: cover;
  }
  .reservation-paiement article .residence-part {
    padding-top: 0 !important;
  }
  .reservation-paiement article .price-recap {
    flex-wrap: wrap;
  }

  .reservation-paiement .recap-paiement .total-price div p {
    font-size: 18px;
  }
  .reservation-paiement .cancel-condition label {
    white-space: normal;
  }
  .reservation-paiement .recap .date-one > span {
    white-space: normal;
  }
}
.reservation-paiement article .residence-part {
  padding: 16px 0;
  border-bottom: 1px solid var(--gray-200);
  column-gap: 16px;
}
.reservation-paiement article .residence-part img {
  border-radius: 6px;
  width: auto;
  aspect-ratio: 14/9;
  max-height: 93px;
}
.reservation-paiement article .residence-part .right-part {
  row-gap: 8px;
  flex: 1;
}
.reservation-paiement article .residence-part .right-part label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reservation-paiement article .residence-part .right-part .stars-part {
  column-gap: 8px;
}
.reservation-paiement article .residence-part .right-part .stars-part svg {
  width: 16px;
  height: 16px;
}
.reservation-paiement article .residence-part .right-part .stars-part span {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.reservation-paiement
  article
  .residence-part
  .right-part
  .stars-part
  .rating-part {
  column-gap: 4px;
}
.reservation-paiement article {
  row-gap: 24px;
  top: 130px;
}
.reservation-paiement article .price-recap,
.reservation-paiement article .price-frais {
  row-gap: 8px;
}
.reservation-paiement article label {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.reservation-paiement article p.info {
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 0;
}
.reservation-paiement article p.red-txt {
  color: var(--destructive-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.reservation-paiement article .total-price p:first-child {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.reservation-paiement article .total-price p:first-child {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.reservation-paiement article .total-price p:last-child {
  color: var(--success-500);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.reservation-paiement section .reservation-panel-bottom {
  top: auto;
  bottom: 0;
  height: 397px;
}

.reservation-paiement section .facturation {
  row-gap: 16px;
}

.reservation-paiement section .facturation label {
  color: var(--shade-black);
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.request-conf {
  width: 100% !important;
}
.request-conf .modal-contains {
  width: 320px;
  height: fit-content;
  min-height: auto !important;
}
.request-conf .modal-contains p {
  color: var(--gray-700);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  margin: 0;
}
@media screen and (max-width: 690px) {
  .reservation-paiement .recap-paiement {
    display: flex;
  }
  .reservation-paiement .full-panel {
    padding-left: 32px;
    padding-right: 32px;
    margin-top: 70px;
  }
  .reservation-paiement .full-panel > div {
    width: 100%;
  }
  .reservation-paiement article {
    display: none;
  }
  .reservation-paiement .recap label {
    font-size: 24px;
    white-space: normal;
    width: 100%;
    line-height: 32px;
  }
  .reservation-paiement .recap > label,
  .reservation-paiement .paiement-recap label,
  .reservation-paiement .cancel-condition label {
    font-size: 28px;
  }
  section .info-reglement-warning {
    padding: 8px;
  }
  .reservation-paiement .recap .date-one > label,
  .reservation-paiement .recap-paiement > div label {
    font-size: 20px;
  }
  .reservation-paiement .cancel-condition + div {
    padding-top: 0px;
  }
  .modal-quit .modal-contains .quit-check p {
    font-size: 16px;
  }
}
@media screen and (max-width: 550px) {
  .reservation-paiement .full-panel {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width: 450px) {
  .paiement-recap .first-step .input-select-perso {
    width: 100% !important;
  }
  .paiement-recap .first-step :is(.email-billing) {
    border-left: 1px solid var(--gray-600);
  }
  .modal-content .modal-contains .title-put-top {
    font-size: 18px;
  }
  .modal-quit .modal-contains .quit-check button {
    border-radius: 6px !important;
  }
}
@media screen and (max-width: 420px) {
  .price-recap .tooltip-recap {
    transform: translateY(5%);
  }
}
