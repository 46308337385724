header {
  width: 100vw;
  height: auto;
  z-index: 5;
  position: fixed;
  left: 0;
  top: 0;
  background: var(--shade-white, #fff);
  transition: transform 0.3s;
}
.shadow-box-active {
  transition: 0.3s !important;
  border-bottom: 1px solid var(--gray-100, #f3f4f6);
  box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.2),
    0px 18px 18px 0px rgba(255, 255, 255, 0.17),
    0px 41px 25px 0px rgba(255, 255, 255, 0.1),
    0px 74px 29px 0px rgba(255, 255, 255, 0.03),
    0px 115px 32px 0px rgba(255, 255, 255, 0);
}
.border-bot-force {
  transition: 0.3s !important;
  /* border-bottom: 1px solid var(--gray-100, #f3f4f6); */
}
.shadow-box-active-hote {
  border-bottom: 1px solid var(--gray-200, #f3f4f6) !important;
  box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.2),
    0px 18px 18px 0px rgba(255, 255, 255, 0.17),
    0px 41px 25px 0px rgba(255, 255, 255, 0.1),
    0px 74px 29px 0px rgba(255, 255, 255, 0.03),
    0px 115px 32px 0px rgba(255, 255, 255, 0);
}
.desactive-header {
  transform: translateY(-100%);
}

.header-replace {
  /* height: 125px; */
  height: 85px;
  width: 100%;
  transition: height 0.3s;
}

.desactive-header-replace {
  height: 0 !important;
}
header .hd-container {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 20px;
  column-gap: 10px;
}

header .hd-search-bar {
  height: 48px;
  width: 500px;
  justify-content: center;
  border-radius: 1000px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
}

header .hd-right-btn .devise-language {
  padding: 0;
}
header .hd-right-btn .devise-language img {
  width: 24px;
  height: 24px;
}

header .hd-search-bar hr {
  border: none;
  border: 1px solid var(--gray-200);
  width: 1px;
  height: 20px;
  box-sizing: border-box;
  margin: 0;
}

.go-search {
  margin-left: 15.5px;
  margin-right: 6px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  background-color: var(--primary-500);
}
.show-filter {
  background-color: var(--shade-white);
  border: 1px solid var(--gray-200, #e5e7eb);
}

.go-search > svg {
  width: 16px;
  height: 16px;
}

header .hd-right-btn .menu-btn {
  display: none;
  border-radius: 100px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  padding: 8px;
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  column-gap: 8px;
}
header .hd-right-btn .menu-btn img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
header .hd-right-btn .menu-btn img:first-child {
  width: 24px;
  height: 24px;
}

header .hd-right-btn .menu-btn .profil-picture {
  display: none;
}
header .hd-right-btn .connected-hote {
  height: 60px;
}
header .hd-right-btn .connected-menu-btn {
  display: flex;
  padding: 14px 28px;
  height: 60px;
}
header .hd-right-btn .connected-menu-btn .profil-picture {
  display: block;
  background: var(--primary-50);
}
header .hd-right-btn .connected-menu-btn .notify {
  width: 16px;
  height: 16px;
  border-radius: 100px;
  border: 2px solid var(--shade-white);
  background: var(--destructive-500);
  position: absolute;
  right: 2px;
  top: 0px;
}

.menu-tc {
  position: fixed;
  z-index: 9;
  top: calc(100% + 8px);
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
}

.menu-tc .background-touch {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.menu-tc .menu-container {
  animation: testing-anim 0.3s linear forwards;
  position: absolute;
  opacity: 1;
  /* opacity: 1; */
  /* animation: fade-in-menu-testing 0.3s ease-in-out; */
  width: 253px;
  height: auto;
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  right: 80px;
  top: 95px;
  padding: 24px 16px;
  row-gap: 8px;
}
@keyframes testing-anim {
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@media screen and (min-width: 1440px) {
  .menu-tc .menu-container {
    right: 50% !important;
    transform: translateX(645px) !important;
  }
}

@keyframes fade-in-menu-testing {
  0% {
    opacity: 0;
    transform: translateY(-10px) translateX(645px);
  }
  100% {
    opacity: 1;
    transform: translateY(0) translateX(645px);
  }
}
.menu-tc .menu-container > div {
  padding-bottom: 16px;
  border-bottom: 1px solid var(--gray-200);
  row-gap: 8px;
  width: 100%;
  justify-content: flex-start;
}
.menu-tc .menu-container > div:last-child {
  padding-bottom: 0;
  border-bottom: none;
}
.menu-tc .menu-container button {
  width: 100%;
  padding: 6px 16px;
  padding-left: 0;
  justify-content: space-between;
}
.menu-tc .menu-container button:disabled {
  background: none !important;
  opacity: 0.5 !important;
}
.menu-tc .menu-container button:disabled span {
  color: var(--gray-600) !important;
  font-weight: 400 !important;
}
.menu-tc .menu-container button .indicator {
  width: 8px;
  height: 8px;
  border-radius: 100px;
  border: 2px solid var(--shade-white);
  background: var(--destructive-500);
}
.menu-tc .menu-container button span {
  text-align: left;
  font-size: 16px;
  font-style: normal;
  line-height: 24px;
  font-family: Inter;
  white-space: nowrap;
}
.menu-tc .menu-container .deco-btn span {
  color: var(--destructive-500);
  font-weight: 400;
}
.menu-tc .menu-container .menu-top button span {
  color: var(--shade-black);
  font-weight: 600;
}
.menu-tc .menu-container .menu-mid button span {
  color: var(--gray-600);
  font-weight: 400;
}
header .hd-search-bar .hd-inp .second-placeholder {
  display: none;
}
header .hd-right-btn .help-btn {
  background: var(--primary-50);
}
header .hd-right-btn .help-btn span {
  color: var(--primary-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
@media screen and (max-width: 1200px) {
  /* header .hd-search-bar {
    height: 48px;
    width: 380px;
  } */
  /* header .hd-search-bar .hd-inp:nth-of-type(1) {
    flex: 1.3 !important;
  }
  header .hd-search-bar .hd-inp:nth-of-type(4) {
    flex: 1.2 !important;
  } */
  header .hd-search-bar .scd-inp .first-placeholder {
    display: none;
  }
  header .hd-search-bar .scd-inp .second-placeholder {
    display: block;
  }
  header .hd-container {
    column-gap: 2px;
  }
}
/* @media screen and (max-width: 1150px) {
  header .hd-search-bar {
    height: 48px;
    width: 350px;
  }
} */
@media screen and (max-width: 1050px) {
  header .hd-container {
    padding-left: 32px;
    padding-right: 32px;
  }
  .menu-tc .menu-container {
    right: 32px;
  }
}
@media screen and (min-width: 950px) {
  /* .not-connected-menu {
    display: none;
  } */
}
@media screen and (max-width: 950px) {
  header .hd-search-bar {
    display: none;
  }
  header .hd-right-btn .connect-btn {
    display: none;
  }
  header .hd-right-btn .menu-btn {
    display: flex;
  }
  .header-replace {
    height: 100px;
  }
  header .hd-right-btn .connected-menu-btn {
    padding: 10px 20px;
    height: auto;
  }
  header .hd-right-btn .connected-hote {
    height: 54px;
  }
}
@media screen and (max-width: 610px) {
  header {
    /* border-bottom: 1px solid var(--gray-100, #f3f4f6); */
  }
  .menu-tc .menu-container button span {
    font-size: 14px;
    line-height: 20px;
  }
  .tc-btn {
    width: 123px;
    height: 27px;
  }
  header .hd-right-btn .lister-resi-btn {
    display: none;
  }
  .header-replace {
    height: 80px;
  }
  .menu-tc .menu-container {
    right: 16px;
  }
  header .hd-container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
@media screen and (max-width: 550px) {
  .hd-search-bar {
    column-gap: 46px;
    background: var(--shade-white);
  }
  .hd-search-bar .btn-mobile-select {
    flex: 1;
    flex-direction: column;
    padding: 8px 0;
    padding-right: 0;
    align-items: flex-start;
    row-gap: 0;
    border-top-left-radius: 1000px;
    border-bottom-left-radius: 1000px;
    max-width: 100%;
  }
  .hd-search-bar .btn-mobile-select:focus,
  .hd-search-bar .btn-mobile-select:focus-visible,
  .hd-search-bar .btn-mobile-select:focus-within {
    background: none;
  }
  .hd-search-bar .btn-mobile-select label {
    color: var(--shade-black);
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    max-width: 70%;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .hd-search-bar .btn-mobile-select .second-info {
    width: 100%;
  }
  .hd-search-bar .btn-mobile-select .second-info p,
  .hd-search-bar .btn-mobile-select .second-info span {
    color: var(--gray-400);
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: calc(100%);
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .hd-search-bar .btn-mobile-select .second-info .date-select {
    width: fit-content;
    max-width: 22%;
  }
  .hd-search-bar .btn-mobile-select .second-info .others-select {
    max-width: 70%;
  }
  .hd-search-bar .btn-mobile-select .second-info span {
    margin: 0 6px;
  }
}

@media screen and (max-width: 415px) {
  .hd-search-bar .btn-mobile-select .second-info .date-select {
    width: fit-content;
    max-width: 80px;
  }

  .hd-search-bar {
    column-gap: 26px;
  }
}

@media screen and (max-width: 350px) {
  .hd-search-bar .btn-mobile-select .second-info .date-select {
    width: 50px;
  }
  .hd-search-bar .btn-mobile-select .second-info .others-select {
    width: 65px;
  }
  .hd-search-bar {
    column-gap: 10px;
  }
}

/*HEADER SEARCH THINHS*/

header .hd-search-bar .input-location {
  flex: 1.1;
  transition: transform 0.2s;
}
header .hd-search-bar .input-location.focus {
  transform: translateY(9px);
}
header .hd-search-bar .input label {
  transform: translateY(-50%);
  left: 16px;
  top: 50%;
  color: var(--shade-black);
  position: absolute;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: calc(100% - 20px);
  pointer-events: none;
  transition: transform 0.2s;
}
header .hd-search-bar .input label.on-top {
  /* font-size: 12px; */
  transform: translateY(-30px);
}
header .hd-search-bar .input label.on-top.no-label {
  transition: 0.1s;
  opacity: 0;
}
header .hd-search-bar .input-location.focus.no-label {
  transform: translateY(0);
}
header .hd-search-bar .input-location.focus.no-label input {
  padding-top: 4.5px;
}
header .hd-search-bar .input input {
  border: none;
  border-radius: 1000px;
  padding: 4px;
  padding-left: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  font-style: normal;
  padding-top: 6.5px;
  line-height: 20px;
  width: 100%;
}
