.verify-email-section {
  row-gap: 24px;
}
.verify-email-section > h2 {
  font-family: Mori Gothic;
}
.modal-error-container .modal-contains {
}
.modal-container-verify .modal-contains {
  justify-content: center;
  align-items: center;
  min-height: auto;
  height: fit-content;
  padding-bottom: 30px;
}
.modal-container-verify .modal-contains .modal-check img {
  width: 70px;
  height: 70px;
}
.modal-container-verify .modal-contains .modal-check p {
  max-width: 300px;
}

@media screen and (max-width: 550px) {
  .modal-container-verify .modal-contains .modal-check p {
    font-size: 17px;
  }
}
@media screen and (max-width: 375px) {
  .verify-email-section > h2 {
    font-size: 18px;
  }
  .modal-container-verify .modal-contains {
    padding-top: 40px;
  }
  .modal-container-verify .modal-contains .modal-check img {
    width: 45px;
    height: 45px;
  }
}
