.react-datepicker {
  display: flex;
  flex-direction: row;
  z-index: 3;
}

.react-datepicker .react-datepicker__header {
  background: none;
  border: none;
  padding-top: 20px;
}

.react-datepicker .react-datepicker__header .react-datepicker__current-month {
  padding-bottom: 36px;
  color: var(--shade-black, #1e1a21);
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

.react-datepicker .day-date-picker,
.react-datepicker .react-datepicker__day-name {
  color: var(--shade-black);
  padding: 14px;
  box-sizing: border-box;
  margin: 0px;
  border-radius: 0px;
  width: 56px;
  height: 56px;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid transparent;
}

.react-datepicker .react-datepicker__day-name {
  color: var(--shade-black, #1e1a21);
  border-radius: 50% !important;
}
.react-datepicker .day-date-picker {
  border-radius: 50% !important;
}
.react-datepicker {
  box-sizing: border-box;
  padding: 40px;
  column-gap: 40px;
  border-radius: 16px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);

  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
}

.react-datepicker .react-datepicker__day--in-selecting-range {
  background: var(--gray-200, #e5e7eb);
  border-radius: 50% !important;
}
.react-datepicker__day--keyboard-selected {
  background: none !important;
}
.react-datepicker .react-datepicker__day--range-start,
/* .react-datepicker__day--selected, */
/* .react-datepicker__day--keyboard-selected, */
.react-datepicker .react-datepicker__day--range-end {
  background: var(--primary-500) !important;
  stroke-width: 1px;
  color: var(--shade-white) !important;
  border: 1px solid var(--primary-300);
  border-radius: 50% !important;
}
.react-datepicker .exclude-date {
  color: var(--shade-black) !important;
}
.react-datepicker .day-date-picker:hover {
  background: none !important;
  color: var(--gray-500, #6b7280) !important;
  stroke-width: 1px;
  border: 1px solid var(--primary-300);
  border-radius: 50%;
}

.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
  top: 60px;
}

.react-datepicker__navigation--previous {
  left: 50px;
}
.react-datepicker__navigation--next {
  right: 50px;
}
.react-datepicker__navigation-icon::before {
  border-color: var(--shade-black);
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 110px;
  padding: 0;
  inset: 0px auto auto 0px !important;
  opacity: 1 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 5;
  animation: showMe 0.15s forwards;
}
@keyframes showMe {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
/* .react-datepicker-popper {
  z-index: 5;
}
.react-datepicker-popper div {
  z-index: 5;
} */
.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 110px;
  padding: 0;
  /* inset: auto auto 0px 0px !important; */
  left: 50% !important;
  transform: translateX(-50%) !important;
  opacity: 1 !important;
  z-index: 5;
  animation: showMe 0.15s forwards;
}
.react-datepicker__day--outside-month {
  height: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  border: none !important;
}
.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  flex: 1;
}
.custom-date-input {
  width: 100%;
  text-align: left;
}
.react-datepicker .react-datepicker__day--outside-month {
  color: transparent !important;
  pointer-events: none !important;
  background: none !important;
}
/*HEADER STORE*/
.date-header .react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 64px;
  padding: 0;
  inset: 0px auto auto 0px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  z-index: 5;
  animation: showMe 0.15s forwards;
}

.date-header .second-search-bar {
  width: 100%;
}
.date-header .react-datepicker .react-datepicker__header {
  padding-top: 10px;
}

.date-header
  .react-datepicker
  .react-datepicker__header
  .react-datepicker__current-month {
  padding-bottom: 18px;
  font-size: 14px;
  line-height: 24px;
}

.date-header .react-datepicker .day-date-picker,
.date-header .react-datepicker .react-datepicker__day-name {
  padding: 6px;
  width: 33px;
  height: 33px;
  font-size: 16px;
}

.date-header .react-datepicker {
  padding: 20px;
  width: fit-content;
}
.date-header .react-datepicker__navigation--next,
.date-header .react-datepicker__navigation--previous {
  top: 27.5px;
}

.date-header .react-datepicker__navigation--previous {
  left: 30px;
  transform: scale(0.8);
}
.date-header .react-datepicker__navigation--next {
  right: 30px;
  transform: scale(0.8);
}

@media screen and (max-width: 980px) {
  .hero-section .second-search-bar {
    width: 100%;
  }
  .react-datepicker .react-datepicker__header {
    padding-top: 10px;
  }

  .react-datepicker .react-datepicker__header .react-datepicker__current-month {
    padding-bottom: 18px;
    font-size: 14px;
    line-height: 24px;
  }

  .react-datepicker .day-date-picker,
  .react-datepicker .react-datepicker__day-name {
    padding: 6px;
    width: 33px;
    height: 33px;
    font-size: 16px;
  }

  .react-datepicker {
    padding: 20px;
  }
  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    top: 27.5px;
  }

  .react-datepicker__navigation--previous {
    left: 30px;
    transform: scale(0.8);
  }
  .react-datepicker__navigation--next {
    right: 30px;
    transform: scale(0.8);
  }
}
@media screen and (max-height: 780px) {
  .react-datepicker .react-datepicker__header {
    padding-top: 10px;
  }

  .react-datepicker .react-datepicker__header .react-datepicker__current-month {
    padding-bottom: 18px;
    font-size: 14px;
    line-height: 24px;
  }

  .react-datepicker .day-date-picker,
  .react-datepicker .react-datepicker__day-name {
    padding: 6px;
    width: 33px;
    height: 33px;
    font-size: 16px;
  }

  .react-datepicker {
    padding: 20px;
  }
  .react-datepicker__navigation--next,
  .react-datepicker__navigation--previous {
    top: 27.5px;
  }

  .react-datepicker__navigation--previous {
    left: 30px;
    transform: scale(0.8);
  }
  .react-datepicker__navigation--next {
    right: 30px;
    transform: scale(0.8);
  }
}
/* ecrire au dessus dans un content arrivée */
.react-datepicker .react-datepicker__day--range-start,
.react-datepicker .react-datepicker__day--range-end,
.excludedate-start,
.excludedate-end {
  position: relative;
}
.react-datepicker .exclude-date {
  position: relative;
}

.react-datepicker .react-datepicker__day--range-start::after,
.react-datepicker .react-datepicker__day--range-end::after,
.react-datepicker .excludedate-start::after,
.react-datepicker .exclude-date::after,
.react-datepicker .exclude-date::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  color: var(--shade-white);
  font-family: Mori Gothic;
  font-size: 6px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  background: var(--shade-black);
  border-radius: 4px;
  padding: 0 2px;
}
.react-datepicker .react-datepicker__day--range-start::after,
.react-datepicker .excludedate-start::after {
  content: "Arrivée";
}
.when-travel
  .period-selector-content
  .react-datepicker
  .react-datepicker__day--range-start::after,
.when-travel .period-selector-content .react-datepicker__day--range-end::after {
  content: "début";
}
.react-datepicker.en .react-datepicker__day--range-start::after,
.react-datepicker.en .excludedate-start::after {
  content: "Arrival";
}
.en .react-datepicker .react-datepicker__day--range-start::after,
.en .react-datepicker .excludedate-start::after {
  content: "Arrival";
}

.react-datepicker .excludedate-start {
  user-select: none;
}
.react-datepicker .react-datepicker__day--range-end::after {
  content: "Départ";
}
.when-travel .react-datepicker .react-datepicker__day--range-end::after {
  content: "fin";
}
.react-datepicker.en .react-datepicker__day--range-end::after {
  content: "Departure";
}
.en .react-datepicker .react-datepicker__day--range-end::after {
  content: "Departure";
}
.react-datepicker .react-datepicker__day--outside-month::after {
  content: "";
}
.react-datepicker .exclude-date::after {
  background: var(--gray-100);
  color: var(--shade-black);
  content: "";
}
@media screen and (max-width: 600px) {
  .btn-cancel-period {
    display: none;
  }
}
.react-datepicker .exclude-date {
  background: var(--gray-500) !important;
  opacity: 1 !important;
  color: var(--shade-white) !important;
  text-decoration: line-through;
}
.react-datepicker .react-datepicker__day--outside-month.exclude-date {
  background: none;
}
.react-datepicker .exclude-date:hover::before {
  content: "Résidence indisponible pour cette date";
  font-size: 11px;
  font-weight: 600;
  font-family: Inter;
  padding: 4px;
  position: fixed;
  max-width: calc(100% - 32px);
  white-space: normal;
  text-transform: none;
  opacity: 1;
  animation: flash-me-no-dispo 1.5s linear infinite;
}
@media screen and (max-width: 550px) {
  .react-datepicker .exclude-date:hover::before {
    top: 245px;
  }
}
@keyframes flash-me-no-dispo {
  0%,
  100% {
    background: #000000;
  }
  50% {
    background: #1f232d;
  }
}
.react-datepicker .react-datepicker__day--outside-month.exclude-date::after {
  content: "";
}
.date-picker-opened-start.react-datepicker__day--range-start {
  animation: flash-me-tr 1s linear infinite !important;
}
.date-picker-opened-end.react-datepicker__day--range-end {
  animation: flash-me-tr 1s linear infinite !important;
}

@keyframes flash-me-tr {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}
