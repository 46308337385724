@media screen and (max-width: 950px) {
  .residence-page .new-commentary-contains {
    width: 100%;
    row-gap: 24px;
  }
  .residence-page .stars-selector .others-note-div .others-note {
    row-gap: 16px;
    flex-direction: column;
  }
  .residence-page .others-note-div .others-note .card-note {
    width: calc(100%);
    border-radius: 16px;
    border: 1px solid var(--gray-300);
    background: var(--shade-white);
    padding: 8px 16px 16px 16px;
    row-gap: 16px;
    justify-content: center;
  }
  .new-commentary-contains .stars-selector .others-note .card-note .stars {
    justify-content: center;
    column-gap: 40px !important;
  }
}
@media screen and (max-width: 600px) {
  .new-commentary-contains .stars-selector .others-note .card-note .stars {
    justify-content: space-between;
    column-gap: 5px !important;
  }
  .residence-page footer {
    display: none;
  }
  .residence-page .contains-hero-residence {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .residence-page .contains-hero-residence > img {
    display: none !important;
  }
  .residence-page .contains-hero-residence > div {
    display: none;
  }
  .residence-page .contains-hero-residence > img:nth-of-type(1),
  .residence-page .contains-hero-residence > .skeleton:nth-of-type(1) {
    display: block !important;
    aspect-ratio: 4/3;
  }
  .residence-page .contains-hero-residence > .skeleton:nth-of-type(1) {
    transform: translateY(0) !important;
  }
  .residence-page .contains-hero-residence > div:nth-of-type(1) {
    transform: translateY(10px);
  }
  .residence-page .galerie-residence .header-galerie-residence {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .residence-page .galerie-residence .header-galerie-residence {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .residence-page .galerie-residence {
    row-gap: 16px;
  }
  .residence-page .galerie-residence .one-row {
    flex-direction: column;
    row-gap: 16px;
    width: 100%;
    height: auto !important;
    height: 516px !important;
  }
  .residence-page .galerie-residence .one-row.only-row {
    height: 250px !important;
  }
  .residence-page .galerie-residence .one-row > div {
    flex: 0 !important;
    width: 100%;
    height: 250px !important;
  }
  .residence-page .others-actions-btn {
    column-gap: 16px;
  }
  .residence-page .others-actions-btn button {
    padding: 12px;
  }
  .residence-page .others-actions-btn button span {
    display: none;
  }
  .residence-page .others-actions-btn button svg {
    width: 20px;
    height: 20px;
  }
  .residence-page .title-residence-share .actions-btn {
    display: none;
  }

  .residence-page .contains-hero-residence .actions-btn {
    position: absolute;
    z-index: 1;
    right: 8px;
    top: 0px;
    display: flex !important;
  }
  .residence-page .contains-hero-residence .actions-btn button {
    background: var(--shade-white);
    border-radius: 50%;
    padding: 8px;
  }
  .residence-page .title-residence-share .resi-info h2 {
    color: var(--shade-black);
    font-family: Mori Gothic;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: -0.48px;
  }
  .residence-page .title-residence-share.fixed-to-header {
    left: 50%;
    position: fixed;
    top: 66px;

    max-width: 1440px;
    width: 100%;
    padding: 10px 16px;
    padding-top: 24px;
  }
  .residence-page .main-residence-section article {
    display: none;
  }
  .residence-page .main-residence-section .contains-main-residence {
    padding-right: 16px;
  }
  .residence-page .contains-main-residence .hote-litle-info > img {
    width: 48px;
    height: 48px;
  }
  .residence-page
    .contains-main-residence
    .hote-litle-info
    > .name-hote-info
    > span {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .residence-page
    .contains-main-residence
    .hote-litle-info
    > .name-hote-info
    > h2 {
    color: var(--shade-black);
    font-family: Mori Gothic;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
  .residence-page
    .contains-main-residence
    .hote-litle-info
    > .name-hote-info
    > p {
    color: var(--shade-black);

    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .residence-page .contains-main-residence > div label {
    overflow: hidden;
    color: var(--shade-black, #1e1a21);
    text-overflow: ellipsis;
    font-family: Mori Gothic;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
  }
  .residence-page {
    color: var(--shade-black, #1e1a21);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
  .residence-page
    .contains-main-residence
    .comodities-residence
    .comodities-contains,
  .comodities-modal .contents {
    flex-direction: column;
    row-gap: 16px;
  }
  .residence-page
    .contains-main-residence
    .comodities-residence
    .show-all-commodities {
    width: 100%;
  }
  .residence-page .comodities-modal .contents {
    row-gap: 16px;
  }

  .residence-page .contains-main-residence .apercu-residence .contains-apercu {
    flex-direction: column;
    row-gap: 16px;
  }
  .residence-page .apercu-residence .contains-apercu .apercu-part {
    width: 100%;
  }
  .residence-page .commentary-residence .contains-commentaires {
    flex-wrap: nowrap;
    overflow-x: scroll;
    column-gap: 10px;
  }
  .residence-page
    .commentary-residence
    .contains-commentaires::-webkit-scrollbar {
    opacity: 0;
    width: 0px;
  }
  .residence-page .commentary-residence .contains-commentaires .commentary,
  .contents-commentary .commentary-list .commentary {
    min-height: 200px;
    padding: 20px 16px;
    width: calc(87%);
    flex-shrink: 0;
  }
  .residence-page .main-residence-section .commentary-residence {
    row-gap: 24px;
    padding: 32px 0;
  }
  .residence-page .main-residence-section .commentary-residence .title button {
    display: none;
  }
  .residence-page .main-residence-section .commentary-residence .title {
    flex-wrap: wrap;
    column-gap: 5px;
    row-gap: 16px;
  }
  .residence-page
    .main-residence-section
    .commentary-residence
    .title
    .btn-no-commentary {
    display: block;
  }
  .residence-page .main-residence-section .commentary-residence .title label {
    color: var(--shade-black);
    text-overflow: ellipsis;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
  }
  .residence-page
    .main-residence-section
    .commentary-residence
    .title-comment
    svg {
    display: none;
  }
  .residence-page .contains-commentaires .commentary .comment,
  .contents-commentary .commentary-list .commentary .comment {
    font-size: 16px !important;
    line-height: 24px !important;
  }
  .residence-page .cancel-condition-residence .list-condition {
    width: 100%;
    row-gap: 4px;
  }
  .residence-page .cancel-condition-residence .list-condition > li {
    flex-direction: column;
  }
  .residence-page .main-residence-section .logement-residence {
    padding: 32px 0;
    row-gap: 24px;
  }
  .residence-page .main-residence-section .logement-residence label {
    overflow: hidden;
    color: var(--shade-black, #1e1a21);
    text-overflow: ellipsis;
    font-family: Mori Gothic;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
  .residence-page .main-residence-section .logement-residence .map-show {
    width: 100%;
    height: auto;
    background: var(--gray-200);
    user-select: none;
    pointer-events: none;
    border-radius: 16px;
  }
  .main-residence-section .logement-residence .map-show {
    /* width: 363px; */
    width: 100%;
    aspect-ratio: 363/231.943 !important;
    /* height: 231.943px !important; */
  }
  .residence-page .contents-commentary {
    width: 100%;
    column-gap: 40px;
    flex-direction: column;
    row-gap: 20px;
    overflow-y: hidden;
  }
  .residence-page .contents-commentary .commentary-list {
    height: auto;
    max-height: none;
    padding-bottom: 10px !important;
  }
  .modal-content .modal-contains .content.bottom-child {
    /* overflow-y: hidden; */
  }
  .residence-page .commentary-residence .contains-commentaires .commentary,
  .contents-commentary .commentary-list .commentary {
    padding: 20px 16px !important;
    width: 100%;
    row-gap: 15px;
    justify-content: space-between;
    height: fit-content;
    min-height: auto;
  }
  .residence-page .commentary-residence .contains-commentaires .commentary {
    height: auto;
    min-height: max-content;
  }
  .contains-commentaires .commentary .comment-person,
  .contents-commentary .commentary-list .commentary .comment-person {
    transform: translateX(3px);
    column-gap: 10px !important;
  }
  .residence-page .contains-commentaires .commentary .comment-person img,
  .residence-page
    .contents-commentary
    .commentary-list
    .commentary
    .comment-person
    img {
    width: 48px;
    height: 48px;
  }
  .contents-commentary .bar-state > div {
    width: 100% !important;
  }
  .residence-page .contents-commentary .commentary-recap .title label {
    color: var(--shade-black);
    font-family: Mori Gothic;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: -0.4px;
  }
  .residence-page .contents-commentary .bar-state > div > span {
    font-size: 12px;
  }
  .residence-page
    .contains-commentaires
    .commentary
    .comment-person
    .title-pers
    span,
  .residence-page
    .contents-commentary
    .commentary-list
    .commentary
    .comment-person
    .title-pers
    span {
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
  .residence-page
    .contents-commentary
    .commentary-list
    .commentary
    .comment-person
    .title-pers {
    row-gap: 0;
  }
  .residence-page .residence-view > .info-residence-view > .label-title {
    white-space: normal;
    font-size: 14px;
    line-height: 20px;
    width: calc(100% - 5px);
    -webkit-line-clamp: 2;
  }
}

@media screen and (max-width: 385px) {
  .residence-page
    .main-residence-section
    .commentary-residence
    .title
    .btn-no-commentary {
    margin: 0 auto;
  }
}
@media screen and (max-width: 360px) {
  .residence-page .contents-commentary .bar-state > div {
    flex-direction: column;
  }
  .residence-page .contents-commentary .bar-state > div > span {
    font-size: 14px;
  }
}
