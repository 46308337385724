.card-resi-content {
  width: 413px;
  height: 472px;
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  border: 2px solid transparent;
  border-radius: 16px;
  overflow: hidden;
  transition: border 0.3s ease;
}

.card-resi,
.card-resi-content {
  text-decoration: none !important;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: var(--gray-50);
}
.card-resi-content:hover {
  text-decoration: none;
}

.card-resi-content.anim-me-card {
  /* opacity: 0 !important; */
  border: 2px solid var(--primary-500, #a273ff) !important;
}
@keyframes border-primary {
  0% {
    border: 2px solid transparent;
  }
  70% {
    border: 2px solid var(--primary-500, #a273ff) !important;
  }
  100% {
    border: 2px solid transparent;
  }
}

.card-resi-content:hover {
  border: 2px solid var(--primary-500, #a273ff) !important;
  opacity: 1 !important;
}
@media screen and (max-width: 651px) {
  .card-resi:hover {
    opacity: 1 !important;
  }
  /* .card-resi:hover {
    opacity: 1 !important;
  }
  .card-resi-content:hover {
    border: 2px solid var(--primary-500, #a273ff) !important;
    opacity: 1 !important;
  } */
}
.card-resi .top-card {
  width: 100%;
  height: 320px;
  /* flex: 1; */
}
.card-resi .top-card span img {
  width: 100%;
  height: 320px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* flex: 1; */
}
.btn-fav {
  position: absolute;
  /* z-index: 2; */
  top: 16px;
  right: 18px;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 8px;
  background: var(--shade-white);
}

.btn-fav svg {
  width: 100%;
  height: 100%;
}

.card-resi .top-card > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-resi .bot-val {
  align-items: flex-start;
  padding: 24px;
  padding-bottom: 40px;
  width: 100%;
}
.card-resi .bot-val > label {
  color: var(--primary-500);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100%);
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-resi .bot-val > h3 {
  color: var(--shade-black);
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100%);
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-resi .bot-val > .price-note {
  width: 100%;
  justify-content: flex-start;
}
.card-resi .bot-val > .price-note > span {
  color: var(--gray-600);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  white-space: nowrap;
}
.card-resi .bot-val > .price-note > hr,
.card-resi .bot-val > .price-note > .separ {
  width: 2px;
  height: 16px;
  background: var(--gray-500);
  opacity: 0.6;
  margin: 0;
  border: none;
}
.card-resi .bot-val > .price-note .star-resi {
  width: 100%;
  justify-content: flex-start;
}
.card-resi .bot-val > .price-note .star-resi {
  column-gap: 4px;
}
.card-resi .bot-val > .price-note .star-resi > span {
  color: var(--shade-black);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
  font-weight: 400;
}
.card-resi .bot-val > .price-note .star-resi > svg {
  color: var(--shade-black);
  width: 16px;
  height: 16px;
}

.card-resi .bot-val .txt-reduc {
  text-wrap: nowrap !important;
  font-size: 16px;
  color: var(--destructive-500);
  /* Paragraph/Medium/semibold */
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.card-resi .bot-val .txt-normal {
  text-wrap: nowrap !important;
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: line-through;
  text-decoration-line: strikethrough;
}
.card-resi .discount-txt {
  text-wrap: nowrap !important;
  color: var(--shade-white);
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 800;
  line-height: 32px; /* 133.333% */
  letter-spacing: -0.48px;
  background: var(--destructive-400);
  position: absolute;
  left: 16px;
  bottom: 16px;
}
@media screen and (max-width: 2080px) {
  .card-resi .bot-val .txt-reduc {
    font-size: 14px;
    line-height: normal;
  }
  .card-resi .bot-val .txt-normal {
    font-size: 13px;
  }
  .card-resi .discount-txt {
    font-size: 16px;
    line-height: normal;
    padding: 6px 4px;
    letter-spacing: -0.65px;
  }
}
@media screen and (max-width: 1050px) {
  .card-resi .bot-val > .price-note .star-resi > span {
    color: var(--shade-black);
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }
}
@media screen and (max-width: 450px) {
  .card-resi {
    border-radius: 12px;
  }
  .card-resi .bot-val .txt-reduc {
    font-size: 12px;
  }
  .card-resi .bot-val .txt-normal {
    font-size: 16px;
  }
  .card-resi .bot-val .txt-normal {
    font-size: 12px;
  }
  .card-resi .bot-val {
    padding: 16px 16px 24px 16px;
    row-gap: 5.5px;
  }
  .card-resi .bot-val > h3 {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .card-resi .bot-val > .price-note > span {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .card-resi .bot-val > .price-note .star-resi > span {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
  .card-resi .bot-val > .price-note .star-resi > svg {
    width: 12px;
    height: 12px;
  }
}
