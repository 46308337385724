.add-residence-next-footer {
  position: fixed;
  width: 100%;
  height: auto;
  bottom: 0;
  transform-origin: bottom;
  right: 0;
  padding: 16px 0;
  z-index: 1;
}

.add-residence-next-footer .contains-next-footer {
  padding-left: 0;
}

.add-residence-next-footer .contains-next-footer .btns-part .contains-btns {
  padding-left: 10.72%;
}

.contains-next-footer .espace {
  height: 0;
}
.contains-next-footer .btns-part .contains-btns .back-btn{
  background: none !important;
}
.contains-next-footer .btns-part .contains-btns .back-btn > span {
  color: var(--primary-500);
}
.contains-next-footer .btns-part .contains-btns .next-btn.block-right {
  /* margin-left: auto; */
}
.contains-next-footer .btns-part .contains-btns button {
  animation: show-me-slowly-add 0.2s ease-out;
}
@keyframes show-me-slowly-add {
  from {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.contains-next-footer .btns-part .contains-btns .next-btn > span {
  font-weight: 500;
}

.contains-next-footer .right-part-add-resi .bars-load {
  width: calc(100% + 80px);
  padding-bottom: 24px;
  height: auto;
  column-gap: 8px;
}

.right-part-add-resi .bars-load .bar {
  height: 6px;
  width: calc(100% / 3);
  height: 6px;
  background: var(--gray-200);
  transition: 0.3s;
  overflow: hidden;
}

.right-part-add-resi .bars-load .bar .contain {
  left: 0;
  top: 0;
  width: 0px;
  height: 100%;
  position: absolute;
  background: var(--SUPPORT-1);
  transition: 0.3s;
}
/* .right-part-add-resi .bars-load .bar:nth-of-type(2) .contain {
  transition-delay: 0.15s;
}
.right-part-add-resi .bars-load .bar:nth-of-type(3) .contain {
  transition-delay: 0.15s;
} */
