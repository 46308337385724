.policy-section {
}
.policy-section h1 {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 48px;
  letter-spacing: -0.8px;
}
.policy-section h2 {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: 44px;
  letter-spacing: -0.72px;
}
.policy-section h3 {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;
  margin-right: 6px;
  display: inline;
  text-align: justify;
}
.policy-section .title {
  row-gap: 24px;
}

.policy-section .content {
  max-width: 831px;
  row-gap: 16px;
}

.policy-section .title label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.8px;
}
.policy-section p,
.policy-section li {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
}
.policy-section a {
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-align: justify;
}
.policy-section li {
  margin-left: 40px;
  list-style: disc;
}
.policy-section .article {
  row-gap: 16px;
}
.policy-section .article label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 44px;
  letter-spacing: -0.72px;
}

.policy-section .second {
  position: fixed;
  top: 72px;
  width: 100vw;
  height: auto;
  padding-top: 20px;
  padding-bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  background: rgb(255, 255, 255, 0.7);
  backdrop-filter: blur(3px);
  animation: show-header-page-slow 0.2s linear forwards;
}

@keyframes show-header-page-slow {
  from {
    transform: translateX(-50%) translateY(-10px);
    opacity: 0;
  }
  to {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
  }
}

@media screen and (max-width: 950px) {
  .policy-section .title label {
    margin-top: 10px;
    font-size: 28px;
    line-height: 36px;
  }
}
