.main-residence-section .commentary-residence {
  row-gap: 48px;
  padding: 64px 0;
}
.main-residence-section .title-comment svg {
  width: 24px;
  height: 24px;
}

.main-residence-section .title-comment label {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: -0.56px;
}
.commentary-residence .contains-commentaires {
  width: 100%;
  column-gap: 20px;
}
.commentary-residence .contains-commentaires .commentary,
.contents-commentary .commentary-list .commentary {
  border-radius: 30px;
  border: 1px solid var(--gray-300);
  background: var(--shade-white);
  row-gap: 20px;
  padding: 20px 32px;
  width: calc(33% - 15px);
}

.contains-commentaires .commentary .comment,
.contents-commentary .commentary-list .commentary .comment {
  color: var(--shade-black);
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.contains-commentaires .commentary .comment-person,
.contents-commentary .commentary-list .commentary .comment-person {
  column-gap: 20px;
}
.commentary-residence .show-commentary {
  display: none;
}
/* @media screen and (max-width: 600px) {
  .commentary-residence .show-commentary {
    display: none;
  }
} */

.contains-commentaires .commentary .comment-person img,
.contents-commentary .commentary-list .commentary .comment-person img,
.comment-person img {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: var(--primary-50);
  border: 1px solid var(--gray-200);
  object-fit: cover;
}

.contains-commentaires .commentary .comment-person .title-pers,
.contents-commentary .commentary-list .commentary .comment-person .title-pers {
  row-gap: 4px;
}

.contains-commentaires .commentary .comment-person .title-pers span,
.contents-commentary
  .commentary-list
  .commentary
  .comment-person
  .title-pers
  span {
  color: var(--shade-black);
  font-family: Mori Gothic;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.4px;
}
.contains-commentaires .commentary .comment-person .title-pers > div svg,
.contents-commentary
  .commentary-list
  .commentary
  .comment-person
  .title-pers
  > div
  svg {
  width: 16px;
  height: 16px;
}
.commentary .comment-person .title-pers > div {
  column-gap: 6px;
}
.contains-commentaires .commentary .comment-person .title-pers > div > span,
.contents-commentary
  .commentary-list
  .commentary
  .comment-person
  .title-pers
  > div
  > span {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

@media screen and (max-width: 550px) {
  .commentary-residence .show-commentary {
    display: flex;
  }
}
