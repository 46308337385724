.top-header-add-resi {
  position: fixed;
  width: 100vw;
  left: 0;
  top: 0;
  height: auto;
  padding-top: 28px;
  padding-top: 28px;
  opacity: 0;
  z-index: 2;
  animation: show-header-add-resi 0.3s 0.35s linear forwards;
}
@keyframes show-header-add-resi {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}
.top-header-add-resi .hd-right-btn .help-btn span {
  color: var(--primary-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-underline-offset: 3px;
  text-decoration: underline;
}
.top-header-add-resi .hd-right-btn .save-btn {
  border: 1px solid var(--primary-500);
}
.top-header-add-resi .hd-right-btn .save-btn span {
  color: var(--primary-500);
}
.top-header-add-resi .hd-right-btn .menu-btn {
  display: none;
  border-radius: 100px;
  border: 1px solid var(--gray-200);
  background: var(--shade-white);
  padding: 8px;
  box-shadow: 0px 0px 0px 0px rgba(109, 114, 116, 0.05),
    0px 13px 29px 0px rgba(109, 114, 116, 0.05),
    0px 52px 52px 0px rgba(109, 114, 116, 0.04),
    0px 118px 71px 0px rgba(109, 114, 116, 0.03),
    0px 209px 84px 0px rgba(109, 114, 116, 0.01),
    0px 327px 91px 0px rgba(109, 114, 116, 0);
  column-gap: 8px;
}

.quit-save .modal-contains {
  min-height: auto;
  max-width: 380px;
}
.quit-save .modal-contains .save-progress button {
  width: 100%;
  min-height: auto;
}
.quit-save .modal-contains .save-progress button span {
  font-weight: 600;
}
.top-header-add-resi .hd-right-btn .menu-btn img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}
.top-header-add-resi .hd-right-btn .menu-btn img:first-child {
  width: 24px;
  height: 24px;
}

@media screen and (max-width: 550px) {
  .top-header-add-resi .hd-right-btn > button {
    display: none;
  }
  .top-header-add-resi .hd-right-btn .menu-btn {
    display: flex;
  }
}
