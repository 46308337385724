.nbr-config {
  width: 100%;
}
.nbr-config .required {
  color: var(--destructive-500);
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 12px;
  font-family: Inter;
}
.nbr-config > label {
  color: var(--gray-600);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.nbr-config .nbrs {
  border-radius: 1000px;
  border: 1px solid var(--gray-100);
}
.nbr-config .nbrs > button {
  padding: 8px;
}
.nbr-config .nbrs > button:disabled {
  background: none !important;
}
.nbr-config .nbrs > span {
  padding: 8px 16px;
  color: var(--gray-500);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  width: 52px;
}
.nbr-config .nbrs > .on-true {
  color: var(--shade-black);
}

@media screen and (max-width: 550px) {
  .filter-selected-panel .nbr-config > label {
    font-size: 14px;
  }
  .filter-selected-panel .nbr-config .nbrs > button {
    padding: 6px;
  }
  .filter-selected-panel .nbr-config .nbrs > span {
    padding: 6px 8px;
    font-size: 12px;
    width: 40px;
    text-align: center;
  }
  .filter-selected-panel .case-selectors button span {
    white-space: nowrap;
    font-size: 12px;
  }
}
