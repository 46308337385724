.step-1-annonce .right-part-add-resi img {
  width: 60.1%;
  height: auto;
  aspect-ratio: 475/325;
  object-fit: contain;
}

.step-2 .right-part-add-resi .filter-selected-panel {
  padding: 0;
  border: none;
  column-gap: 20px;
}
.step-2 .right-part-add-resi .filter-selected-panel .residence-type {
  padding: 0;
  border: none;
  column-gap: 20px;
  flex-wrap: wrap;
}
.step-2 .right-part-add-resi .filter-selected-panel .residence-type button {
  width: calc(100% / 3 - 40px / 3);
  border: 1px solid var(--primary-200);
}
.step-2 .right-part-add-resi .filter-selected-panel .residence-type button img {
  width: 32px;
  height: 32px;
}
