.input-select-perso {
  position: relative;
  width: 100%;
  border-radius: 6px 6px 0px 0px;
  border: 1px solid var(--gray-500, #6b7280);
}
.input-select-perso.selected {
  border-bottom: 1px solid var(--primary-500, #dac7ff);
}
.input-select-perso.error {
  border-bottom: 1px solid var(--destructive-400, #ef4343) !important;
}
.input-select-perso label {
  position: absolute;
  left: 15px;
  top: 50%;
  transition: 0.15s;
  transform: translateY(-50%);
  color: var(--gray-500, #6b7280);
  font-family: Inter;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border-radius: 1px;
  padding: 0px 3px;
  pointer-events: none;
  user-select: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 32px);
  width: fit-content;
}

.input-select-perso label.selected {
  transform: translateY(calc(-100% - 10px));
  font-size: 13px !important;
  font-weight: 600;
  background: var(--shade-white, #fff);
}

.input-select-perso input {
  width: 100%;
  padding: 16px;
  color: var(--shade-black, #1e1a21);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  border: none;
  outline: none;
}

.input-select-perso .detect-issue-input {
  position: absolute;
  top: calc(100% + 8px);
  left: 0;
  max-width: 100%;
  background: var(--issue-100, #ffd8d6);
  padding: 5px;
  border-radius: 4px;
  opacity: 0;
  animation: showMe-3 0.2s ease-in-out forwards;
  z-index: 2;
}
@keyframes showMe-3 {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.input-select-perso .detect-issue-input .chevron {
  position: absolute;
  top: -6px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 8px solid var(--issue-100, #ffd8d6);
  left: 10px;
  transform: scale(1.3);
  z-index: 2;
}
.input-select-perso .detect-issue-input span {
  color: var(--shade-black, #1e1a21);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
}
